const preloadImage = (src:any, loadedSrc:any) => {
    let img = new Image()
    img.onload = () => {
        if (loadedSrc) {
            loadedSrc(true)
        }
    }
    img.src = src
}

export const loadImagesSelectedUponPageLoad = (images:Array<any>, next:any) => {
    let imageLoadedCount = 0

    if (images && images.length > 0) {
        let handler = (i:number) => {
            if (i < images.length) {
                if (!images[i]) {
                    if (next) {
                        next(true)
                    }
                }
                preloadImage(images[i], (loadedSrc:any) => {
                    if (loadedSrc) {
                        imageLoadedCount++
                        const x = i + 1;
                        handler(x)
                    }
                })
            } else {
                if (next) {
                    next(true)
                }
            }
        }

        handler(0)
    } else {
        next(true)
    }
}
