import {reset, bright, white, cyan, red} from '../../ConsoleLogDisplay';
import { PGoldContract } from '../Web3Utils';
import Web3 from "web3";

export const balanceOf = async (wallet:string) => {
    const raw = await PGoldContract().methods.balanceOf(wallet).call();
    const result = Web3.utils.fromWei(raw.toString());
    console.log(`${bright}${cyan}${wallet} has ${white}${result}${cyan} tokens.${reset}`);
    return parseInt(result);
}

export const totalSupply = async () => {
    const totalSupply = await PGoldContract().methods.totalSupply().call();
    console.log(`${bright}${cyan}There are ${white}${Web3.utils.fromWei(totalSupply)}${cyan} tokens in circulation for the PGold Contract.${reset}`);
    return totalSupply;
}
