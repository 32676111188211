import {reset, bright, white, cyan, red} from '../../ConsoleLogDisplay';
import * as pnpAction from './PNPAction';
import * as pyramidAction from './PyramidAction';
import Web3 from "web3";
import {tokensOfOwnerInCombined} from "./PNPAction";
import BN from "bn.js";
import {BigNumber} from 'ethers';

const calculateNoblesEarning_06102022 = async (tokenIds:any) => {
    const pGoldPerSovereign = await pyramidAction.pGoldPerSovereign();
    let earnings:any = 0;
    for (let i = 0; i < tokenIds.length; i++) {
        const traits = await pnpAction.getTokenTraits(tokenIds[i]);
        // @ts-ignore
        if (!traits.isPeasant) {
            // @ts-ignore
            const sovereignScore = 8 - traits.sovereignIndex;
            let earning = sovereignScore * pGoldPerSovereign;
            earnings += earning;
        }
    }
    earnings = earnings.toString();
    console.log(`${bright}${cyan}${white}${earnings}${cyan} PGold can be claimed as Noble Taxes from the Pyramid Contract.${reset}`);
    return earnings;
}

const calculateNoblesEarning_06152022 = async (tokenIds:any) => {
    // @ts-ignore
    const pGoldPerSovereign = new Web3.utils.toBN(await pyramidAction.pGoldPerSovereign());
    // @ts-ignore
    let earnings = new Web3.utils.toBN(0);
    for (let i = 0; i < tokenIds.length; i++) {
        const traits = await pnpAction.getTokenTraits(tokenIds[i]);
        if (!traits[0]) {
            // @ts-ignore
            const sovereignScore = 8 - traits[11];
            const earning = pGoldPerSovereign.muln(sovereignScore);
            earnings.add(earning);
        }
    }
    const result = earnings.toString();
    console.log(`${bright}${cyan}${white}${result}${cyan} PGold can be claimed as Noble Taxes from the Pyramid Contract.${reset}`);
    return result;
}

const calculateNoblesEarning = async (tokenIds:any) => {
    // @ts-ignore
    const pGoldPerSovereign = new Web3.utils.toBN(await pyramidAction.pGoldPerSovereign());
    // @ts-ignore
    let earnings = new Web3.utils.toBN(0);
    for (let i = 0; i < tokenIds.length; i++) {
        const traits = await pnpAction.getTokenTraits(tokenIds[i]);
        if (!traits[0]) {
            // @ts-ignore
            let treasurer = new Web3.utils.toBN(await pyramidAction.treasurer(tokenIds[i]));
            if (treasurer == 0) {
                // @ts-ignore
                treasurer = new Web3.utils.toBN(await pyramidAction.pGoldLedger(tokenIds[i]));
                const calcPGoldPerSovereign = pGoldPerSovereign.sub(treasurer);
                // @ts-ignore
                const sovereignScore = 8 - traits[11];
                const earning = calcPGoldPerSovereign.muln(sovereignScore);
                earnings.add(earning);
            }
        }
    }
    const result = earnings.toString();
    console.log(`${bright}${cyan}${white}${result}${cyan} PGold can be claimed as Noble Taxes from the Pyramid Contract.${reset}`);
    return result;
}


export const calculateEstimatedNoblesEarning_06102022 = async (wallet:string) => {
    const tokenIds = await pnpAction.tokensOfOwnerInCombined(wallet);
    const totalPeasantCount = await pyramidAction.totalPeasantCensus();

    // @ts-ignore
    const dailyPGoldRateBN = new Web3.utils.toBN(await pyramidAction.dailyPGoldRate());

    const dailyTaxRateBN = dailyPGoldRateBN.muln(totalPeasantCount).divn(5); // 20%, not formatted

    const totalSovereignScore = await pyramidAction.totalSovereignCensus();

    const maxSovereign = await pyramidAction.maxSovereign();

    let userSovereignScore = 0;
    let result = null;

    for (let i=0; i<tokenIds.length; i++) {
        const traits = await pnpAction.getTokenTraits(tokenIds[i]);
        if (!traits[0]) {
            // @ts-ignore
            const sovereignScore = maxSovereign - traits[11];
            userSovereignScore += sovereignScore;
        }
    }
    // if (totalSovereignScore > 0) {
    //     const estimatedEarning = dailyTaxRateBN.divn(totalSovereignScore).muln(userSovereignScore).toString();
    //     result = Math.round(Number(Web3.utils.fromWei(estimatedEarning)));
    // }
    console.log(`${bright}${cyan}The estimated earnings for Nobles are ${white}${result}${cyan} PGold per day.${reset}`);
    return Number(result);
}

export const calculateEstimatedNoblesEarning = async (wallet:string) => {
    const tokenIds = await pnpAction.tokensOfOwnerInCombined(wallet);
    const totalPeasantCount = parseInt(await pyramidAction.totalPeasantCensus());

    // @ts-ignore
    const dailyPGoldRateBN = new Web3.utils.toBN(await pyramidAction.dailyPGoldRate());

    const dailyTaxRateBN = dailyPGoldRateBN.muln(totalPeasantCount).divn(5); // 20%, not formatted

    const totalSovereignScore = await pyramidAction.totalSovereignCensus();

    const maxSovereign = await pyramidAction.maxSovereign();

    let userSovereignScore = 0;
    let result = null;

    for (let i=0; i<tokenIds.length; i++) {
        const traits = await pnpAction.getTokenTraits(tokenIds[i]);
        if (!traits[0]) {
            // @ts-ignore
            const sovereignScore = maxSovereign - traits[11];
            userSovereignScore += sovereignScore;
        }
    }
    if (totalSovereignScore > 0) {
        const estimatedEarning = dailyTaxRateBN.divn(totalSovereignScore).muln(userSovereignScore).toString();
        result = Math.round(Number(Web3.utils.fromWei(estimatedEarning)));
    }
    console.log(`${bright}${cyan}The estimated earnings for Nobles are ${white}${result}${cyan} PGold per day.${reset}`);
    return Number(result);
}

// export const calculateProjectedPeasantsEarning_WORKING = async (wallet: string) => {
//     const tokenIds = await tokensOfOwnerInCombined(wallet);
//     // @ts-ignore
//     const dailyPGoldRateBN = new Web3.utils.toBN(await pyramidAction.dailyPGoldRate());
//     let peasantCount = 0;
//     for (let i = 0; i < tokenIds.length; i++) {
//         const traits = await pnpAction.getTokenTraits(tokenIds[i]);
//         if (traits.isPeasant) peasantCount += 1;
//     }
//     const projectedEarnings = dailyPGoldRateBN.muln(peasantCount).toString();
//     console.log(`${bright}${cyan}The projected earnings for Peasants are ${white}${Web3.utils.fromWei(projectedEarnings)}${cyan} PGold per day.${reset}`);
//     return Number(Web3.utils.fromWei(projectedEarnings.toString()));
//
// }

export const calculateProjectedPeasantsEarning = async (wallet: string) => {
    const tokenIds = await tokensOfOwnerInCombined(wallet);
    const dailyPGoldRateBN = new BN(await pyramidAction.dailyPGoldRate());
    let peasantCount = 0;
    for (let i = 0; i < tokenIds.length; i++) {
        const traits = await pnpAction.getTokenTraits(tokenIds[i]);
        if (traits[0]) peasantCount += 1;
    }
    const projectedEarnings = new BN(dailyPGoldRateBN.muln(peasantCount).toString());
    console.log(`${bright}${cyan}The projected earnings for Peasants are ${white}${Web3.utils.fromWei(projectedEarnings.toString())}${cyan} PGold per day.${reset}`);
    return Number(Web3.utils.fromWei(projectedEarnings.toString()));
}

export const calculateTotalEarnings = async (wallet:string) => {
    const tokenIds = await pnpAction.tokensOfOwnerInCombined(wallet);

    const [peasantIds, nobleIds] = await pnpAction.getPeasantsAndNobles(tokenIds);
    // @ts-ignore
    const peasantEarningsBN = new Web3.utils.toBN(await pyramidAction.calculatePeasantsEarning(peasantIds));
    const _noblesEarning = await calculateNoblesEarning(nobleIds);
    // @ts-ignore
    const nobleEarningsBN = new Web3.utils.toBN(Math.round(_noblesEarning.toString().replace('e-','').replace('e+','')));
    const totalEarnings = (peasantEarningsBN.add(nobleEarningsBN)).toString();
    console.log(`${bright}${cyan}${white}  ${Web3.utils.fromWei(totalEarnings)}${cyan} PGold can be claimed  from the Pyramid Contract.${reset}`);

    return parseInt(Web3.utils.fromWei(totalEarnings));
}
