// Colors reference

// font effect
export const reset = "\x1b[0m"
export const bright = "\x1b[1m" // bold
export const dim = "\x1b[2m"
export const italic = "\x1b[3m"
export const underscore = "\x1b[4m"
export const blink = "\x1b[5m"
export const inverse = "\x1b[7m"
export const hidden = "\x1b[8m"
export const strikethrough = "\x1b[9m"

// font
export const black = "\x1b[30m"
export const red = "\x1b[31m"
export const green = "\x1b[32m"
export const yellow = "\x1b[33m"
export const blue = "\x1b[34m"
export const magenta = "\x1b[35m"
export const cyan = "\x1b[36m"
export const white = "\x1b[37m"

// background
export const BGblack = "\x1b[40m"
export const BGred = "\x1b[41m"
export const BGgreen = "\x1b[42m"
export const BGyellow = "\x1b[43m"
export const BGblue = "\x1b[44m"
export const BGmagenta = "\x1b[45m"
export const BGcyan = "\x1b[46m"
export const BGwhite = "\x1b[47m"

// cursor manipulation
export const left = "\b" // go back one character
export const up = "\x1B[A" // move one line up (Multiple lines up, \x1B[NA with N = number of lines)
export const home = "\r" // go to beginning of line
export const upHome = "\x1B[F" // go to beginning of previous line (Multiple lines up, \x1B[NF with N = number of lines)
export const erase = "\x1B[2K" // erases the whole line
