import React, {useEffect, useRef, useState} from 'react';
import {createRoot, Root} from "react-dom/client";
import styled from "styled-components";
import ImageBG from '../assets/images/dapp-background.svg';
import {Breakpoint} from "../constants";
import Footer from "../components/Footer";
import {useSelector, useDispatch} from "react-redux";
import { ethers } from 'ethers';
import {Modal, PopupHeader, Text} from "../Styles";
import Header from "../components/pyramids/Header";
import EarningAndBalance from "../components/pyramids/EarningAndBalance";
import Minting from "../components/pyramids/Minting";
import ComingSoonModal from "../components/ComingSoonModal";
import ConfirmedModal from "../components/ConfirmedModal";
import ImageModal from "../components/ImageModal";
import PeasantsNobles from "../components/pyramids/PeasantsNobles";
import {ERROR_NETWORK_MESSAGE} from "../constants";
import {create as ipfsHttpClient} from 'ipfs-http-client';
import axios from 'axios';

// @ts-ignore
const client = ipfsHttpClient('https://ipfs.infura.io:5001/api/v0');
// const client = ipfsHttpClient("https://dalliants.mypinata.cloud:5001/api/v0")
// const client = ipfsHttpClient({host:'gateway.ipfs.io', port:5001, protocol:'https'});

export default function Pyramids() {

    const {ethereum} = window;
    const dispatch = useDispatch();
    const refModal = useRef<HTMLDivElement>(null);
    // @ts-ignore
    const { chainId, provider, allowedNetworks } = useSelector((state) => state.profile);
    const prevChainIdRef = useRef(0);
    let root: Root | null = null;


    async function initConnection() {
        if (ethereum) {
            const provider = new ethers.providers.Web3Provider(ethereum, "any");
            try {
                let accounts = await provider.send('eth_accounts', []);
                if (accounts && accounts.length > 0) {
                } else {
                    // accounts = await provider.send('eth_requestAccounts', []);
                }
                console.log(accounts)

                // const signer = provider.getSigner();
                // const msgSender = await signer.getAddress();
                //
                //
                // const contract = pgoldContract(provider);
                // const xx = await contract.name();
                // console.log('CONTRATA===', xx)

            } catch (e) {
                console.log(e)
            }
        }
    }

    function handleDiscordClick() {
        window.open("https://discord.com/invite/Wa5YqkzAQK", "_blank");
    }

    function handleTwitterClick() {
        window.open("https://twitter.com/PNPEmpire", "_blank");
    }

    async function handleOpenseaClick() {
        const body = document.body;
        body.style.height = '100vh';
        body.style.overflowY = 'hidden';
        if (refModal.current) {
            root = createRoot(refModal.current);
            if (root) {
                root.render(<ComingSoonModal close={handleCloseModalClick}/>);
            }
            refModal.current.classList.add('active');
        }
    }

    function handleCloseModalClick() {
        const body = document.body;
        body.style.height = '';
        body.style.overflowY = '';
        if (refModal.current) {
            if (root) {
                root.unmount();
            }
            refModal.current.classList.remove('active');
        }
    }

    async function handleMintingStatus(status:number) {
        const body = document.body;
        body.style.height = '100vh';
        body.style.overflowY = 'hidden';
        if (refModal.current) {

            root = createRoot(refModal.current);
            if (status === 1) {
                root.render(<ConfirmedModal message={'Mint successful!\nNFT image(s) will be generated in a few minutes.'} close={handleCloseModalClick} viewOnOpensea={handleOpenseaClick} opensea/>);
            } else if (status === 2) {
                root.render(<ConfirmedModal message={'Mint is still pending!\n.It may take a few minutes to finish the transaction.'} close={handleCloseModalClick}/>);
            } else if (status === 0) {
                root.render(<ConfirmedModal message={'Mint unsuccessful!\nPlease try again.'} close={handleCloseModalClick}/>);
            } else if (status === 3) {
                root.render(<ConfirmedModal message={'Wallet is not\nwhitelisted!'} close={handleCloseModalClick}/>);
            } else if (status === 4) {
                root.render(<ConfirmedModal message={'You have already minted\nyour allocation!'} close={handleCloseModalClick}/>);
            }
            refModal.current.classList.add('active');
        }
    }

    async function handleOpenImage(imageUrl:string) {
        const body = document.body;
        body.style.height = '100vh';
        body.style.overflowY = 'hidden';
        if (refModal.current) {
            root = createRoot(refModal.current);
            if (root) {
                root.render(<ImageModal imageUrl={imageUrl} close={handleCloseModalClick}/>);
            }
            refModal.current.classList.add('active');
        }
    }

    async function onImageChange(e:any) {
        const file = e.target.files[0];
        try {


/*
            const added = await client.add(file, {wrapWithDirectory: true, progress: (prog) => console.log(`received: ${prog}`)});
            const url = `https://ipfs.io/ipfs/${added}`;
            // @ts-ignore
            console.log('URL', added.cid._baseCache.get('z')        )
            // @ts-ignore
            const cid = added.cid._baseCache.get('z');
*/

            let formData = new FormData();
            formData.append('file', file);

            axios({
                method: 'POST',
                url: 'http://localhost:4000/nft/pnp_name_publish',
                data: {pnpImage: formData},
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then()
                .catch(e => {
                    console.log('ERROR UPLOADING IMAGE', e)
                })

            // axios({
            //     method: 'GET',
            //     url: 'http://localhost:4000/nft/pnp_name_publish',
            //     params: {hash: formData},
            //     headers: {
            //         'Content-Type': 'multipart/form-data',
            //     }
            //
            // }).then(res => {
            //     console.log('RES', res)
            // }).catch(e => {
            //     console.log('ERRROR', e)
            // })


            //const ipnsAdded = await client.name.publish(`/ipfs/${cid}`);
            //console.log('IPNS URL', ipnsAdded)

        } catch (e) {
            console.log(e)
        }
    }


    useEffect(() => {
        if (parseInt(chainId) > 0 && prevChainIdRef.current > 0) {
            if (allowedNetworks.includes(parseInt(chainId))) {
                if (prevChainIdRef.current !== parseInt(chainId)) {
                    window.location.reload();
                }
            }
        }

        prevChainIdRef.current = parseInt(chainId);
    },[chainId, provider])

    useEffect(() => {
        window.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                handleCloseModalClick();
            }
        });
    }, [])


    return (
        <Container>
            <Modal ref={refModal}/>
            {
                (chainId > 0 && !allowedNetworks.includes(parseInt(chainId))) && (
                    <PopupHeader>
                        <Text font={'asulbold'} fontSize={1.1} color={'#000'}>{ERROR_NETWORK_MESSAGE}</Text>
                    </PopupHeader>
                )
            }
            <Background>
                <BGWrap>
                    <Content>
                        <Header
                            discord={handleDiscordClick}
                            twitter={handleTwitterClick}
                            opensea={handleOpenseaClick}
                        />

                        <div style={{marginTop:'2rem'}}>
                            <EarningAndBalance/>
                        </div>

                        <HorizonalLine/>

                        <Minting mintingStatus={handleMintingStatus}/>

                        <HorizonalLine/>

                        <PeasantsNobles openImage={handleOpenImage}/>
                    </Content>
                </BGWrap>
            </Background>
            <Footer discord={handleDiscordClick} twitter={handleTwitterClick} opensea={handleOpenseaClick}/>

        </Container>
    );
}

const Container = styled.div`
    width: 100%;
    height: 100%;    
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    position: relative;
    overflow-x: hidden;
    background-color: #B97F4B;
`
const Background = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-image: url(${ImageBG});
    background-repeat: no-repeat;
    background-size: 100%;
    padding: 3vw;
    @media (max-width: ${props => Breakpoint.md}) {
        background-size: 120%;
    }
`

const BGWrap = styled.div`
    position: relative;
    width: 100%;
    border-radius: 1.2rem;
    overflow: hidden;
`
const Content = styled.div`
    position: relative;
    width: 100%;
    background-color: rgba(255,255,255,0.6);
    // -webkit-filter: blur(0.6rem); 
    // filter: blur(0.6rem);
    backdrop-filter: blur(0.5rem);
    padding: 2vw 6vw 6vw 6vw;
`


const HorizonalLine = styled.div`
    position: relative;
    width: 100%;
    height: 0.5rem;
    background-color: #AF7D3E;
    background-color: ${props => '#8D5D29'};
    border: 0.1rem solid #3A2206;
    border-radius: 0.5rem;    
    margin-top: 2rem;
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    &:after {
        position: absolute;
        content: '';
        display: inline-block;
        width: 99.5%;
        height: 50%;
        background-color: ${props => '#AF7D3E'};
        border-radius: 0.5rem;    
    }
`


