import {WhitelistContract, sendTx, PNPContract} from "../Web3Utils";
import * as whitelist from "../abi/Whitelist";
import {reset, bright, white, red, cyan, magenta, blue, green} from '../../ConsoleLogDisplay';

// check whitelist entitlement
export const entitlement = async (wallet:string) => {
    const entitlement = await WhitelistContract().methods.entitlement(wallet).call();
    console.log(entitlement)
    switch (entitlement) {
        case "0":
            console.log(`${bright}${cyan}${wallet} has no whitelist slot.${reset}`);
            break;
        case "1":
            console.log(`${bright}${cyan}${wallet} has whitelist slot.${reset}`);
            break;
        default:
            console.log(`${bright}${cyan}${wallet} already claimed whitelist slot.${reset}`);
            break;
    }
    return parseInt(entitlement);
}

// claim whitelist
export const claimWhitelist = async (wallet:string) => {
    console.log(`${bright}${magenta}Claiming Whitelist starting for Whitelist Contract...${reset}`);
    // prepare transaction data
    const txData = await WhitelistContract().methods.claimWhitelist();
    // send transaction
    const result = await sendTx(txData, wallet, whitelist.address, 0);
    // @ts-ignore
    if (result.txResult === true) {
        console.log(`${bright}${magenta}Claiming Whitelist ended for Whitelist Contract.${reset}`);
    } else {
        console.log(`${red}Failed to claim whitelist, error: ${result}${reset}`);
    }
}
