import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom'
import styled from 'styled-components';
import { Breakpoint } from '../constants';
import ImageLandingTop from '../assets/images/landing-top.svg';
import ImageLandingPGolds from '../assets/images/landing-pgolds.svg';
import ImageLandingAnimals from '../assets/images/landing-animals.svg';
import ImageLandingPeople from '../assets/images/landing-pesants-nobles.svg';
import ImageLandingBottom from '../assets/images/landing-bottom.svg';
import ImageLogo from '../assets/images/logo.svg';
import {gsap} from 'gsap';
import {useNavigate} from 'react-router-dom';
import BoxPnpDesc from '../components/BoxPnpDesc';
import BoxHowPnpWork from "../components/BoxHowPnpWork";
import Roadmap from "../components/Roadmap";
import Team from "../components/Team";
import Footer from "../components/Footer";
import LitePaperModal from "../components/LitePaperModal";
import ComingSoonModal from "../components/ComingSoonModal";
import Loadable from 'react-loadable';
import ActivityLoader, {HomeLandingWrap} from "../components/ActivityLoader";
import {Modal} from "../Styles";
import HomeLanding from "../components/HomeLanding";

export default function Home() {
    const refContainer = useRef<HTMLDivElement>(null);
    const refToggleButton = useRef<HTMLDivElement>(null);
    const refMenuBlocker = useRef<HTMLDivElement>(null);
    const refMenuContentSliding = useRef<HTMLDivElement>(null);
    const refMenuContent = useRef<HTMLDivElement>(null);
    const refNavbarLinks = useRef<HTMLDivElement>(null);
    const refModal = useRef<HTMLDivElement>(null);
    const refLitePaperButton = useRef<HTMLDivElement>(null);
    const targetRefMenuContent = gsap.timeline();
    const body = document.body;
    const navigate = useNavigate();
    const [hasHomeLandingLoaded, setHomeLandingLoaded] = useState(false);

    function getPosition(elem:any, target:any) {
        const targetRect = target.getBoundingClientRect();
        const elemRect = elem.getBoundingClientRect();

        gsap.set(elem, {
            x: 0,
            y: 0,
            width: targetRect.width,
            height: targetRect.height
        });
        const newRect = elem.getBoundingClientRect();
        gsap.set(elem, { width: elemRect.width, height: elemRect.height });
        return {
            left: targetRect.left - newRect.left,
            top: targetRect.top - newRect.top,
            width: newRect.width,
            height: newRect.height
        };
    }

    async function handleToggleButtonClick() {

        if (refContainer.current) {
            refContainer.current.classList.toggle('active');
        }
        if (refToggleButton.current) {
            if (refToggleButton.current.classList.contains('active')) {
                body.style.height = '';
                body.style.overflowY = '';
            } else {
                body.style.height = '100vh';
                body.style.overflowY = 'hidden';
            }
            await refToggleButton.current.classList.toggle('active');
        }

        if (refMenuContentSliding.current) {
            refMenuContentSliding.current.classList.toggle('active');
        }

        if (refMenuContent.current) {
            // targetRefMenuContent
            //     .to(refMenuContent.current,{duration:0.2,marginLeft:'40%'})
            //     .reversed(!targetRefMenuContent.reversed());
            refMenuContent.current.classList.toggle('active');
        }

        if (refMenuBlocker.current) {
            await refMenuBlocker.current.classList.toggle('active');
        }
    }


    function handleCloseModalClick() {
        body.style.height = '';
        body.style.overflowY = '';
        if (refModal.current) {
            ReactDOM.unmountComponentAtNode(refModal.current);
            refModal.current.classList.remove('active');
        }
    }

    async function handleLitePaperClick() {
        const body = document.body;
        body.style.height = '100vh';
        body.style.overflowY = 'hidden';
        if (refModal.current) {
            await ReactDOM.unmountComponentAtNode(refModal.current);
            await ReactDOM.render(<LitePaperModal close={handleCloseModalClick}/>, refModal.current);

            // const tl = gsap.timeline();
            // const newRect = getPosition(refModal.current, refLitePaperButton.current);
            // tl.set(refModal.current, {
            //     x: newRect.left,
            //     y: newRect.top,
            //     width: newRect.width,
            //     height: newRect.height
            // });
            // tl.to(refModal.current, 0.35, {
            //     x: 0,
            //     y: 0,
            //     width: '100%',
            //     height: '100%',
            //     autoAlpha: 1
            // });

            refModal.current.classList.add('active');
        }
    }

    function handleDiscordClick() {
        window.open("https://discord.com/invite/Wa5YqkzAQK", "_blank");
    }

    function handleTwitterClick() {
        window.open("https://twitter.com/PNPEmpire", "_blank");
    }

    async function handleOpenseaClick() {
        const body = document.body;
        body.style.height = '100vh';
        body.style.overflowY = 'hidden';
        if (refModal.current) {
            await ReactDOM.unmountComponentAtNode(refModal.current);
            ReactDOM.render(<ComingSoonModal close={handleCloseModalClick}/>, refModal.current);

            refModal.current.classList.add('active');
        }
    }

    async function handleEnterClick() {
        const body = document.body;
        body.style.height = '100vh';
        body.style.overflowY = 'hidden';
        if (refModal.current) {
            await ReactDOM.unmountComponentAtNode(refModal.current);
            ReactDOM.render(<ComingSoonModal close={handleCloseModalClick}/>, refModal.current);

            refModal.current.classList.add('active');
        }
        // navigate("/dapp");
    }

    function handleWhitePaperClick() {
        window.open("https://empire.gitbook.io/pnpempire", "_blank");
    }

    useEffect(() => {
/*
        const bprem = Breakpoint.md.replace('rem','');
        const px = Number(bprem) * 16;
        if (window.outerWidth <= px) {
            setResponsive(true);
        } else {
            setResponsive(false);
        }

        window.addEventListener('resize', () => {
            const bprem = Breakpoint.md.replace('rem','');
            const px = Number(bprem) * 16;
            if (window.outerWidth <= px) {
                setResponsive(true);
            } else {
                setResponsive(false);
            }
        })
*/

        // targetRefMenuContent.reversed(true);
    }, []);


    return (
        <Container ref={refContainer}>

            <Modal ref={refModal} onClick={handleCloseModalClick}/>
            {/*{*/}
            {/*    isResponsive ? (*/}
                    <NavbarWrapMobile>
                        <MenuBlocker ref={refMenuBlocker}></MenuBlocker>
                        {/*<MenuContentSliding ref={refMenuContentSliding}>*/}
                            <MenuContent ref={refMenuContent}>
                                <LinkButtonImage nthOfType={1} marginRight={1} width={11.5} src={require('../assets/images/link-button-rect.svg').default} onClick={handleWhitePaperClick}>
                                    <LinkButtonInner widthInPct={11.5 * 0.94} heightInPct={3.4 * 0.79}>Whitepaper</LinkButtonInner>
                                </LinkButtonImage>
                                <LinkButtonImage nthOfType={2} marginRight={1} width={11.5} src={require('../assets/images/link-button-rect.svg').default} onClick={handleLitePaperClick}>
                                    <LinkButtonInner widthInPct={11.5 * 0.94} heightInPct={3.4 * 0.79}>Litepaper</LinkButtonInner>
                                </LinkButtonImage>
                                <ButtonsSmallGroup>
                                    <LinkButtonImage nthOfType={3} marginRight={1} src={require('../assets/images/link-button-square.svg').default} onClick={handleDiscordClick}>
                                        <LinkButtonInner widthInPct={3.4 * 0.79} heightInPct={3.4 * 0.79} src={require('../assets/images/discord.svg').default} />
                                    </LinkButtonImage>
                                    <LinkButtonImage nthOfType={4} marginRight={1} src={require('../assets/images/link-button-square.svg').default} onClick={handleTwitterClick}>
                                        <LinkButtonInner widthInPct={3.4 * 0.79} heightInPct={3.4 * 0.79} src={require('../assets/images/twitter.svg').default} />
                                    </LinkButtonImage>
                                    <LinkButtonImage src={require('../assets/images/link-button-square.svg').default} onClick={handleOpenseaClick}>
                                        <LinkButtonInner widthInPct={3.4 * 0.79} heightInPct={3.4 * 0.79} src={require('../assets/images/opensea.svg').default} />
                                    </LinkButtonImage>
                                </ButtonsSmallGroup>
                            </MenuContent>

                        {/*</MenuContentSliding>*/}
                        <ToggleButton ref={refToggleButton} className="toggle-button" onClick={handleToggleButtonClick}>
                            <Bar/>
                            <Bar/>
                            <Bar/>
                        </ToggleButton>
                    </NavbarWrapMobile>
                {/*) : (*/}
                    <NavbarWrap>
                        <Navbar>
                            <Brand></Brand>
                            <NavbarLinks ref={refNavbarLinks}>
                                <LinkButtonImage nthOfType={1} marginRight={1} width={11.5} src={require('../assets/images/link-button-rect.svg').default} onClick={handleWhitePaperClick}>
                                    <LinkButtonInner widthInPct={11.5 * 0.94} heightInPct={3.4 * 0.79}>Whitepaper</LinkButtonInner>
                                </LinkButtonImage>
                                <LinkButtonImage nthOfType={2} marginRight={1} width={11.5} src={require('../assets/images/link-button-rect.svg').default} onClick={handleLitePaperClick} ref={refLitePaperButton}>
                                    <LinkButtonInner widthInPct={11.5 * 0.94} heightInPct={3.4 * 0.79}>Litepaper</LinkButtonInner>
                                </LinkButtonImage>
                                <LinkButtonImage nthOfType={3} marginRight={1} src={require('../assets/images/link-button-square.svg').default} onClick={handleDiscordClick}>
                                    <LinkButtonInner widthInPct={3.4 * 0.79} heightInPct={3.4 * 0.79} src={require('../assets/images/discord.svg').default} />
                                </LinkButtonImage>
                                <LinkButtonImage nthOfType={4} marginRight={1} src={require('../assets/images/link-button-square.svg').default} onClick={handleTwitterClick}>
                                    <LinkButtonInner widthInPct={3.4 * 0.79} heightInPct={3.4 * 0.79} src={require('../assets/images/twitter.svg').default} />
                                </LinkButtonImage>
                                <LinkButtonImage src={require('../assets/images/link-button-square.svg').default} onClick={handleOpenseaClick}>
                                    <LinkButtonInner widthInPct={3.4 * 0.79} heightInPct={3.4 * 0.79} src={require('../assets/images/opensea.svg').default} />
                                </LinkButtonImage>
                            </NavbarLinks>

                        </Navbar>
                    </NavbarWrap>

            <LoadableLandingLogoComponent enter={handleEnterClick}/>

            <HomeLanding hasLoaded={(val:boolean) => setHomeLandingLoaded(val)}/>

            {
                hasHomeLandingLoaded && (
                    <LandingBottomBackground>
                        <BoxPnpDesc/>
                        <BoxHowPnpWork/>
                        <Roadmap/>
                        <Team/>
                        <Space/>
                        <Footer discord={handleDiscordClick} twitter={handleTwitterClick} opensea={handleOpenseaClick}/>
                    </LandingBottomBackground>
                )
            }
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
    height: 100%;    
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    position: relative;
    overflow-x: hidden;
    background-color: #3B2205;
`

const NavbarWrap = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 1;
    width: 100%;
    @media (max-width: ${props => Breakpoint.md}) {
        display: none;
    }
`

const Navbar = styled.nav`
    display: flex;
    flex-direction: row;
    padding: 2.3rem 0;
    @media (max-width: ${props => Breakpoint.md}) {
        flex-direction: column;
        padding: 0;
    }
`

const Brand = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 1rem;
    @media (max-width: ${props => Breakpoint.md}) {
        display: none;
    }
`

const NavbarLinks = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 0 2rem 0 0;
    @media (max-width: ${props => Breakpoint.md}) {
        opacity: 0;
        flex-direction: column;
        align-items: center;
        max-height: 0rem;
        position: absolute;
        background-color: #fff;
        top: 0;
        z-index: 100;
        &.active {
            opacity: 1;
            max-height: 30rem;
        }

        transition-timing-function: ease;
        transition-duration: 0.35s;
        transition-property: max-height, opacity;
        transition-delay: 0, 0.35s;
    }

`
const LinkButtonImage = styled('div')<{src?:string,nthOfType?:number,marginRight?:number,width?:number}>`
    width: ${props => props.width || 3.4}rem;
    height: 3.4rem;
    background-image: url(${props => props.src});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;    
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: ${props => props.marginRight || 0}rem;
    @media (max-width: ${props => Breakpoint.md}) {
        margin-right: 0;
    }
   
    &:nth-of-type(${props => props.nthOfType}) {
        @media (max-width: ${props => Breakpoint.md}) {
            margin-bottom: 1.5rem;
            margin-right: 0;
        }    
    }
`
const LinkButtonInner = styled('div')<{widthInPct?:number,heightInPct?:number,src?:string}>`
    width: ${props => props.widthInPct}rem;
    height: ${props => props.heightInPct}rem;
    border-radius: 0.6rem;
    border: 0.1rem solid black;
    background: linear-gradient(180deg, #1C7390 0%, #082855 100%);
    font-family: asulbold;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    ${props => props.src && `
        &:after {
            content: '';
            width: 100%;
            height: 100%;
            display: inline-block;
            background-image: url(${props.src});
            background-repeat: no-repeat;
            background-position: center;
            background-size: 70%;        
        }    
    `};
`

const ToggleButton = styled('div')<{}>`
    position: absolute;
    top: 1rem;
    right: 2rem;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 2rem;
    height: 1.5rem;
    cursor: pointer;
    margin: 1rem 0 0 0;
    z-index: 201;
    @media (max-width: ${props => Breakpoint.md}) {
        display: flex;
    }
    &.active {
        ${props => Bar} {
            &:nth-of-type(1) {
                transition: transform 0.2s ease 0s;
                transform: translate(0%,200%) rotate(45deg);
            }                    
            &:nth-of-type(3) {
                transition: transform 0.2s ease 0s;
                transform: translate(0%,-200%) rotate(-45deg);
            }
            &:nth-of-type(2) {
                visibility: hidden;
            }                    
        }            
    }
`

const Bar = styled('div')<{isActive?:boolean}>`
    height: 0.3rem;
    width: 100%;
    background-color: #8F3012;      
    transition: transform 0.2s ease 0s;
`

const LandingTopBackground = styled.img`
    position: relative;
    width: 100%;
    @media (max-width: ${props => Breakpoint.md}) {
        display: none;
    }
`
const LandingTopBackgroundMobile = styled.div`
    width: 100%;
    height: 100vh;
    background-image: url(${ImageLandingTop});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    display: none;
    @media (max-width: ${props => Breakpoint.md}) {
        display: flex;
    }
`

const LandingImageMobile = styled('div')<{src?:string,height?:number,topPct?:number,leftPct?:number}>`
    position: absolute;
    width: 100%;
    height: ${props => props.height}vh;
    display: none;
    justify-content: center;
    top: ${props => props.topPct}%;
    left: ${props => props.leftPct}%;
    background-image: url(${props => props.src});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    @media (max-width: ${props => Breakpoint.md}) {
        display: flex;
    }    
`

const LandingTopImages = styled.div`
    width: 100%;
    height: 100%;
    position: relative;    
`

const LandingImage = styled('img')<{src?:string,width?:number,paddingBottom?:number,marginTop?:number,marginLeft?:number,responsive?:boolean}>`
    position: absolute;
    width: ${props => props.width || 100}%;
    margin-top: ${props => props.marginTop || 0}%;
    left: ${props => props.marginLeft || 0}%;
    @media (max-width: ${props => Breakpoint.md}) {
        display: none
    }
`

const LandingBottomBackground = styled.div`
    position: relative;
    width: 100%;
    background-image: url(${ImageLandingBottom});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -0.4%;
`

const MenuContent = styled.div`
    position: absolute;
    width: 60%;
    height: 100vh;
    background: linear-gradient(180deg, #EED79B 0%, #CCA060 100%);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    z-index: 103;
    display: none;
    flex-direction: column;
    align-items: center;
    padding-top: 20%;
    margin-left: 101%;
    @media (max-width: ${props => Breakpoint.md}) {
        display: flex;
        &.active {
            margin-left: 40%;
        }
    }    
    transition: margin-left 0.35s ease;
`
const NavbarWrapMobile = styled.div`
    display: flex;
`
const MenuBlocker = styled.div`
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    z-index: 102;
    opacity: 0;
    display: none;
    @media (max-width: ${props => Breakpoint.md}) {
        &.active {
            display: flex;
            opacity: 1;        
        }        
    }
    transition-timing-function: ease-in;
    transition-duration: 0.35s;
    transition-property: display, opacity;
    transition-delay: 0, 0.35s;
`

const ButtonsSmallGroup = styled.div`
    width: 11.5rem;
    display: flex;
    justify-content: space-between;
`

const Space = styled.div`
    height: 9rem;
`

const ButtonsContainer = styled.div`
    background-color: #fff;
    border-radius: 1.2vw;
    padding: 0.6vw;
    display: flex;
    flex-direction: column;    
    @media (max-width: ${props => Breakpoint.md}) {
        transform: scale(2);
        transform-origin: top;
        margin-top: 16vh;
    }    
    @media (max-width: ${props => Breakpoint.sm}) {
        transform: scale(3);
        transform-origin: top;
        margin-top: 23vh;
    }    
`

const Button = styled('div')<{
    width?:number,
    height?:number,
    paddingTop?:number,
    paddingBottom?:number,
    paddingLeft?:number,
    paddingRight?:number,
    bg?:string,
    color?:string,
    borderW?:number}>`
    background: linear-gradient(90.01deg, #F2B30F 5.23%, #A56E04 19.79%, #FFDE88 65.49%, #D08A02 107.44%);    
    border-radius: 0.9vw;
    color: ${props => props.color};
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border: ${props => props.borderW || 0.15}vw solid #3A2206;
    cursor: pointer;
    overflow: hidden;
    z-index: 10;
    width: ${props => props.width}vw;
    height: ${props => props.height}vw;    
`

const ButtonLink = styled('a')<{
    width?:number,
    height?:number,
    paddingTop?:number,
    paddingBottom?:number,
    paddingLeft?:number,
    paddingRight?:number,
    bg?:string,
    color?:string,
    borderW?:number}>`
    background: linear-gradient(90.01deg, #F2B30F 5.23%, #A56E04 19.79%, #FFDE88 65.49%, #D08A02 107.44%);    
    border-radius: 0.9vw;
    color: ${props => props.color};
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border: ${props => props.borderW || 0.15}vw solid #3A2206;
    cursor: pointer;
    overflow: hidden;
    z-index: 10;
    width: ${props => props.width}vw;
    height: ${props => props.height}vw;
    text-decoration: none;    
`

const ButtonInner = styled('div')<{width?:number,height?:number,bg?:string,font?:string,fontSize?:number,borderW?:number,letterSpacing?:number}>`
    // width: ${props => props.width}rem;
    // height: ${props => props.height}rem;
    width: ${props => props.width}vw;
    height: ${props => props.height}vw;

    background: ${props => props.bg};
    border-radius: 0.6vw;
    border: ${props => props.borderW || 0.15}vw solid #3A2206;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: ${props => props.font || 'asulregular'};
    font-size: ${props => props.fontSize}vw;
    letter-spacing: ${props => props.letterSpacing}vw;
`

const LandingLogo = styled.img`
    position: absolute;
    width: 29%;
    margin-top: 0%;
    left: 0%;
          
    @media (max-width: ${props => Breakpoint.md}) {
        transform: scale(2);
        transform-origin: top;
        margin-top: 1vh;
        
    }    
    @media (max-width: ${props => Breakpoint.sm}) {
        transform: scale(2.5);
        transform-origin: top;
        margin-top: 9vh;
    }    
`

const LandingLogoWrap = styled.div`
    position: absolute;
    width: 100%;
    margin-top: 12%;
    left: 35.5%;
    z-index: 8;  
`

const LandingButtons = styled.div`
    position: absolute;
    width: 15%;
    margin-top: 29%;
    left: 42.6%;
    z-index: 8;            
`


const LoadableLandingLogoComponent = Loadable({
    loader: () => import('../components/HomeLandingLogo'),
    loading: ActivityLoader,
    // render(loaded, props) {
    //     let Component = loaded.default;
    //     return <Component />;
    // }
})
