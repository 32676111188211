import React, {useEffect} from 'react';
import ImageLandingTop from "../assets/images/landing-top.svg";
import ImageLandingPGolds from "../assets/images/landing-pgolds.svg";
import ImageLandingAnimals from "../assets/images/landing-animals.svg";
import ImageLandingPeople from "../assets/images/landing-pesants-nobles.svg";
import styled from "styled-components";
import {Breakpoint} from "../constants";
import {loadImagesSelectedUponPageLoad} from "../utils";

export default function HomeLanding(props:any) {

        useEffect(() => {
                const images = [];
                images.push(ImageLandingPGolds);
                images.push(ImageLandingAnimals);
                images.push(ImageLandingPeople);

                loadImagesSelectedUponPageLoad(images, (next: boolean) => {
                        if (next) {
                                if (props.hasLoaded) {
                                        props.hasLoaded(true)
                                }
                        }
                })

        }, []);

        return (
            <LandingTopImages>
                    {/*DESKTOP*/}
                    <LandingTopBackground src={ImageLandingTop}/>
                    <LandingImage
                        src={ImageLandingPGolds}
                        width={71}
                        paddingBottom={27.7}
                        marginTop={26}
                        marginLeft={13.3}
                    />
                    <LandingImage
                        src={ImageLandingAnimals}
                        width={69}
                        paddingBottom={31}
                        marginTop={29.9}
                        marginLeft={28.4}
                    />
                    <LandingImage
                        src={ImageLandingPeople}
                        width={95}
                        paddingBottom={37}
                        marginTop={25.7}
                        marginLeft={0.7}
                    />


                    {/*MOBILE*/}
                    <LandingTopBackgroundMobile/>
                    <LandingImageMobile
                        src={ImageLandingPGolds}
                        height={43.5}
                        topPct={38.9}
                        leftPct={-3}
                    />
                    <LandingImageMobile
                        src={ImageLandingAnimals}
                        height={48}
                        topPct={45.1}
                        leftPct={21.2}
                    />
                    <LandingImageMobile
                        src={ImageLandingPeople}
                        height={60}
                        topPct={38.2}
                        leftPct={-3.7}
                    />

                    {/*
                <LandingImage
                    src={ImageLogo}
                    width={29}
                    marginTop={12}
                    marginLeft={35.6}
                />
*/}


                    {/*
                <div style={{width:'10%',height: '4%',backgroundColor:'red'}}>
                    <LandingImage
                        responsive
                        src={require('../assets/images/button-enter.svg').default}
                        width={11.5}
                        marginTop={30}
                        marginLeft={40}
                    />

                </div>
*/}


            </LandingTopImages>
        );
}

const LandingTopImages = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`
const LandingTopBackground = styled.img`
    position: relative;
    width: 100%;
    @media (max-width: ${props => Breakpoint.md}) {
        display: none;
    }
`
const LandingImage = styled('img')<{src?:string,width?:number,paddingBottom?:number,marginTop?:number,marginLeft?:number,responsive?:boolean}>`
    position: absolute;
    width: ${props => props.width || 100}%;
    margin-top: ${props => props.marginTop || 0}%;
    left: ${props => props.marginLeft || 0}%;
    @media (max-width: ${props => Breakpoint.md}) {
        display: none
    }
`
const LandingTopBackgroundMobile = styled.div`
    width: 100%;
    height: 100vh;
    background-image: url(${ImageLandingTop});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    display: none;
    @media (max-width: ${props => Breakpoint.md}) {
        display: flex;
    }
`
const LandingImageMobile = styled('div')<{src?:string,height?:number,topPct?:number,leftPct?:number}>`
    position: absolute;
    width: 100%;
    height: ${props => props.height}vh;
    display: none;
    justify-content: center;
    top: ${props => props.topPct}%;
    left: ${props => props.leftPct}%;
    background-image: url(${props => props.src});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    @media (max-width: ${props => Breakpoint.md}) {
        display: flex;
    }    
`
