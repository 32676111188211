import React from 'react';
import styled from 'styled-components';
import {Text, TextCol, BoxSection} from '../Styles';
import {Breakpoint} from "../constants";

export default function Team() {
    return (
        <BoxSection marginTop={2}>
            <Wrapper>
                <TextCol>
                    <Text font='asulbold' weight={900} size={28}>Team</Text>
                </TextCol>
                <Images>
                    <Col>
                        <Image src={require('../assets/images/team-tenten.svg').default} bgSize={85}/>
                        <TextCol><Text font={'asulBold'} size={21} lineHeight={1.4}>Tenten</Text></TextCol>
                        <TextCol><Text font={'asulregular'}>Developer</Text></TextCol>
                    </Col>
                    <Col>
                        <Image src={require('../assets/images/team-fivrre.svg').default} bgSize={84}/>
                        <TextCol><Text font={'asulBold'} size={21} lineHeight={1.4}>Fivrre</Text></TextCol>
                        <TextCol><Text font={'asulregular'}>Community</Text></TextCol>

                    </Col>
                    <Col>
                        <Image src={require('../assets/images/team-soda.svg').default}/>
                        <TextCol><Text font={'asulBold'} size={21} lineHeight={1.4}>Lobardi</Text></TextCol>
                        <TextCol><Text font={'asulregular'}>Creative</Text></TextCol>

                    </Col>
                </Images>
            </Wrapper>
        </BoxSection>
    );
}

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem;
    @media (max-width: ${props => Breakpoint.md}) {
        padding 3rem 0 1rem 0;
    }
`

const Images = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 3rem;
    @media (max-width: ${props => Breakpoint.md}) {
        flex-direction: column;
    }
`

const Image = styled('div')<{src?:string,bgSize?:number}>`
    width: 9rem;
    height: 9rem;
    background-color: #CBA060;
    border: 0.2rem solid #3A2206;
    border-radius: 1rem;
    box-shadow: 0.6rem 0.6rem #3A2206;
    margin: 0 4rem;
    position: relative;
    display: flex;
    margin-bottom: 1rem;
    &:after {
        content: '';
        display: inline-block;
        width: 100%;
        height: 100%;      
        background-image: url(${props => props.src});
        background-repeat: no-repeat;
        background-size: ${props => props.bgSize || 100}%;
        background-position: bottom;
    }
`

const Col = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: ${props => Breakpoint.md}) {
        margin-bottom: 2rem;
    }
`