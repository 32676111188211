import React from 'react';
import styled from 'styled-components';
import {Breakpoint} from "../constants";

export default function Footer(props:any) {


    return (
        <Container>
            <Logo src={require('../assets/images/logo.svg').default}/>
            <Buttons>
                <LinkButtonImage nthOfType={3} marginRight={1} src={require('../assets/images/link-button-square.svg').default} onClick={props.discord}>
                    <LinkButtonInner widthInPct={3.4 * 0.79} heightInPct={3.4 * 0.79} src={require('../assets/images/discord.svg').default} />
                </LinkButtonImage>
                <LinkButtonImage nthOfType={4} marginRight={1} src={require('../assets/images/link-button-square.svg').default} onClick={props.twitter}>
                    <LinkButtonInner widthInPct={3.4 * 0.79} heightInPct={3.4 * 0.79} src={require('../assets/images/twitter.svg').default} />
                </LinkButtonImage>
                <LinkButtonImage src={require('../assets/images/link-button-square.svg').default} onClick={props.opensea}>
                    <LinkButtonInner widthInPct={3.4 * 0.79} heightInPct={3.4 * 0.79} src={require('../assets/images/opensea.svg').default} />
                </LinkButtonImage>
            </Buttons>
        </Container>
    );
}

const Container = styled.div`
    width: 100%;
    height: 16rem;
    background: linear-gradient(270deg, #3A2206 0%, #533B28 49.8%, #3A2206 99.14%);
    display: flex;
    flex-direction: column;
    justify-content: center;    
    align-items: center;
`

const Logo = styled.img`
    height: 8rem;
`

const Buttons = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 0.5rem;
`
const LinkButtonImage = styled('div')<{src?:string,nthOfType?:number,marginRight?:number,width?:number}>`
    width: ${props => props.width || 3.4}rem;
    height: 3.4rem;
    background-image: url(${props => props.src});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;    
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 0.4rem;
`

const LinkButtonInner = styled('div')<{widthInPct?:number,heightInPct?:number,src?:string}>`
    width: ${props => props.widthInPct}rem;
    height: ${props => props.heightInPct}rem;
    border-radius: 0.6rem;
    border: 0.1rem solid black;
    background: linear-gradient(180deg, #1C7390 0%, #082855 100%);
    font-family: asulbold;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    ${props => props.src && `
        &:after {
            content: '';
            width: 100%;
            height: 100%;
            display: inline-block;
            background-image: url(${props.src});
            background-repeat: no-repeat;
            background-position: center;
            background-size: 70%;        
        }    
    `};
`
