import React from 'react';
import styled from "styled-components";
import imageClose from "../assets/images/x-close.svg";
import {Breakpoint} from "../constants";

export default function ImageModal(props:any) {
    return (
        <Container>
            <CloseImage src={imageClose} onClick={props.close}/>
            <NftImage src={props.imageUrl}/>
        </Container>
    );
}

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`
const CloseImage = styled.img`
    position: absolute;
    width: 3.2vh;
    height: 3.2vh;
    top: 4vh;
    right: 4vh;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`
const NftImage = styled.img`
    height: 90%;
    border-radius: 1vh;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
    @media (max-width: ${props => Breakpoint.md}) {
        width: 90%;
        height: auto;
    }
`