import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    peasants: [],
    nobles: [],
    totalMinted: 0
}

export const pnpSlice = createSlice({
    name: 'pnpaction',
    initialState: initialState,
    reducers: {
        setTotalMinted: (state, action) => {
          state.totalMinted = action.payload;
        },
        setPeasants: (state, action) => {
            state.peasants = action.payload;
        },
        setNobles: (state, action) => {
            state.nobles = action.payload;
        },
        addPeasant: (state, action) => {
            // @ts-ignore
            state.peasants.push(action.payload);
        },
        addNoble: (state, action) => {
            // @ts-ignore
            state.nobles.push(action.payload);
        },
        clearPeasants: (state) => {
            // @ts-ignore
            state.peasants = [];
        },
        clearNobles: (state) => {
            state.nobles = [];
        },
        updatePeasant: (state, action) => {
            const index = state.peasants.findIndex((o:any) => o.tokenId === action.payload.tokenId);
            if (index > -1) {
                state.peasants.forEach((obj:any) => {
                    if (obj.tokenId === action.payload.tokenId) {
                        const img = new Image();
                        img.src = action.payload.imageUrl;
                        obj.image = img.src;
                    }
                })
            } else {
                const img = new Image();
                img.src = action.payload.imageUrl;
                addPeasant({tokenId: action.payload.tokenId, image: img.src, imageUrl: action.payload.imageUrl});
            }
        },
        updateNoble: (state, action) => {
            const index = state.nobles.findIndex((o:any) => o.tokenId === action.payload.tokenId);
            if (index > -1) {
                state.nobles.forEach((obj:any) => {
                    if (obj.tokenId === action.payload.tokenId) {
                        const img = new Image();
                        img.src = action.payload.imageUrl;
                        obj.image = img.src;
                    }
                })
            } else {
                const img = new Image();
                img.src = action.payload.imageUrl;
                addNoble({tokenId: action.payload.tokenId, image: img.src, imageUrl: action.payload.imageUrl});
            }
        }
    }
})

export const {
    setTotalMinted,
    setPeasants,
    setNobles,
    addPeasant,
    addNoble,
    clearPeasants,
    clearNobles,
    updatePeasant,
    updateNoble
} = pnpSlice.actions;

export default pnpSlice.reducer;