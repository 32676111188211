import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Breakpoint} from "../../constants";
import {useDispatch, useSelector} from "react-redux";
import ActivityIndicatorDots from "../ActivityIndicatorDots";
import * as pgoldAction from '../../redux/blockchain/actions/PGoldAction';
import * as pyramidAction from '../../redux/blockchain/actions/PyramidAction';
import * as consolidatedAction from '../../redux/blockchain/actions/ConsolidatedAction';
import {formatNumber} from "../../Helper";
import {setDappLocked} from '../../redux/blockchain/reducers/common';

export default function EarningAndBalance() {

    const dispatch = useDispatch();
    // @ts-ignore
    const { chainId, allowedNetworks, account, web3 } = useSelector((state) => state.profile);
    // @ts-ignore
    const { dappLocked, mintCounter } = useSelector((state) => state.common);

    const [loadingTotalPeasantCensus, setLoadingTotalPeasantCensus] = useState(false);
    const [totalPeasantCensus, setTotalPeasantCensus] = useState(0);

    const [loadingPGoldBalance, setLoadingPGoldBalance] = useState(false);
    const [pGoldBalance, setPGoldBalance] = useState(0);

    const [loadingEstimatedNoblesEarning, setLoadingEstimatedNoblesEarning] = useState(false);
    const [estimatedNoblesEarning, setEstimatedNoblesEarning] = useState(0);

    const [loadingPGoldClaimable, setLoadingPGoldClaimable] = useState(false);
    const [pGoldClaimable, setPGoldClaimable] = useState(0);
    const [loading, setLoading] = useState(false);

    async function handleClaimClick() {

        try {
            if (chainId > 0 && allowedNetworks.includes(chainId)) {
                if (account) {
                    dispatch(setDappLocked(true));
                    setLoading(true);
                    await pyramidAction.claimManyFromTreasurer(account);
                    dispatch(setDappLocked(false));
                    setLoading(false);

                    const _pGoldBalance = await pgoldAction.balanceOf(account);
                    setPGoldBalance(Number(_pGoldBalance));
                    const _pGoldClaimable = await consolidatedAction.calculateTotalEarnings(account);
                    setPGoldClaimable(_pGoldClaimable);
                }
            }

        } catch (e) {
            console.log(e);
            dispatch(setDappLocked(false));
            setLoading(false);
        }

    }


    useEffect(() => {

        (async () => {

            try {
                if (chainId > 0 && allowedNetworks.includes(chainId)) {

                    if (account) {
                        // await pnpAction.endWhitelist(account);

                        dispatch(setDappLocked(true));
                        setLoadingTotalPeasantCensus(true);
                        setLoadingEstimatedNoblesEarning(true)
                        setLoadingPGoldBalance(true);
                        setLoadingPGoldClaimable(true);

                        // const _totalPeasantCensus =  await pyramidAction.totalPeasantCensus();
                        const _totalPeasantCensus = await consolidatedAction.calculateProjectedPeasantsEarning(account);
                        setTotalPeasantCensus(_totalPeasantCensus);
                        setLoadingTotalPeasantCensus(false);

                        const _estimatedNoblesEarning = await consolidatedAction.calculateEstimatedNoblesEarning(account);
                        setEstimatedNoblesEarning(_estimatedNoblesEarning);
                        setLoadingEstimatedNoblesEarning(false);

                        const _pGoldBalance = await pgoldAction.balanceOf(account);
                        setPGoldBalance(Number(_pGoldBalance));
                        setLoadingPGoldBalance(false);

                        const _pGoldClaimable = await consolidatedAction.calculateTotalEarnings(account);
                        setPGoldClaimable(_pGoldClaimable);
                        setLoadingPGoldClaimable(false);

                        dispatch(setDappLocked(false));
                    }
                }
            } catch (e) {
                console.log(e)
                setLoadingTotalPeasantCensus(false);
                setLoadingEstimatedNoblesEarning(false);
                setLoadingPGoldBalance(false);
                setLoadingPGoldClaimable(false);
            }

        })();


    }, [chainId,account,mintCounter])


    return (
        <Container>
            <BalanceBox wPct={19} marginRight={1.4} paddingTop={1.5} paddingRight={1.8} paddingBottom={1.5} paddingLeft={1.8}>
                <BalanceLabel>Daily Peasant earnings</BalanceLabel>
                <Newline></Newline>
                <BalanceText>{loadingTotalPeasantCensus ? <ActivityIndicatorDots/> : formatNumber(totalPeasantCensus)}</BalanceText>
            </BalanceBox>
            <BalanceBox wPct={19} marginRight={1.4}  paddingTop={1.5} paddingRight={1} paddingBottom={1.5} paddingLeft={1}>
                <BalanceLabel>Daily Nobles earnings (estimated)</BalanceLabel>
                <Newline></Newline>
                <BalanceText>{loadingEstimatedNoblesEarning ? <ActivityIndicatorDots/> : formatNumber(estimatedNoblesEarning)}</BalanceText>
            </BalanceBox>
            <BalanceBox wPct={19} marginRight={1.4} paddingTop={1.5} paddingRight={1.5} paddingBottom={1.5} paddingLeft={1.8}>
                <BalanceLabel>$PGold Balance</BalanceLabel>
                <Newline></Newline>
                <BalanceText>{loadingPGoldBalance ? <ActivityIndicatorDots/> : formatNumber(pGoldBalance)}</BalanceText>
            </BalanceBox>
            <BalanceBox wPct={43}>
                <BalanceClaimable>
                    <DappImage/>
                    <div style={{position:'relative',height:'100%',width:'80%',display:'flex',flexDirection:'column'}}>
                        <ClaimablePGoldLabelWrap>
                            <div style={{display:'flex',flexDirection:'column'}}>
                                <BalanceLabel>Claimable</BalanceLabel>
                                <BalanceLabel>$PGold</BalanceLabel>
                            </div>
                            <BalanceClaimableWithButton>
                                <LinkButtonImage
                                    isLoading={dappLocked || pGoldClaimable < 1}
                                    width={11.5}
                                    src={require('../../assets/images/link-button-rect.svg').default}
                                    onClick={() => (dappLocked || pGoldClaimable < 1) ? null : handleClaimClick()}>
                                    <LinkButtonInner
                                        widthInPct={11.5 * 0.94}
                                        heightInPct={3.4 * 0.79}
                                        bgColor={'linear-gradient(180deg, #1C7390 0%, #082855 100%)'}
                                        hoverBgColor={(dappLocked || pGoldClaimable < 1) ? 'linear-gradient(180deg, #1C7390 0%, #082855 100%)' : '#0F496F'}
                                    >
                                        {
                                            loading ? (
                                                <div className="sk-wave" style={{position:'absolute'}}>
                                                    <div className="sk-wave-rect"></div>
                                                    <div className="sk-wave-rect"></div>
                                                    <div className="sk-wave-rect"></div>
                                                    <div className="sk-wave-rect"></div>
                                                    <div className="sk-wave-rect"></div>
                                                </div>
                                            ) : 'Claim'
                                        }
                                    </LinkButtonInner>
                                </LinkButtonImage>
                            </BalanceClaimableWithButton>
                        </ClaimablePGoldLabelWrap>

                        <Newline></Newline>

                        <BalanceText>{loadingPGoldClaimable ? <ActivityIndicatorDots/> : formatNumber(pGoldClaimable)}</BalanceText>
                    </div>
                </BalanceClaimable>
            </BalanceBox>
        </Container>
    );
}

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content:space-between;
    @media (max-width: ${props => Breakpoint.lg}) {
        flex-direction: column;
    }
    @media (max-width: ${props => Breakpoint.xl}) {
    }
`

const BalanceBox = styled('div')<{
    marginTop?:number,
    wPct?:number,
    h?:number
    marginRight?:number,
    paddingTop?:number,
    paddingRight?:number,
    paddingBottom?:number,
    paddingLeft?:number,
    boxShadow?:number}>`
    position: relative;
    width: ${props => props.wPct}%;
    height: ${props => props.h}rem;
    border-radius: 1rem;
    border: 0.2rem solid #3A2206;
    background-color: #EDD79B;
    box-sizing: border-box;
    box-shadow: ${props => props.boxShadow || 0.5}rem ${props => props.boxShadow || 0.5}rem #3A2206;
    margin-top: ${props => props.marginTop || 0}rem;
    display: flex;
    flex-direction: column;
    margin-right: ${props => props.marginRight}rem;
    padding-top: ${props => props.paddingTop}rem;
    padding-right: ${props => props.paddingRight}rem;
    padding-bottom: ${props => props.paddingBottom}rem;
    padding-left: ${props => props.paddingLeft}rem;
    
    @media (max-width: ${props => Breakpoint.xl}) {
        // opacity: 0.3;
        // box-shadow: ${props => props.boxShadow || 0.5}vw ${props => props.boxShadow || 0.5}vw #3A2206;
        // border-radius: 1vw;
        // height: ${props => props.h}vw;
        // margin-right: ${props => props.marginRight}vw;
        // padding-top: ${props => props.paddingTop}rem;
        // padding-right: ${props => props.paddingRight}vw;
        // padding-bottom: ${props => props.paddingBottom}rem;
        // padding-left: ${props => props.paddingLeft}vw;
    }
    
    @media (max-width: ${props => Breakpoint.lg}) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: auto;
        width: 100%;
        padding: 1.8rem;
        margin-bottom: 1rem;
    }    
    @media (max-width: ${props => Breakpoint.sm}) {
        padding: 1rem;
    }
`
const BalanceLabel = styled.span`
    font-family: asulregular;
    font-size: 1.2rem;
    @media (max-width: ${props => Breakpoint.sm}) {
        font-size: ${props => 1.2 * 0.8}rem;
    }
`
const BalanceText = styled.span`
    font-family: asulbold;
    font-size: 2rem;
    color: #943112;
    @media (max-width: ${props => Breakpoint.sm}) {
        font-size: ${props => 2 * 0.8}rem;
    }
`
const Newline = styled.div`
    height: 100%;
    @media (max-width: ${props => Breakpoint.lg}) {
        display: none;
    }
`
const ClaimablePGoldLabelWrap = styled.div`
    display: flex;
    justify-content: space-between;
    padding-right: 1rem;
    @media (max-width: ${props => Breakpoint.lg}) {
        padding-right: 0.1rem;
    }
`
const BalanceClaimable = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    padding: 1.5rem;
    @media (max-width: ${props => Breakpoint.lg}) {
        padding: 0rem 0.5rem;
        align-items: center;
    }
`
const DappImage = styled.div`
    position: relative;
    width: 5.5rem;
    height: 7.5rem;
    background-image: url(${require('../../assets/images/pgold.svg').default});
    background-repeat: no-repeat;
    background-size: 100%;
    margin-top: -0.5rem;
    margin-left: -1.5rem;
`
const BalanceClaimableWithButton = styled.div`
    @media (max-width: ${props => Breakpoint.sm}) {
        transform: scale(0.8);
    }
`
const LinkButtonImage = styled('div')<{src?:string,nthOfType?:number,marginRight?:number,width?:number,loggedIn?:boolean,isLoading?:boolean}>`
    width: ${props => props.width || 3.4}rem;
    height: 3.4rem;
    background-image: url(${props => props.src});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;    
    display: flex;
    justify-content: center;
    align-items: center;
    // cursor: ${props => props.loggedIn ? 'default' : 'pointer'};
    cursor: ${props => props.isLoading ? 'not-allowed' : props.loggedIn ? 'default' : 'pointer'};
    margin-right: ${props => props.marginRight || 0}rem;
    // pointer-events: ${props => props.isLoading ? 'none' : 'auto'};
    
    // @media (max-width: ${props => Breakpoint.md}) {
    //     margin-right: 0;
    // }
    //
    // &:nth-of-type(${props => props.nthOfType}) {
    //     @media (max-width: ${props => Breakpoint.md}) {
    //         margin-bottom: 1.5rem;
    //         margin-right: 0;
    //     }    
    // }
`
const LinkButtonInner = styled('div')<{widthInPct?:number,heightInPct?:number,src?:string,bgColor?:string,hoverBgColor?:string,loggedIn?:boolean,isBold?:boolean}>`
    width: ${props => props.widthInPct}rem;
    height: ${props => props.heightInPct}rem;
    border-radius: 0.6rem;
    border: 0.1rem solid black;
    background: ${props => props.bgColor};
    font-family: ${props => props.isBold ? 'asulbold' : 'asulregular'};
    font-size: ${props => props.heightInPct ? props.heightInPct * 0.45 : 0}rem;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    ${props => props.src && `
        &:after {
            content: '';
            width: 100%;
            height: 100%;
            display: inline-block;
            background-image: url(${props.src});
            background-repeat: no-repeat;
            background-position: center;
            background-size: 70%;        
        }    
    `};    
    ${props => !props.loggedIn ?
    `
        &:hover {
            background: ${props.hoverBgColor};
        }
    ` : ''
};    
`
