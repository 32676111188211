import React, {useEffect, useRef} from 'react';
import styled from "styled-components";
import {Breakpoint} from "../../constants";
import {setAccount, setChainId, setProvider, setWeb3} from "../../redux/profile/reducer";
import {Web3Provider} from "@ethersproject/providers";
import {useMetamaskSignIn} from "../../hooks/useMetamaskSignin";
import {useDispatch, useSelector} from "react-redux";
import {setContractProvider, setWeb3ForUtils} from "../../redux/blockchain/Web3Utils";
import {setWeb3ForPyramid} from "../../redux/blockchain/actions/PyramidAction";
import Web3 from "web3";

export default function Header(props:any) {

    const {ethereum} = window;
    const dispatch = useDispatch();
    const {signIn} = useMetamaskSignIn();
    // @ts-ignore
    const { account, chainId, provider, allowedNetworks, isNetworkValid } = useSelector((state) => state.profile);

    function truncate(str: string) {
        return str?.length > 10 ? `${str.substring(0, 5)}...${str.slice(-4)}` : str;
    };

    async function setMetaMaskAccount(account: string) {
        if (account) {
            dispatch(setAccount(account));
        } else {
            await setMetaMaskChainId(0);
        }
    }

    async function setMetaMaskChainId(_chainId: number) {
        dispatch(setChainId(_chainId));
    }

    async function setMetaMaskProvider(_provider: Web3Provider | null) {
        dispatch(setProvider(_provider));
        setContractProvider(_provider);
    }

    async function setMetamaskWeb3(_web3: Web3) {
        dispatch(setWeb3(_web3));
        setWeb3ForUtils(_web3);
        setWeb3ForPyramid(_web3);
    }

    async function _HandleConnectClick() {
        await signIn(setMetaMaskAccount, setMetaMaskChainId, setMetaMaskProvider, setMetamaskWeb3);
    }

    async function handleConnectClick() {
        if (ethereum) {
            const web3 = new Web3(ethereum);
            dispatch(setWeb3(web3));
            setWeb3ForUtils(web3);
            setWeb3ForPyramid(web3);

            try {
                const accounts = await ethereum.request({method: 'eth_requestAccounts'});
                if (accounts) {
                    await setMetaMaskAccount(accounts[0]);
                }
                const _chainId = await web3.eth.net.getId();
                await setMetaMaskChainId(_chainId);
            } catch (e) {

            }
        } else {
            console.log('MetaMask not detected')
        }
    }

    async function initConnection() {
        if (ethereum) {
            const web3 = new Web3(Web3.givenProvider);
            dispatch(setWeb3(web3));
            setWeb3ForUtils(web3);
            setWeb3ForPyramid(web3);

            try {
                const accounts = await ethereum.request({method: 'eth_accounts'});
                if (accounts) {
                    await setMetaMaskAccount(accounts[0]);
                }
                const _chainId = await web3.eth.net.getId();
                await setMetaMaskChainId(_chainId);
            } catch (e) {

            }

            ethereum.on("networkChanged", async (networkId:number) => {
                console.log("Network:", networkId);
                await setMetaMaskChainId(networkId);
                if (!allowedNetworks.includes(Number(networkId))) {
                    //todo
                } else {
                    await initConnection();
                }
            })
            ethereum.on("accountsChanged", async (accounts:string[]) => {
                console.log("Account", accounts)
                if (accounts.length < 1) {
                    window.location.reload();
                }
                await setMetaMaskAccount(accounts[0]);
            })

        } else {
            console.log('MetaMask not detected')
        }
    }

    useEffect(() => {
        (async () => {
            await initConnection();
        })()
    }, [])


    return (
        <Container>
            <HeaderLogo src={require('../../assets/images/logo.svg').default}/>
            <Buttons>
                <LinkButtonImage marginRight={0.8} src={require('../../assets/images/link-button-square.svg').default} onClick={props.discord}>
                    <LinkButtonInner widthInPct={3.4 * 0.79} heightInPct={3.4 * 0.79} src={require('../../assets/images/discord.svg').default} bgColor={'linear-gradient(180deg, #1C7390 0%, #082855 100%)'} hoverBgColor={'#0F496F'}/>
                </LinkButtonImage>
                <LinkButtonImage marginRight={0.8} src={require('../../assets/images/link-button-square.svg').default} onClick={props.twitter}>
                    <LinkButtonInner widthInPct={3.4 * 0.79} heightInPct={3.4 * 0.79} src={require('../../assets/images/twitter.svg').default} bgColor={'linear-gradient(180deg, #1C7390 0%, #082855 100%)'} hoverBgColor={'#0F496F'}/>
                </LinkButtonImage>
                <LinkButtonImage marginRight={1.2} src={require('../../assets/images/link-button-square.svg').default} onClick={props.opensea}>
                    <LinkButtonInner widthInPct={3.4 * 0.79} heightInPct={3.4 * 0.79} src={require('../../assets/images/opensea.svg').default} bgColor={'linear-gradient(180deg, #1C7390 0%, #082855 100%)'} hoverBgColor={'#0F496F'}/>
                </LinkButtonImage>
                <LinkButtonImage width={11.5} src={require('../../assets/images/link-button-rect.svg').default} onClick={() => account ? null : handleConnectClick()} loggedIn={account}>
                    <LinkButtonInner widthInPct={11.5 * 0.94} heightInPct={3.4 * 0.79} bgColor={'linear-gradient(180deg, #BA3C14 0%, #832C11 100%)'} hoverBgColor={'#842C11'} loggedIn={account}>{account ? truncate(account) : 'CONNECT'}</LinkButtonInner>
                </LinkButtonImage>
            </Buttons>

        </Container>
    );
}

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: ${props => Breakpoint.md}) {
        flex-direction: column;
    }
    @media (max-width: ${props => Breakpoint.sm}) {
        transform: scale(0.9);
    }
`
const HeaderLogo = styled.img`
    height: 8rem;
`
const Buttons = styled.div`
    display: flex;
    flex-direction: row;
    @media (max-width: ${props => Breakpoint.md}) {
        margin-top: 1rem;
    }
`
const LinkButtonImage = styled('div')<{
    src?:string,
    nthOfType?:number,
    marginRight?:number,
    width?:number,
    loggedIn?:boolean
}>`
    width: ${props => props.width || 3.4}rem;
    height: 3.4rem;
    background-image: url(${props => props.src});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;    
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: ${props => props.loggedIn ? 'default' : 'pointer'};
    margin-right: ${props => props.marginRight || 0}rem;
    // @media (max-width: ${props => Breakpoint.md}) {
    //     margin-right: 0;
    // }
    //
    // &:nth-of-type(${props => props.nthOfType}) {
    //     @media (max-width: ${props => Breakpoint.md}) {
    //         margin-bottom: 1.5rem;
    //         margin-right: 0;
    //     }    
    // }
`
const LinkButtonInner = styled('div')<{widthInPct?:number,heightInPct?:number,src?:string,bgColor?:string,hoverBgColor?:string,loggedIn?:boolean,isBold?:boolean}>`
    width: ${props => props.widthInPct}rem;
    height: ${props => props.heightInPct}rem;
    border-radius: 0.6rem;
    border: 0.1rem solid black;
    background: ${props => props.bgColor};
    font-family: ${props => props.isBold ? 'asulbold' : 'asulregular'};
    font-size: ${props => props.heightInPct ? props.heightInPct * 0.45 : 0}rem;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    ${props => props.src && `
        &:after {
            content: '';
            width: 100%;
            height: 100%;
            display: inline-block;
            background-image: url(${props.src});
            background-repeat: no-repeat;
            background-position: center;
            background-size: 70%;        
        }    
    `};    
    ${props => !props.loggedIn ?
    `
        &:hover {
            background: ${props.hoverBgColor};
        }
    ` : ''
    };    
`

