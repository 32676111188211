import React from 'react';
import ImageLogoBg from "../assets/images/litepaper-background.svg";
import styled from "styled-components";
import {Button, ButtonInner} from "../Styles";
import {Breakpoint} from "../constants";
import ImageLogo from "../assets/images/logo.svg";

function LitePaperModal(props:any) {
    return (
        <Container>
            <ModalWrap>
                <ModalBg bg={ImageLogoBg}>
                    <ModalContent>
                        <LitePaperHeader/>
                        <ModalContentInner>
                            <TextCol>
                                <Text font={'asulbold'} spacing={0.3} fontSize={3} color={'#196786'}>INTRODUCTION</Text>
                            </TextCol>
                            <TextCol marginTop={5}>
                                <Text font={'asulbold'} fontSize={2} color={'#196786'}>What is PNP?</Text>
                            </TextCol>
                            <TextCol marginTop={2.4}>
                                <Text font={'asulregular'} fontSize={1.6}>PNP is an ambitious world with infinite utility.</Text>
                            </TextCol>
                            <TextCol marginTop={2.4}>
                                <Text font={'asulregular'} fontSize={1.6}>Players choose their path to ascend to the ranks of a Pharaoh with a host of different strategies.</Text>
                            </TextCol>
                            <TextCol marginTop={2.4}>
                                <Text font={'asulregular'} fontSize={1.6}>In the brutal world of ancient Egypt, both Peasants and Nobles work tirelessly to climb the hierarchy to become a true good god of Egypt but only a select few will reach the sacred land.</Text>
                            </TextCol>
                            <TextCol marginTop={2.4}>
                                <Text font={'asulregular'} fontSize={1.6}>Peasants work tirelessly while Nobles run the land, accumulating different assets that all have a symbiotic relationship and impacts the supply and demand of other assets in PNP.</Text>
                            </TextCol>

                            <TextCol marginTop={5}>
                                <Text font={'asulbold'} fontSize={2} color={'#196786'}>Our Motivation</Text>
                            </TextCol>
                            <TextCol marginTop={2.4}>
                                <Text font={'asulregular'} fontSize={1.6}>We, like all of you, have participated in countless mints and spent way too many hours on Discord.</Text>
                            </TextCol>
                            <TextCol marginTop={2.4}>
                                <Text font={'asulregular'} fontSize={1.6}>And if there is one thing we have noticed, it is that the current state of NFTs has gone stale. The cycle is simple:</Text>
                            </TextCol>
                            <ul style={{fontFamily:'asulregular',fontSize:'1.6vw'}}>
                                <li style={{listStyleType: 'decimal',margin:'0.3vw'}}>
                                    <Text font={'asulregular'} fontSize={1.6}>Get onto a whitelist</Text>
                                </li>
                                <li style={{listStyleType: 'decimal',margin:'0.3vw'}}>
                                    <Text font={'asulregular'} fontSize={1.6}>Mint and flip pre-reveal for a (usually) high profit or gamble for a "rare"</Text>
                                </li>
                                <li style={{listStyleType: 'decimal',margin:'0.3vw'}}>
                                    <Text font={'asulregular'} fontSize={1.6}>Reveal a common and suffer the post-reveal dump or hit dat sweet rare (but let's be real, how many times have you hit this)</Text>
                                </li>
                                <li style={{listStyleType: 'decimal',margin:'0.3vw'}}>
                                    <Text font={'asulregular'} fontSize={1.6}>Rinse and repeat</Text>
                                </li>
                            </ul>
                            <TextCol marginTop={0.5}>
                                <Text font={'asulregular'} fontSize={1.6}>We're often made to choose between profits and holding an NFT that we like in the projects we love.</Text>
                            </TextCol>
                            <TextCol marginTop={2.4}>
                                <Text font={'asulregular'} fontSize={1.6}>So, that got us thinking:Why not allow people to make a sizeable profits while getting a sick-ass looking NFT that they like without the gamble?</Text>
                            </TextCol>

                            <TextCol marginTop={5}>
                                <Text font={'asulbold'} fontSize={2} color={'#196786'}>How do we do this?</Text>
                            </TextCol>
                            <TextCol marginTop={2.4}>
                                <Text font={'asulregular'} fontSize={1.6}>We’ve come up with something so painfully simple that we’re amazed this hasn’t been done successfully more often.</Text>
                            </TextCol>
                            <TextCol marginTop={2.4}>
                                <Text font={'asulregular'} fontSize={1.6}>Instead of gambling on the primary mint for a rare, there will be a chance for both early adopters and late-comers to obtain a Pharaoh.</Text>
                            </TextCol>
                            <TextCol marginTop={2.4}>
                                <Text font={'asulregular'} fontSize={1.6}>This means obtaining the necessary assets to obtain your Pharaoh over time. Or, you could also buy your way to a Pharaoh in the secondary market and a fellow player will be better off for it.</Text>
                            </TextCol>
                            <TextCol marginTop={2.4}>
                                <Text font={'asulregular'} fontSize={1.6}>We achieve this by incoporating De-Fi to build a foundation where players can profit across every phase regardless of their chosen path. The concept of dynamic demand is achieved by the symbiotic relationship between the assets, something not yet seen in any other project.</Text>
                            </TextCol>
                            <TextCol marginTop={2.4}>
                                <Text font={'asulregular'} fontSize={1.6}>Furthermore, this approach allows us to incentivize the team to continue building the project beyond the early phases. This empowers us to continue tinkering with new ideas to introduce new utility and provide an ecosystem where everyone benefits.</Text>
                            </TextCol>

                            <Line/>

                            <TextCol marginTop={5}>
                                <Text font={'asulbold'} fontSize={2} color={'#196786'}>The rise of the Pyramids</Text>
                            </TextCol>
                            <TextCol marginTop={2.4}>
                                <Text font={'asulregular'} fontSize={1.6}>We start with mechanics that everyone is familiar with and then start introducing novel elements.</Text>
                            </TextCol>
                            <TextCol marginTop={2.4}>
                                <Text font={'asulregular'} fontSize={1.6}>The stage is set in ancient Egypt (cause that’s cool as heck) and obviously start with a mint.</Text>
                            </TextCol>
                            <TextCol marginTop={2.4}>
                                <Text font={'asulregular'} fontSize={1.6}>Phase 1.1 - Minting</Text>
                            </TextCol>
                            <ul style={{fontFamily:'asulregular',fontSize:'1.6vw'}}>
                                <li style={{listStyleType: 'decimal',margin:'0.3vw'}}>
                                    <Text font={'asulregular'} fontSize={1.6}>We start with the most popular P2E mechanism in recent memory: A 10,000 mint of 90% Peasants and 10% Nobles (yeah okay, we lied. You’re gambling here).</Text>
                                </li>
                                <li style={{listStyleType: 'decimal',margin:'0.3vw'}}>
                                    <Text font={'asulregular'} fontSize={1.6}>Your Peasants earn $PGOLD and your Nobles tax $PGOLD from the Peasants.</Text>
                                </li>
                                <li style={{listStyleType: 'decimal',margin:'0.3vw'}}>
                                    <Text font={'asulregular'} fontSize={1.6}>You use $PGOLD to mint more Peasants/Nobles to accumulate more $PGOLD</Text>
                                </li>
                            </ul>
                            <TextCol marginTop={0.5}>
                                <Text font={'asulregular'} fontSize={1.6}>Phase 1.2 - House of Horus</Text>
                            </TextCol>
                            <ul style={{fontFamily:'asulregular',fontSize:'1.6vw'}}>
                                <li style={{listStyleType: 'decimal',margin:'0.3vw'}}>
                                    <Text font={'asulregular'} fontSize={1.6}>We introduce alternative ways to earn $PGOLD through staking and bonding</Text>
                                </li>
                            </ul>

                            <TextCol marginTop={3.1}>
                                <Text font={'asulbold'} fontSize={2} color={'#196786'}>Ascension</Text>
                            </TextCol>
                            <TextCol marginTop={2.4}>
                                <Text font={'asulregular'} fontSize={1.6}>From Phase 2 onwards, we lay the foundations for a game with infinite utility and branches for players to obtain their Pharaoh.</Text>
                            </TextCol>
                            <TextCol marginTop={2.4}>
                                <Text font={'asulregular'} fontSize={1.6}>Phase 2.1 - Monuments</Text>
                            </TextCol>
                            <ul style={{fontFamily:'asulregular',fontSize:'1.6vw'}}>
                                <li style={{listStyleType: 'decimal',margin:'0.3vw'}}>
                                    <Text font={'asulregular'} fontSize={1.6}>$PGOLD can now be used to construct different monuments, which provide the player with $PWATER, $PMEAT, and $GRAIN in varying amounts. These tokens are required for the ascension of Pharaohs</Text>
                                </li>
                                <li style={{listStyleType: 'decimal',margin:'0.3vw'}}>
                                    <Text font={'asulregular'} fontSize={1.6}>Buying and selling of monument tokens will also be introduced, allowing players to strategize and optimize around assets they own.</Text>
                                </li>
                            </ul>
                            <TextCol marginTop={0.5}>
                                <Text font={'asulregular'} fontSize={1.6}>Phase 2.2 - Ascension and Revelation Events</Text>
                            </TextCol>
                            <ul style={{fontFamily:'asulregular',fontSize:'1.6vw'}}>
                                <li style={{listStyleType: 'decimal',margin:'0.3vw'}}>
                                    <Text font={'asulregular'} fontSize={1.6}>The gods of PNP arrive and bring revelation events that change the world.</Text>
                                </li>
                                <li style={{listStyleType: 'decimal',margin:'0.3vw'}}>
                                    <Text font={'asulregular'} fontSize={1.6}>Revelation events are randomly generated events that affect the combination of monument tokens required to ascend.</Text>
                                    <ul>
                                        <li style={{listStyleType: 'lower-alpha',margin:'0.3vw'}}>
                                            <Text font={'asulregular'} fontSize={1.6}>Eg. A drought has occured! The sun god, Ra, has brought his wrath upon the lands. The lands are dry and barren and no crops can be grown until Ra’s anger is satiated.</Text>
                                        </li>
                                        <li style={{listStyleType: 'none',marginTop:'2.4vw'}}>
                                            <Text font={'asulregular'} fontSize={1.6}>Pharohs now cost an additional 40% $PGRAIN to ascend!</Text>
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                            <TextCol marginTop={3}>
                                <Text font={'asulbold'} fontSize={2} color={'#196786'}>Beyond the Ascension</Text>
                            </TextCol>

                            <TextCol marginTop={2.4}>
                                <Text font={'asulregular'} fontSize={1.6}>As we’ve mentioned, we’ve built a foundation for infinite utility.</Text>
                            </TextCol>
                            <TextCol marginTop={2.4}>
                                <Text font={'asulregular'} fontSize={1.6}>A few work in progresses:</Text>
                            </TextCol>
                            <ul style={{fontFamily:'asulregular',fontSize:'1.6vw',margin:'0.3vw'}}>
                                <li style={{listStyleType: 'decimal',margin:'0.3vw'}}>
                                    <Text font={'asulregular'} fontSize={1.6}>Pharaohs Exclusive Club</Text>
                                </li>
                                <li style={{listStyleType: 'decimal',margin:'0.3vw'}}>
                                    <Text font={'asulregular'} fontSize={1.6}>Other races, monuments, and tokens (Atlanteans, Amazons etc.) that incorporates the already introduced tokens and NFTs.</Text>
                                </li>
                                <li style={{listStyleType: 'decimal',margin:'0.3vw'}}>
                                    <Text font={'asulregular'} fontSize={1.6}>The Pharaoh’s DAO</Text>
                                </li>
                                <li style={{listStyleType: 'decimal',margin:'0.3vw'}}>
                                    <Text font={'asulregular'} fontSize={1.6}>Uprising events</Text>
                                </li>
                            </ul>
                            <TextCol marginTop={0.5}>
                                <Text font={'asulregular'} fontSize={1.6}>Further down the line:</Text>
                                <ul style={{fontFamily:'asulregular',fontSize:'1.6vw',margin:'0.3vw'}}>
                                    <li style={{listStyleType: 'decimal',margin:'0.3vw'}}>
                                        <Text font={'asulregular'} fontSize={1.6}>An interactive browser/app game where players strategize around their Pharaohs to conquer ancient Egypt</Text>
                                    </li>
                                </ul>
                            </TextCol>
                            <TextCol marginTop={2.4}>
                                <Text font={'asulregular'} fontSize={1.6}>*Note: Regardless of whatever we introduce, $PGOLD and the Pharaohs collection will always remain the cornerstone of the project.</Text>
                            </TextCol>

{/*
                            <TextCol marginTop={2.4}>
                                <Text font={'asulregular'} fontSize={1.6} lineHeight={1.2}>As avid gamers, part of what hooks us to games are the graphics, gameplay, and interaction, something that is sorely lacking in the NFT P2E space.</Text>
                            </TextCol>
                            <TextCol marginTop={2.4}>
                                <Text font={'asulregular'} fontSize={1.6} lineHeight={1.2}>Simply clicking on your NFT and then "Stake" button is well..boring and is something that we'd like to change.</Text>
                            </TextCol>
                            <TextCol marginTop={2.4}>
                                <Text font={'asulregular'} fontSize={1.6} lineHeight={1.2}>Chapter II includes (but not limited to):</Text>
                            </TextCol>
                            <ul style={{fontFamily:'asulregular',fontSize:'1.6vw',margin:'0.3vw'}}>
                                <li style={{listStyleType: 'square',margin:'0.3vw'}}>
                                    <Text font={'asulregular'} fontSize={1.6}>A simple, interactive side-scroller and different maps to explore (mobile app and web app)</Text>
                                    <ul style={{fontFamily:'asulregular',fontSize:'1.6vw'}}>
                                        <li style={{listStyleType: 'circle',margin:'0.3vw'}}>
                                            <Text font={'asulregular'} fontSize={1.6}>Perform actions and interact with your NFT</Text>
                                        </li>
                                    </ul>
                                </li>
                                <li style={{listStyleType: 'square',margin:'0.3vw'}}>
                                    <Text font={'asulregular'} fontSize={1.6}>Pharaohs Exclusive Club</Text>
                                </li>
                                <li style={{listStyleType: 'square',margin:'0.3vw'}}>
                                    <Text font={'asulregular'} fontSize={1.6}>World expansions</Text>
                                    <ul style={{fontFamily:'asulregular',fontSize:'1.6vw'}}>
                                        <li style={{listStyleType: 'circle',margin:'0.3vw'}}>
                                            <Text font={'asulregular'} fontSize={1.6}>New storylines, races, and assets (The Uprising is already stirring!)</Text>
                                        </li>
                                    </ul>
                                </li>
                                <li style={{listStyleType: 'square',margin:'0.3vw'}}>
                                    <Text font={'asulregular'} fontSize={1.6}>Technical additions</Text>
                                    <ul style={{fontFamily:'asulregular',fontSize:'1.6vw'}}>
                                        <li style={{listStyleType: 'circle',margin:'0.3vw'}}>
                                            <Text font={'asulregular'} fontSize={1.6}>PNP automated liquidity protocol</Text>
                                        </li>
                                        <li style={{listStyleType: 'circle',margin:'0.3vw'}}>
                                            <Text font={'asulregular'} fontSize={1.6}>Cross-chain bridging</Text>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
*/}


                            <Line/>

                            {/*-----------------------------------------ROADMAP-----------------------------------------*/}
{/*
                            <TextCol marginTop={5}>
                                <Text font={'asulbold'} spacing={0.3} fontSize={3} color={'#196786'}>ROADMAP</Text>
                            </TextCol>
                            <RoadmapWrap>
                                <RoadmapRow>
                                    <VerticalLines/>
                                    <RoadmapBulletCircle/>
                                    <RoadmapContent>
                                        <TextCol>
                                            <Text font={'asulbold'} fontSize={2}>Phase 1.1 - The Beginning (End-Feb 2022)</Text>
                                        </TextCol>
                                        <TextCol marginTop={1}>
                                            <ul style={{fontFamily:'asulregular',fontSize:'1.6vw',margin:'0.3vw'}}>
                                                <li style={{listStyleType: 'square',margin:'0.3vw'}}>
                                                    <Text font={'asulregular'} fontSize={1.6}>Minting of 50,000 Peasants and Nobles</Text>
                                                </li>
                                                <li style={{listStyleType: 'square',margin:'0.3vw'}}>
                                                    <Text font={'asulregular'} fontSize={1.6}>Introduction of $PGOLD</Text>
                                                </li>
                                                <li style={{listStyleType: 'square',margin:'0.3vw'}}>
                                                    <Text font={'asulregular'} fontSize={1.6}>Creation of liquidity pool for $PGOLD on</Text>
                                                </li>
                                            </ul>
                                        </TextCol>
                                    </RoadmapContent>
                                </RoadmapRow>
                                <RoadmapRow marginTop={4}>
                                    <RoadmapBulletCircle/>
                                    <RoadmapContent>
                                        <TextCol>
                                            <Text font={'asulbold'} fontSize={2}>Phase 1.2 - House of Horus (Mar 2022)</Text>
                                        </TextCol>
                                        <TextCol marginTop={1}>
                                            <ul style={{fontFamily:'asulregular',fontSize:'1.6vw',margin:'0.3vw'}}>
                                                <li style={{listStyleType: 'square',margin:'0.3vw'}}>
                                                    <Text font={'asulregular'} fontSize={1.6}>Staking and Bonding of $PGOLD</Text>
                                                </li>
                                            </ul>
                                        </TextCol>

                                    </RoadmapContent>
                                </RoadmapRow>
                                <RoadmapRow marginTop={4}>
                                    <RoadmapBulletCircle/>
                                    <RoadmapContent>
                                        <TextCol>
                                            <Text font={'asulbold'} fontSize={2}>Phase 2.2 - Monument Tokens LP (Mid-Apr 2022)</Text>
                                        </TextCol>
                                        <TextCol marginTop={1}>
                                            <ul style={{fontFamily:'asulregular',fontSize:'1.6vw',margin:'0.3vw'}}>
                                                <li style={{listStyleType: 'square',margin:'0.3vw'}}>
                                                    <Text font={'asulregular'} fontSize={1.6}>LP for $PWATER, $PMEAT, $PGRAIN to be created</Text>
                                                </li>
                                                <li style={{listStyleType: 'square',margin:'0.3vw'}}>
                                                    <Text font={'asulregular'} fontSize={1.6}>Lending and Borrowing market for PWATER, PMEAT, PGRAIN.</Text>
                                                </li>
                                            </ul>
                                        </TextCol>
                                    </RoadmapContent>
                                </RoadmapRow>
                                <RoadmapRow marginTop={4}>
                                    <RoadmapBulletCircle/>
                                    <RoadmapContent>
                                        <TextCol>
                                            <Text font={'asulbold'} fontSize={2}>Phase 2.3 - Ascension (End-Apr 2022)</Text>
                                        </TextCol>
                                        <TextCol marginTop={1}>
                                            <ul style={{fontFamily:'asulregular',fontSize:'1.6vw',margin:'0.3vw'}}>
                                                <li style={{listStyleType: 'square',margin:'0.3vw'}}>
                                                    <Text font={'asulregular'} fontSize={1.6}>Pharaohs released</Text>
                                                </li>
                                                <li style={{listStyleType: 'square',margin:'0.3vw'}}>
                                                    <Text font={'asulregular'} fontSize={1.6}>Revelation events occurrences</Text>
                                                    <ul style={{fontFamily:'asulregular',fontSize:'1.6vw',margin:'1vw 0.3vw 0.3vw 0.3vw'}}>
                                                        <li style={{listStyleType: 'circle',margin:'0.3vw'}}>
                                                            <Text font={'asulregular'} fontSize={1.6} lineHeight={1.2}>Revelation events affect how much individual monument tokens will be required to mint a Pharaoh</Text>
                                                        </li>
                                                        <li style={{listStyleType: 'circle',margin:'0.3vw'}}>
                                                            <Text font={'asulregular'} fontSize={1.6} lineHeight={1.2}>Eg. Famine has happened! The land looked barren as far as the eyes can see under the blinding sun. Land made rock hard and dried by the very sun god, Ra. A lifeless land that has nothing growing on it. PGRAIN production in Monuments fall by 20% and the price to ascend a Pharaoh increase 40%.</Text>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </TextCol>
                                    </RoadmapContent>
                                </RoadmapRow>
                                <RoadmapRow marginTop={4}>
                                    <RoadmapBulletCircle/>
                                    <RoadmapContent>
                                        <TextCol>
                                            <Text font={'asulbold'} fontSize={2}>Phase 3 - Pharaohs Exclusive (May 2022)</Text>
                                        </TextCol>
                                        <TextCol marginTop={1}>
                                            <Text font={'asulregular'} fontSize={1.6}>The Pharaohs exclusive Club will have perks including but not limited to:</Text>
                                            <ul style={{fontFamily:'asulregular',fontSize:'1.6vw',margin:'0.3vw'}}>
                                                <li style={{listStyleType: 'square',margin:'0.3vw'}}>
                                                    <Text font={'asulregular'} fontSize={1.6}>Exclusive metaverse hangout (tentatively a Sandbox plot)</Text>
                                                </li>
                                                <li style={{listStyleType: 'square',margin:'0.3vw'}}>
                                                    <Text font={'asulregular'} fontSize={1.6}>Pharaohs whitelist program</Text>
                                                </li>
                                                <li style={{listStyleType: 'square',margin:'0.3vw'}}>
                                                    <Text font={'asulregular'} fontSize={1.6}>Bi-weekly sacrificial box</Text>
                                                </li>
                                            </ul>
                                        </TextCol>
                                    </RoadmapContent>
                                </RoadmapRow>
                                <RoadmapRow marginTop={4}>
                                    <RoadmapBulletCircle/>
                                    <RoadmapContent>
                                        <TextCol>
                                            <Text font={'asulbold'} fontSize={2}>Phase 4 (and Beyond) - Utility Expansion (Jun 2022)</Text>
                                        </TextCol>
                                        <TextCol marginTop={1}>
                                            <Text font={'asulregular'} fontSize={1.6} lineHeight={1.2}>We are looking to introduce even more actual DeFi elements to the project that would be too much to introduce all at once at this point</Text>
                                            <ul style={{fontFamily:'asulregular',fontSize:'1.6vw',margin:'0.3vw',marginTop:'1vw'}}>
                                                <li style={{listStyleType: 'square',margin:'0.3vw'}}>
                                                    <Text font={'asulregular'} fontSize={1.6}>These elements will tie in with existing assets while introducing new ones</Text>
                                                    <ul style={{fontFamily:'asulregular',fontSize:'1.6vw',margin:'0.3vw'}}>
                                                        <li style={{listStyleType: 'circle',margin:'1vw 0.3vw 0.3vw 0.3vw'}}>
                                                            <Text font={'asulregular'} fontSize={1.6} lineHeight={1.2}>Eg. Atlantean race that uses $PWATER, revolutions that involve peasants</Text>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </TextCol>
                                    </RoadmapContent>
                                </RoadmapRow>
                            </RoadmapWrap>
                            <Line/>

*/}

                            <TextCol marginTop={5}>
                                <Text font={'asulbold'} spacing={0.3} fontSize={3} color={'#196786'}>ROADMAP</Text>
                            </TextCol>
                            <RoadmapWrap>
                                <RoadmapRow>
                                    <VerticalLines/>
                                    <RoadmapBulletCircle/>
                                    <RoadmapContent>
                                        <TextCol>
                                            <Text font={'asulbold'} fontSize={2}>Phase 1.1 - The Beginning (May 2022)</Text>
                                        </TextCol>
                                        <TextCol marginTop={1}>
                                            <ul style={{fontFamily:'asulregular',fontSize:'1.6vw',margin:'0.3vw'}}>
                                                <li style={{listStyleType: 'square',margin:'0.3vw'}}>
                                                    <Text font={'asulregular'} fontSize={1.6}>Minting of 50,000 Peasants and Nobles</Text>
                                                </li>
                                                <li style={{listStyleType: 'square',margin:'0.3vw'}}>
                                                    <Text font={'asulregular'} fontSize={1.6}>Introduction of $PGOLD</Text>
                                                </li>
                                                <li style={{listStyleType: 'square',margin:'0.3vw'}}>
                                                    <Text font={'asulregular'} fontSize={1.6}>Creation of liquidity pool for $PGOLD</Text>
                                                </li>
                                            </ul>
                                        </TextCol>
                                    </RoadmapContent>
                                </RoadmapRow>
                                <RoadmapRow marginTop={4}>
                                    <RoadmapBulletCircle/>
                                    <RoadmapContent>
                                        <TextCol>
                                            <Text font={'asulbold'} fontSize={2}>Phase 1.2 - House of Horus (Q3 2022)</Text>
                                        </TextCol>
                                        <TextCol marginTop={1}>
                                            <ul style={{fontFamily:'asulregular',fontSize:'1.6vw',margin:'0.3vw'}}>
                                                <li style={{listStyleType: 'square',margin:'0.3vw'}}>
                                                    <Text font={'asulregular'} fontSize={1.6}>Staking and Bonding of $PGOLD</Text>
                                                </li>
                                            </ul>
                                        </TextCol>

                                    </RoadmapContent>
                                </RoadmapRow>
                                <RoadmapRow marginTop={4}>
                                    <RoadmapBulletCircle/>
                                    <RoadmapContent>
                                        <TextCol>
                                            <Text font={'asulbold'} fontSize={2}>Phase 2.1 - Monument Tokens LP (Q3 2022)</Text>
                                        </TextCol>
                                        <TextCol marginTop={1}>
                                            <ul style={{fontFamily:'asulregular',fontSize:'1.6vw',margin:'0.3vw'}}>
                                                <li style={{listStyleType: 'square',margin:'0.3vw'}}>
                                                    <Text font={'asulregular'} fontSize={1.6}>LP for $PWATER, $PMEAT, $PGRAIN to be created</Text>
                                                </li>
                                            </ul>
                                        </TextCol>
                                    </RoadmapContent>
                                </RoadmapRow>
                                <RoadmapRow marginTop={4}>
                                    <RoadmapBulletCircle/>
                                    <RoadmapContent>
                                        <TextCol>
                                            <Text font={'asulbold'} fontSize={2}>Phase 2.2 - Ascension (Q3 2022)</Text>
                                        </TextCol>
                                        <TextCol marginTop={1}>
                                            <ul style={{fontFamily:'asulregular',fontSize:'1.6vw',margin:'0.3vw'}}>
                                                <li style={{listStyleType: 'square',margin:'0.3vw'}}>
                                                    <Text font={'asulregular'} fontSize={1.6}>Pharaohs released</Text>
                                                </li>
                                                <li style={{listStyleType: 'square',margin:'0.3vw'}}>
                                                    <Text font={'asulregular'} fontSize={1.6}>Revelation events occurrences</Text>
                                                    <ul style={{fontFamily:'asulregular',fontSize:'1.6vw',margin:'1vw 0.3vw 0.3vw 0.3vw'}}>
                                                        <li style={{listStyleType: 'circle',margin:'0.3vw'}}>
                                                            <Text font={'asulregular'} fontSize={1.6} lineHeight={1.2}>Revelation events affect how much individual monument tokens will be required to mint a Pharaoh</Text>
                                                        </li>
                                                        <li style={{listStyleType: 'circle',margin:'0.3vw'}}>
                                                            <Text font={'asulregular'} fontSize={1.6} lineHeight={1.2}>Eg. Famine has happened! The land looked barren as far as the eyes can see under the blinding sun. Land made rock hard and dried by the very sun god, Ra. A lifeless land that has nothing growing on it. PGRAIN production in Monuments fall by 20% and the price to ascend a Pharaoh increase 40%.</Text>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </TextCol>
                                    </RoadmapContent>
                                </RoadmapRow>
                            </RoadmapWrap>
                            <Line/>

                            {/*-----------------------------------------END ROADMAP-----------------------------------------*/}

                            <TextCol marginTop={3}>
                                <Text font={'asulbold'} fontSize={2} color={'#196786'}>Chapter II (and Beyond) -  PNP Mobile  & Web-app</Text>
                            </TextCol>
                            <TextCol marginTop={2.4}>
                                <Text font={'asulregular'} fontSize={1.6}>As avid gamers, part of what hooks us to games are the graphics, gameplay, and interaction, something that is sorely lacking in the NFT P2E space.</Text>
                            </TextCol>
                            <TextCol marginTop={2.4}>
                                <Text font={'asulregular'} fontSize={1.6}>Simply clicking on your NFT and then the "Stake" button is well..boring and is something that we'd like to change.</Text>
                            </TextCol>
                            <TextCol marginTop={2.4}>
                                <Text font={'asulregular'} fontSize={1.6}>Chapter II includes (but not limited to):</Text>
                            </TextCol>
                            <ul style={{fontFamily:'asulregular',fontSize:'1.6vw'}}>
                                <li style={{listStyleType: 'square',margin:'0.3vw'}}>
                                    <Text font={'asulregular'} fontSize={1.6}>A simple, interactive side-scroller and different maps to explore (mobile app and web app)</Text>
                                    <ul style={{fontFamily:'asulregular',fontSize:'1.6vw',margin:'1vw 0.3vw 0.3vw 0.3vw'}}>
                                        <li style={{listStyleType: 'circle',margin:'0.3vw'}}>
                                            <Text font={'asulregular'} fontSize={1.6} lineHeight={1.2}>Perform actions and interact with your NFT</Text>
                                        </li>
                                    </ul>
                                </li>
                                <li style={{listStyleType: 'square',margin:'0.3vw'}}>
                                    <Text font={'asulregular'} fontSize={1.6}>Pharaohs Exclusive Club</Text>
                                </li>
                                <li style={{listStyleType: 'square',margin:'0.3vw'}}>
                                    <Text font={'asulregular'} fontSize={1.6}>World expansions</Text>
                                    <ul style={{fontFamily:'asulregular',fontSize:'1.6vw',margin:'1vw 0.3vw 0.3vw 0.3vw'}}>
                                        <li style={{listStyleType: 'circle',margin:'0.3vw'}}>
                                            <Text font={'asulregular'} fontSize={1.6} lineHeight={1.2}>New storylines, races, and assets (The Uprising is already stirring!)</Text>
                                        </li>
                                    </ul>
                                </li>
                                <li style={{listStyleType: 'square',margin:'0.3vw'}}>
                                    <Text font={'asulregular'} fontSize={1.6}>Technical additions</Text>
                                    <ul style={{fontFamily:'asulregular',fontSize:'1.6vw',margin:'1vw 0.3vw 0.3vw 0.3vw'}}>
                                        <li style={{listStyleType: 'circle',margin:'0.3vw'}}>
                                            <Text font={'asulregular'} fontSize={1.6} lineHeight={1.2}>PNP automated liquidity protocol</Text>
                                        </li>
                                        <li style={{listStyleType: 'circle',margin:'0.3vw'}}>
                                            <Text font={'asulregular'} fontSize={1.6} lineHeight={1.2}>Cross-chain bridging</Text>
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                            <Line/>

                            <TextCol marginTop={3}>
                                <Text font={'asulbold'} fontSize={2} color={'#196786'}>Transparency</Text>
                            </TextCol>
                            <TextCol marginTop={2.4}>
                                <Text font={'asulregular'} fontSize={1.6}>Most projects that promise infinite utility tend to fizzle out with a whimper because of  two compounding reasons:</Text>
                            </TextCol>
                            <ul style={{fontFamily:'asulregular',fontSize:'1.6vw'}}>
                                <li style={{listStyleType: 'decimal',margin:'0.3vw'}}>
                                    <Text font={'asulregular'} fontSize={1.6}>The community asks “wen next utility” endlessly and disintegrates the moment anything goes wrong</Text>
                                </li>
                                <li style={{listStyleType: 'decimal',margin:'0.3vw'}}>
                                    <Text font={'asulregular'} fontSize={1.6}>The devs aren’t paid enough to keep up with utility demand (okay maybe they are but if they’ve gotten your eth from the primary mint, what real incentive do they really have to continue building the project?)</Text>
                                </li>
                            </ul>
                            <TextCol marginTop={0.5}>
                                <Text font={'asulregular'} fontSize={1.6}>Which is why we have chosen to focus on</Text>
                            </TextCol>
                            <TextCol marginTop={2.4}>
                                <Text font={'asulregular'} fontSize={1.6}>Aside from the primary mint (which majority of the funds will be committed to the project anyways), we <span style={{fontFamily:'asulbold',fontSize:'1.6vw'}}>WILL</span> profit throughout the lifetime of the project through each phase.</Text>
                            </TextCol>
                            <TextCol marginTop={2.4}>
                                <Text font={'asulregular'} fontSize={1.6}>Basically, we’re going to be profiting off you guys but you won’t care because you’re making too much of your own profits to care.</Text>
                            </TextCol>

                            <div style={{width:'100%',display:'flex',justifyContent:'center',margin:'6vw 0 0 0'}}>
                                <Button
                                    width={14}
                                    height={4}
                                    color={'#fff'}
                                >
                                    <ButtonInner
                                        width={14 * 0.94}
                                        height={4 * 0.8}
                                        bg={'linear-gradient(180deg, #1A6988 0%, #0B325D 100%)'}
                                        font={'asulbold'}
                                        fontSize={1.5}
                                        onClick={props.close}
                                    >
                                        Close
                                    </ButtonInner>
                                </Button>
                            </div>

                        </ModalContentInner>
                    </ModalContent>
                </ModalBg>
            </ModalWrap>
        </Container>
    )
}

export default LitePaperModal;

export const TextCol = styled('div')<{marginTop?:number}>`
    display: flex;
    flex-direction: column;
    margin-top: ${props => props.marginTop || 0}vw;
`
const Text = styled('div')<{font?:string,fontSize?:number,spacing?:number,lineHeight?:number,color?:string}>`
    font-family: ${props => props.font};
    font-size: ${props => props.fontSize}vw;
    letter-spacing: ${props => props.spacing || 0.01}vw;
    line-height: ${props => props.lineHeight || 1};
    color: ${props => props.color};
`

const Container = styled.div`
    position: relative;
    width: 100%;
    // padding: 1rem;
    
    // height: 70rem;
    // min-height: 10vh;
    // border-radius: 1.5rem;
    // overflow: hidden;
`
const ModalWrap = styled.div`
    margin: 3rem;
    display: flex;
    justify-content: center;
    border-radius: 2.4vw;
    overflow: hidden;
    // height: 100%;
    
`
const ModalBg = styled('div')<{bg?:string}>`
    position: relative;
    width: 100%;

    // height: 100%;
    // background-image: url(${props => props.bg});
    // background-repeat: no-repeat;
    // background-size: 106.5%;
    

    margin-top: -2.7rem;
    
    // margin-top: -5.8rem;

    
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #DEA464;

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        // top: 0;
        // left: 0;
        background-image: url(${props => props.bg});
        background-repeat: no-repeat;
        background-size: 106.5%;
        background-position: top;
        -webkit-filter: blur(1rem); 
        filter: blur(1rem);
        backdrop-filter: blur(1rem);
    }
    
`
const ModalContent = styled.div`
    // position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin-top: 3rem;
    width: 100%;
    // height: 200rem;
    // background-color: rgba(255,0,0,0.3);
    // padding: 4% 4% 0 4%;
    margin-bottom: 4%;
    padding-left: 4%;
    padding-right: 4%;
    padding-top: 6%; 
    z-index: 1;
    @media (max-width: ${props => Breakpoint.lg}) {
        padding-top: 8%; 
    }
    @media (max-width: ${props => Breakpoint.md}) {
        padding-top: 10%; 
    }
    @media (max-width: ${props => Breakpoint.sm}) {
        padding-top: 15%; 
    }
`
const ModalContentInner = styled.div`
    width: 100%;
    background-color: #fff;
    border-radius: 2vw;
    box-shadow: 0 0.187rem 0.625rem rgb(0 0 0 / 0.2);    
    z-index: 3;
    display: flex;
    flex-direction: column;
    padding: 7vw 5vw;
`
const LPContentHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    // text-align: center;
    // height: 20rem;
    // background-color: rgba(255,0,0,0.3);
    padding: 0 3% 0 3%;
    margin-bottom: -8.3%;
    position: relative;
    z-index: 2;
`
const ModalImage = styled('img')<{wPct?:number,marginTopPct?:number}>`
    width: ${props => props.wPct}%;
    height: 100%;
`
const LPLogo = styled('div')`
    position: relative;    
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 2%;
    @media (max-width: ${props => Breakpoint.lg}) {
        transform: scale(0.9);
        transform-origin: top;
    }
    @media (max-width: ${props => Breakpoint.md}) {
        transform: scale(0.8);
        transform-origin: top;
    }
`

const Line = styled.hr`
    width: 100%;
    border: none;
    border-bottom: 0.06vw dashed #000;
    margin-top: 5vw;
`

const RoadmapWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 5vw;
`
const RoadmapRow = styled('div')<{marginTop?:number}>`
    display: flex;
    flex-direction: row;
    margin-top: ${props => props.marginTop}vw;
`
const RoadmapContent = styled.div`
    margin-left: 2vw;
`
const RoadmapBulletCircleWrap = styled.div`
    
`
const _RoadmapBulletCircle = styled.div`
 background: linear-gradient(#222, #222), 
              linear-gradient(to right, red, purple);
  // background-origin: padding-box, border-box;
  background-repeat: no-repeat; /* this is important */
  border: 5px solid transparent;
      width: 2vw;
    height: 2vw;
    border-radius: 50%;

  `
const RoadmapBulletCircle = styled.div`
    position: relative;
    width: 1.8vw;
    height: 1.8vw;
    min-width: 1.8vw;
    min-height: 1.8vw;
    border-radius: 50%;
    background: linear-gradient(180deg, #B83B14 0%, #872E11 100%);
    &:after {
        position: absolute;
        content: '';
        display: inline-block;
        width: 45%;
        height: 45%;
        border-radius: 50%;
        background-color: #fff;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
    }
`
const VerticalLine = styled.div`
    position: absolute;
    width: 1rem;
    height: 1rem;
    background-color: #000;
    repeat(4, 1fr);
`

const VerticalLines = (props:any) => {
    // const ctr = 109;
    const ctr = 49;
    return (
        <VerticalLineContainer>
            {
                [...new Array(ctr)].map((i, idx) => {
                    return <VLine key={props.tag + '-' + idx}/>
                })
            }
        </VerticalLineContainer>
    )
}
const VerticalLineContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    margin-left: 0.84vw;
`
const VLine = styled.div`
    width: 0.08vw;
    height: 0.32vw;
    background-color: #000;
    margin-bottom: 0.4vw;
`



const LitePaperHeader = () => {
    return (
        <LPContentHeader>
            {/*<ImageWrap>*/}
                <ModalImage src={require('../assets/images/litepaper-people-1.svg').default} wPct={22} />
            {/*</ImageWrap>*/}
            <LPLogo>
                <img src={ImageLogo} style={{width:'50%'}}/>
                <div style={{position:'relative', display:'flex',width:'100%',justifyContent:'center'}}>
                    <Button
                        width={32}
                        height={7}
                        color={'#fff'}
                        borderW={0.2}
                    >
                        <ButtonInner
                            width={32 * 0.97}
                            height={7 * 0.85}
                            bg={'linear-gradient(180deg, #228CA3 0%, #000D40 100%)'}
                            font={'asulbold'}
                            fontSize={3.3}
                            borderW={0.2}
                            letterSpacing={0.3}
                        >
                            LITEPAPER
                        </ButtonInner>
                    </Button>
                </div>
            </LPLogo>
            <ModalImage src={require('../assets/images/litepaper-people-2.svg').default} wPct={21} />
        </LPContentHeader>
    )
}

