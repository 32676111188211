import React, {useEffect, useState, useRef} from 'react';
import {createRoot} from "react-dom/client";
import styled, {keyframes} from "styled-components";
import {Breakpoint, BACKEND_SERVER} from "../../constants";
import {getPNP, generateArt} from "../../redux/blockchain/actions/PNPAction";
import {useSelector,useDispatch} from "react-redux";
import{clearPeasants,clearNobles,addPeasant,addNoble,updatePeasant,updateNoble} from '../../redux/blockchain/reducers/pnpReducer';

function imageExists(url:string, callback:any) {
    const img = new Image();
    img.src = url;
    if (img.complete) {
        callback(img.src);
    } else {
        img.onload = () => {
            callback(img.src);
        };

        img.onerror = () => {
            callback('');
        };
    }
}

export default function PeasantsNobles(props:any) {

    const dispatch = useDispatch();
    // @ts-ignore
    const { chainId, allowedNetworks, account } = useSelector((state) => state.profile);
    // @ts-ignore
    const {peasants, nobles} = useSelector((state) => state.pnp);
    const refNoImage = useRef<(HTMLDivElement | null)[]>([]);

    async function testOnGenerateImage() {
        const tokenIds = [];

        for (let i=0; i<peasants.length; i++) {
            await tokenIds.push(peasants[i].tokenId);
        }
        for (let i=0; i<nobles.length; i++) {
            await tokenIds.push(nobles[i].tokenId);
        }

        const data:any = await generateArt(tokenIds);

        for (let i = 0; i < data.length; i++) {
            const nft = data[i];
            if (nft.isPeasant) {
                dispatch(updatePeasant(nft));
            } else {
                dispatch(updateNoble(nft));
            }
        }
    }

    async function handleGenerateImage(tId:any) {
        // @ts-ignore
        const root = createRoot(refNoImage.current[tId]);
        root.render(
            <div className="sk-wave" style={{position: 'absolute'}}>
                <div className="sk-wave-rect"></div>
                <div className="sk-wave-rect"></div>
                <div className="sk-wave-rect"></div>
                <div className="sk-wave-rect"></div>
                <div className="sk-wave-rect"></div>
            </div>
        );

        const tokenIds = [tId];

        const data:any = await generateArt(tokenIds);
        root.unmount();

        for (let i = 0; i < data.length; i++) {
            const nft = data[i];
            if (nft.isPeasant) {
                dispatch(updatePeasant(nft));
            } else {
                dispatch(updateNoble(nft));
            }
        }
    }

    useEffect(() => {
        (async () => {
            try {
                if (chainId > 0 && allowedNetworks.includes(chainId)) {
                    if (account) {
                        dispatch(clearPeasants());
                        dispatch(clearNobles());
                        const [peasantIds, nobleIds] = await getPNP(account);

                        for (let i=0; i<peasantIds.length; i++) {
                            const imgPath = `${BACKEND_SERVER}/peasants/${peasantIds[i]}.png`;
                            await imageExists(imgPath, async (src:string) => {
                                setTimeout(() => dispatch(addPeasant({tokenId:peasantIds[i],image:src,imageUrl:imgPath})), 500 * (i+1));
                            });
                        }

                        for (let j=0; j<nobleIds.length; j++) {
                            const imgPath = `${BACKEND_SERVER}/nobles/${nobleIds[j]}.png`;
                            await imageExists(imgPath, async (src:string) => {
                                setTimeout(() => dispatch(addNoble({tokenId:nobleIds[j],image:src,imageUrl:imgPath})), 500 * (j+1));
                            });
                        }
                    }
                }
            } catch (e) {
                console.log('Peasants&Nobles Error:', e)
            }
        })();
    }, [chainId,account])

    return (
        <Container>
            <PNBox>
                <PNText>Your Peasants</PNText>
                <ImagesWrap>
                    {
                        (peasants && peasants.length > 0) && (
                            peasants.map((item:any) => {
                                return item['image'] ? (
                                    <Nft key={`peasant-${item['tokenId']}`}>
                                        <PNImage src={item['image']} onClick={() => props.openImage ? props.openImage(item['imageUrl']) : null}/>
                                        <TokenId>#{item['tokenId']}</TokenId>
                                    </Nft>
                                ) : (
                                    <Nft key={`peasant-${item['tokenId']}`}>
                                        <NoImage
                                            onClick={() => handleGenerateImage(item['tokenId'])}
                                            ref={el => refNoImage.current[item['tokenId']] = el}>
                                            <NoImageTextWrap>
                                                <NoImageText bold>No image yet?</NoImageText>
                                                <NoImageText marginTopPct={5}>Click here to generate</NoImageText>
                                            </NoImageTextWrap>
                                        </NoImage>
                                        <TokenId>#{item['tokenId']}</TokenId>
                                    </Nft>
                                )
                            })
                        )
                    }
                </ImagesWrap>
            </PNBox>
            <MiddleSpace/>
            <PNBox>
                <PNText>Your Nobles</PNText>
                <ImagesWrap>
                    {
                        (nobles && nobles.length > 0) && (
                            nobles.map((item:any) => {
                                return item['image'] ? (
                                    <Nft key={`noble-${item['tokenId']}`}>
                                        <PNImage src={item['image']} onClick={() => props.openImage ? props.openImage(item['imageUrl']) : null}/>
                                        <TokenId>#{item['tokenId']}</TokenId>
                                    </Nft>
                                ) : (
                                    <Nft key={`noble-${item['tokenId']}`}>
                                        <NoImage
                                            onClick={() => handleGenerateImage(item['tokenId'])}
                                            ref={el => refNoImage.current[item['tokenId']] = el}>
                                            <NoImageTextWrap>
                                                <NoImageText bold>No image yet?</NoImageText>
                                                <NoImageText marginTopPct={5}>Click here to generate</NoImageText>
                                            </NoImageTextWrap>
                                        </NoImage>
                                        <TokenId>#{item['tokenId']}</TokenId>
                                    </Nft>

                                )
                            })
                        )
                    }
                </ImagesWrap>
            </PNBox>
        </Container>
    );
}

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    @media (max-width: ${props => Breakpoint.lg}) {
        flex-direction: column;       
    }
`

const PNBox = styled.div`
    position: relative;
    width: 100%;
    border-radius: 1rem;
    border: 0.24rem solid #3A2206;
    background-color: #EDD79B;
    box-sizing: border-box;
    box-shadow: 0.5rem 0.5rem #3A2206;
    padding: 2rem 1.5rem;
    display: flex;
    flex-direction: column;
    @media (max-width: ${props => Breakpoint.lg}) {
        margin-bottom: 1.2rem;        
    }    
    @media (max-width: ${props => Breakpoint.sm}) {
    }
`
const PNText = styled.span`
    font-family: asulbold;
    font-size: 1.7rem;
    line-height: 1;
    margin-bottom: 2rem;
`

const MiddleSpace = styled.div`
    width: 3%;
    @media (max-width: ${props => Breakpoint.lg}) {
        display: none;        
    }    
`
const ImagesWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1.2rem;    
`
const Nft = styled.div`
    width: 7.7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    animation: ${props => nftFadeIn} 500ms forwards;
`
const nftFadeIn = keyframes`
    0%{opacity:0;}
    100%{opacity:1;}
`
const PNImage = styled.img`
    width: 7.7rem;
    height: 7.7rem;
    border-radius: 1rem;
    border: 0.17rem solid #3A2206;
    box-shadow: 0.3rem 0.4rem #3A2206;
    margin-bottom: 0.7rem;
    cursor: pointer;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
`
const TokenId = styled.span`
    font-family: asulregular;
    font-size: 1rem;
`
const NoImage = styled.div`
    width: 7.7rem;
    height: 7.7rem;
    border-radius: 1rem;
    border: 0.17rem solid #3A2206;
    box-shadow: 0.3rem 0.4rem #3A2206;
    margin-bottom: 0.7rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
const NoImageTextWrap = styled.div`
    width: inherit;
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`
const NoImageText = styled('span')<{bold?:boolean,marginTopPct?:number}>`
    font-size: 0.8rem;
    text-align: center;
    ${props => props.bold ? 'font-weight:700' : ''};
    margin-top: ${props => props.marginTopPct || 0}%;
`