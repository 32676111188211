import React from 'react';
import styled from 'styled-components';
import {Text, TextCol, BoxSection} from '../Styles';
import {Breakpoint} from "../constants";
import {LineWithArrow} from "./Custom";

export default function BoxHowPnpWork() {
    return (
        <BoxSection marginTop={2}>
            <Wrapper>
                <TextCol>
                    <Text font='asulbold' weight={900} size={28}>How does PNP work?</Text>
                </TextCol>

                <FlexWrap>
                    <CellText>
                        <TextCol marginBottom={1.8}>
                            <Text lineHeight={1.2}>
                                Peasants accumulate 10,000 $PGOLD every day for toiling the lands, while the Nobles tax 20% of their earnings upon claim. Nobles also exercise their influence to enslave new mints with a 10% chance.
                            </Text>
                        </TextCol>
                        <TextCol>
                            <Text lineHeight={1.2}>
                                There are different strategies you can use to accumulate the tokens required to obtain a Pharaoh, including the House of Horus staking.
                            </Text>
                        </TextCol>
                    </CellText>
                    <CellText>
                        <TextCol marginBottom={1.8}>
                            <Text lineHeight={1.2}>
                                You will require $PGOLD to construct the different monuments to obtain the tokens required for the Ascension Ritual: $PWATER, $PMEAT, and $PGRAIN.
                            </Text>
                        </TextCol>
                        <TextCol marginBottom={1.8}>
                            <Text lineHeight={1.2}>
                                $PGOLD and the Pharaohs will be the cornerstones of PNP beyond Chapter l.
                            </Text>
                        </TextCol>
                    </CellText>
                </FlexWrap>

                <ImagesSectionDesktop>
                    <ImageGroup widthInPct={65}>
                        <Cell1>
                            <InnerImg src={require('../assets/images/peasant.svg').default} width={73} marginTopPct={-20} />
                            <InnerImg src={require('../assets/images/noble.svg').default} width={82} marginTopPct={-24} />
                        </Cell1>
                        <Cell2>
                            <InnerImg src={require('../assets/images/arrow-right-short-orange.svg').default} width={118} marginTopPct={214} />
                        </Cell2>
                        <Cell3>
                            <InnerImg src={require('../assets/images/group-of-pgolds.svg').default} width={110} />
                            <InnerImg src={require('../assets/images/arrow-right-short-orange.svg').default} width={32} style={{transform:'rotate(90deg)'}} marginTopPct={17} />
                            <InnerImg src={require('../assets/images/group-of-pgolds.svg').default} width={110} marginTopPct={8} />
                        </Cell3>
                        <Cell4>
                            <InnerImg src={require('../assets/images/arrow-right-long-orange.svg').default} width={118} marginTopPct={91} />
                            <InnerImg src={require('../assets/images/arrow-right-medium-orange.svg').default} width={80} marginTopPct={222} />
                        </Cell4>
                        <Cell5>
                            <InnerImg src={require('../assets/images/temple.svg').default} width={119} />
                        </Cell5>
                        <Cell6>
                            <InnerImg src={require('../assets/images/arrow-right-short-orange.svg').default} width={118} marginTopPct={215} />
                            <InnerImg src={require('../assets/images/arrow-right-short-orange.svg').default} width={118} marginTopPct={215} />
                            <InnerImg src={require('../assets/images/arrow-right-short-orange.svg').default} width={118} marginTopPct={215} />
                        </Cell6>
                        <Cell7>
                            <InnerImg src={require('../assets/images/pmeat.svg').default} width={104} marginTopPct={55} />
                            <InnerImg src={require('../assets/images/pgrain.svg').default} width={104} marginTopPct={30}  />
                            <InnerImg src={require('../assets/images/pwater.svg').default} width={104} marginTopPct={20}/>
                        </Cell7>
                        <Cell8>
                            <InnerImg src={require('../assets/images/line-short-orange.svg').default} width={102} marginTopPct={200} />
                            <InnerImg src={require('../assets/images/line-short-orange.svg').default} width={102} marginTopPct={512} />
                            <InnerImg src={require('../assets/images/line-long-orange.svg').default} width={28} marginTopPct={200} marginLeftPct={90}  absolute />
                            <InnerImg src={require('../assets/images/arrow-right-long-orange.svg').default} width={230} marginTopPct={450} absolute />
                        </Cell8>
                    </ImageGroup>
                    <PharaohImageGroup>
                        <InnerImg src={require('../assets/images/ellipse.svg').default} width={115} />
                        <InnerImg src={require('../assets/images/pharaoh-silhouette.svg').default} width={47} absolute style={{bottom:0}} />
                    </PharaohImageGroup>

                </ImagesSectionDesktop>

                <ImagesSectionMobile>

                    <Cell1>
                        <div style={{position:'relative',display:'flex',justifyContent:'center'}}>
                            <InnerImage src={require('../assets/images/peasant.svg').default} height={8} />
                            <InnerImage
                                src={require('../assets/images/arrow-right-short-orange.svg').default}
                                height={1}
                                rotate={'90deg'}
                                style={{marginTop:'7.5rem'}}
                                absolute
                            />
                            <InnerImage
                                src={require('../assets/images/group-of-pgolds.svg').default}
                                height={5.2}
                                style={{marginTop:'9.3rem'}}
                                absolute
                            />
                            <InnerImage
                                src={require('../assets/images/arrow-right-short-orange.svg').default}
                                height={1}
                                rotate={'90deg'}
                                absolute
                                style={{marginTop:'16rem'}}
                            />
                        </div>
                        <div style={{position:'relative',display:'flex',justifyContent:'center', marginLeft:'16%'}}>
                            <InnerImage src={require('../assets/images/noble.svg').default} height={8} />
                            <InnerImage
                                src={require('../assets/images/group-of-pgolds.svg').default}
                                height={5.2}
                                style={{marginTop:'9.3rem'}}
                                absolute
                            />
                            <InnerImage
                                src={require('../assets/images/arrow-right-short-orange.svg').default}
                                height={1}
                                rotate={'90deg'}
                                absolute
                                style={{marginTop:'16rem'}}
                            />
                        </div>
                        <InnerImage src={require('../assets/images/arrow-right-short-orange.svg').default} height={1} absolute style={{marginTop:'12rem'}} />
                    </Cell1>

                    <div style={{position:'relative',display:'flex',justifyContent:'center',marginTop:'9rem'}}>
                        <TempleWrap>
                            <InnerImage src={require('../assets/images/temple.svg').default} height={11} />
                        </TempleWrap>
                    </div>

                    <Cell1 style={{marginTop:'1.5rem'}}>
                        <div style={{position:'relative',display:'flex',justifyContent:'center'}}>
                            <InnerImage
                                src={require('../assets/images/arrow-right-short-orange.svg').default}
                                height={1}
                                rotate={'90deg'}
                            />
                            <InnerImage src={require('../assets/images/pmeat.svg').default} height={3} absolute style={{marginTop:'2rem'}} />
                        </div>
                        <div style={{position:'relative',display:'flex',justifyContent:'center'}}>
                            <InnerImage
                                src={require('../assets/images/arrow-right-short-orange.svg').default}
                                height={1}
                                rotate={'90deg'}
                                style={{margin:'0 2rem 0 2rem'}}
                            />
                            <InnerImage src={require('../assets/images/pgrain.svg').default} height={3} absolute style={{marginTop:'2rem'}}  />
                        </div>
                        <div style={{position:'relative',display:'flex',justifyContent:'center'}}>
                            <InnerImage
                                src={require('../assets/images/arrow-right-short-orange.svg').default}
                                height={1}
                                rotate={'90deg'}
                            />
                            <InnerImage src={require('../assets/images/pwater.svg').default} height={3} absolute style={{marginTop:'2rem'}} />
                        </div>
                    </Cell1>
                    <div style={{position:'relative',display:'flex',justifyContent:'center',marginTop:'5rem'}}>
                        <LineWithArrow/>
                    </div>
                    <PharaohImageGroup>
                        <div style={{position:'relative',display:'flex',justifyContent:'center',alignItems:'flex-end'}}>
                            <InnerImg src={require('../assets/images/ellipse.svg').default} width={100} />
                            <InnerImg src={require('../assets/images/pharaoh-silhouette.svg').default} width={47} absolute style={{bottom:0}} />
                        </div>
                    </PharaohImageGroup>
                </ImagesSectionMobile>

            </Wrapper>
            <BoxContainer>
                <Box>
                    <BoxRow marginRightPct={2} responsive>
                        <InnerImage src={require('../assets/images/pgold.svg').default} height={9} />
                    </BoxRow>
                    <BoxRow>
                        <div style={{position:'relative',marginTop:'0.5rem'}}>
                            <Text font='asulbold' size={20} lineHeight={1}>You can accumulate enough $PGOLD by choosing to:</Text>
                            <ul>
                                <li>
                                    <Text font='asulregular' size={20} lineHeight={1.4}>Accumulate $PGOLD and mint more Peasants/Nobles</Text>
                                </li>
                                <li>
                                    <Text font='asulregular' size={20} lineHeight={1.4}>Stake your $PGOLD in the House of Horus</Text>
                                </li>
                                <li>
                                    <Text font='asulregular' size={20} lineHeight={1.4}>Buy $PGOLD on the secondary market</Text>
                                </li>
                            </ul>
                        </div>
                    </BoxRow>

                </Box>

            </BoxContainer>

        </BoxSection>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 3rem 3rem 0 3rem;
    @media (max-width: ${props => Breakpoint.md}) {
        flex-wrap: wrap;
        padding: 3rem 1.5rem 0 1.5rem;
    }
`

const CellText = styled('div')<{marginTop?:number,w?:number}>`
    width: ${props => props.w || 50}%;
    height: 100%;
    margin-top: ${props => props.marginTop || 0}rem;
    padding-right: 1rem;
    
    @media (max-width: ${props => Breakpoint.md}) {
        margin-bottom: 2rem;       
        padding-right: 0rem;
        width: 100%;
    }
`

const SpaceBetween = styled('div')<{marginTop?:number}>`
    display: flex;
    justify-content: space-between;
    margin-top: ${props => props.marginTop || 0}rem;
`

const FlexWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 2.5rem;
    @media (max-width: ${props => Breakpoint.md}) {
        flex-direction: column;       
    }    
    font-family: asulregular;
    font-size: 20px;
`

const ImagesSectionDesktop = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    @media (max-width: ${props => Breakpoint.lg}) {
        display: none;
    }
`
const PharaohImageGroup = styled.div`
    width: 35%;
    position: relative;
    display: flex;
    justify-content: center;
    margin-left: 2%;
    @media (max-width: ${props => Breakpoint.lg}) {
        margin-left: 0;
        width: 100%;
        margin-top: 1.5rem;
    }
`
const ImageGroup = styled('div')<{widthInPct?:number}>`
    width: ${props => props.widthInPct || 100}%;
    display: flex;
    justify-content: space-between;
    @media (max-width: ${props => Breakpoint.lg}) {
        flex-direction: column;
    }
`

const Cell1 = styled('div')<{}>`
    display: flex;
    flex-direction: column;
    position: relative;
    @media (max-width: ${props => Breakpoint.lg}) {
        flex-direction: row;
        justify-content: center;
    }
`

const Cell2 = styled('div')<{}>`
    display: flex;
    flex-direction: column;
    position: relative;
    margin-left: -1rem;
    margin-right: 1rem;    
    @media (max-width: ${props => Breakpoint.lg}) {
        flex-direction: row;
    }
`

const Cell3 = styled('div')<{}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: ${props => Breakpoint.lg}) {
        flex-direction: row;
        justify-content: center;
    }
`
const Cell4 = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 1rem;
`
const Cell5 = styled('div')<{}>`
    display: flex;
    flex-direction: column;
`
const Cell6 = styled('div')<{}>`
    display: flex;
    flex-direction: column;
    // margin-left: 1rem;
    margin-left: 6rem;    
`
const Cell7 = styled('div')<{}>`
    display: flex;
    flex-direction: column;
    margin-left: 1.5rem;    
`
const Cell8 = styled('div')<{}>`
    display: flex;
    flex-direction: column;
    margin-left: 1.5rem;    
    position: relative;
`
const Cell9 = styled('div')<{}>`
    display: flex;
    flex-direction: column;
    margin-left: 1rem;    
    position: relative;
`

const Cell = styled('div')<{direction?:string,absolute?:boolean,marginLeft?:number,marginRight?:number}>`
    display: flex;
    ${props => props.direction ? `flex-direction:${props.direction}` : ''};
    position: ${props => props.absolute ? 'absolute' : 'relative'};
    margin-left: ${props => props.marginLeft || 0}rem;
    margin-right: ${props => props.marginRight || 0}rem;    
`

const InnerImage = styled('img')<{height?:number,marginTopPct?:number,marginLeftPct?:number,absolute?:boolean,rotate?:string,marginLeft?:number}>`
    height: ${props => props.height}rem;
    margin-top: ${props => props.marginTopPct}%;
    margin-left: ${props => props.marginLeftPct ? `${props.marginLeftPct}%` : props.marginLeft ? `${props.marginLeft}rem` : ''};
    position: ${props => props.absolute ? 'absolute' : 'relative'};
    ${props => props.rotate ? `transform:rotate(${props.rotate})` : ''};
`

const InnerImg = styled('img')<{width?:number,height?:number,marginTopPct?:number,marginLeftPct?:number,absolute?:boolean}>`
    height: ${props => props.height}%;
    width: ${props => props.width}%;
    margin-top: ${props => props.marginTopPct}%;
    margin-left: ${props => props.marginLeftPct}%;
    position: ${props => props.absolute ? 'absolute' : 'relative'};
`

const ImagesSectionMobile = styled.div`
    width: 100%;
    display: none;
    flex-direction: column;
    @media (max-width: ${props => Breakpoint.lg}) {
        display: flex;
    }
`

const CellMobile1 = styled.div`
    display: flex;
    flex-direction: row;
`
const Col = styled.div`
    display: flex;
    flex-direction: column;
`
const Row = styled.div`
    display: flex;
    flex-direction: row;
`

const BoxContainer = styled.div`
    width: 100%;
    padding: 0 1rem 1rem 1rem;
`
const Box = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    background-color: #FFBF23;
    width: 100%;
    box-sizing: border-box;
    border-radius: 0.7rem;
    padding: 2rem;
    @media (max-width: ${props => Breakpoint.md}) {
        padding: 1rem;
    }
    @media (max-width: ${props => Breakpoint.sm}) {
        flex-wrap: wrap;
    }
`

const BoxRow = styled('div')<{widthPct?:number,responsive?:boolean,marginRightPct?:number}>`
    width: ${props => props.widthPct ? `${props.widthPct}%` : 'auto'};
    display: flex;
    margin-right: ${props => props.marginRightPct}%;
    @media (max-width: ${props => Breakpoint.md}) {
        margin-right: ${props => props.responsive && 0 };
    }
    @media (max-width: ${props => Breakpoint.sm}) {
        width: ${props => props.responsive ? '100%' : props.widthPct ? `${props.widthPct}%` : 'auto'};
        justify-content: center;    
    }
`

const TempleWrap = styled.div`
    display: flex;
    justify-content: center;
    @media (max-width: ${props => Breakpoint.md}) {
        overflow: hidden;
    }
`