import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import store from './redux/store';
import Home from './pages/Home';
import Pyramids from "./pages/Pyramids";
import Config from "./pages/Config";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <Provider store={store}>
          <Router>
              <Routes>
                  <Route path="/" element={<Home/>}/>
                  <Route path="/pyramids" element={<Pyramids/>}/>
                  <Route path="/config" element={<Config/>}/>
              </Routes>
          </Router>
      </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
