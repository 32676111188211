import {reset, bright, white, cyan, magenta, red} from '../../ConsoleLogDisplay';
import {PyramidContract,getProvider,getWeb3} from "../Web3Utils";
import {address, ABI} from "../abi/Pyramid";
import {ethers} from "ethers";
import * as pnpAction from './PNPAction';
import Web3 from "web3";
import BN from "bn.js";
import {sendTx} from "../Web3Utils";
import {getTokenTraits} from "./PNPAction";

let web3:any;
export const setWeb3ForPyramid = (_web3:any) => {
    web3 = _web3;
}

const overseer = async (tokenId:number) => {
    // const peasantCensus = await PyramidContract().overseer(tokenId);
    // const peasantCensus = await PyramidContract().methods.overseer(tokenId).call();
    // console.log(`${bright}${cyan}Token ${tokenId} last claimed at ${white}${new Date(peasantCensus * 1000)}${cyan}.${reset}`);
    // return peasantCensus;


    const peasantCensus = await PyramidContract().methods.overseer(tokenId).call();
    console.log(`${bright}${cyan}Token ${tokenId} last claimed at ${white}${new Date(peasantCensus * 1000)}${cyan}.${reset}`);
    return peasantCensus;

}

export const getStorage = async (slot:number) => {
    return await web3.eth.getStorageAt(address, slot);
}

export const totalPeasantCensus = async () => {
    // const raw = await getProvider().getStorageAt(address, 10);
    // const result = Number(raw.toString());
    const raw = await web3.eth.getStorageAt(address, 10);
    const result = await Web3.utils.hexToNumberString(raw.toString());
    console.log(`${bright}${cyan}The total number of peasants is ${white}${result}${cyan}.${reset}`);
    return result;
}

export const dailyPGoldRate = async () => {
    const result  = await PyramidContract().methods.DAILY_PGOLD_RATE().call();
    // @ts-ignore
    // const result = new Web3.utils.toBN(raw.toString());
    console.log(`${bright}${cyan}A Peasant will produce ${white}${Web3.utils.fromWei(result)}${cyan} PGold per day.${reset}`);
    return result;
}

export const totalSovereignCensus_06102022 = async () => {
    const raw = await web3.eth.getStorageAt(address, 9);
    // const result = Number(Web3.utils.hexToNumberString(raw.toString()))
    const result = Number(raw.toString());
    console.log(`${bright}${cyan}The total sovereign scores are ${white}${result}${cyan}.${reset}`);
    return result;
}
export const totalSovereignCensus = async () => {
    const totalSovereignCensus = await web3.utils.hexToNumberString(await web3.eth.getStorageAt(address, 9));
    console.log(`${bright}${cyan}The total sovereign scores are ${white}${totalSovereignCensus}${cyan}.${reset}`);
    return totalSovereignCensus;
}


export const maxSovereign = async () => {
    // const result = await PyramidContract().MAX_SOVEREIGN();
    const result = await PyramidContract().methods.MAX_SOVEREIGN().call();
    console.log(`${bright}${cyan}The maximum Sovereign Score is ${white}${result}${cyan}.${reset}`);
    return result;
}

export const calculatePeasantsEarning___________ = async (tokenIds:any) => {
    // @ts-ignore
    const a = new Web3.utils.toBN(0);
    const b = a.divn(86400)
    // @ts-ignore
    let earnings = new Web3.utils.toBN(0);
    const earning = b.muln(5)
    earnings = earnings.add(earning)

    return earnings.toString();
}

export const calculatePeasantsEarning = async (tokenIds:any) => {
    // @ts-ignore
    const dailyPGoldRateBN = new Web3.utils.toBN(await dailyPGoldRate());
    const secondPGoldRateBN = dailyPGoldRateBN.divn(86400); // 115740740740740740
    const now = Math.round(Date.now() / 1000);
    // @ts-ignore
    let earnings:any = new Web3.utils.toBN(0);
    for (let i = 0; i < tokenIds.length; i++) {
        let _overseer = await overseer(tokenIds[i]);
        if (_overseer == 0) {
           _overseer = parseInt(await pnpAction.getMintTime(tokenIds[i])) - 60;
        }
        // @ts-ignore
        const _earning = secondPGoldRateBN.mul(new Web3.utils.toBN(now - _overseer));
        earnings = earnings.add(_earning);
    }
    earnings = earnings.toString();
    console.log(`${bright}${cyan}${white}${Web3.utils.fromWei(earnings.toString())}${cyan} PGold can be claimed as Peasant Wages from the Pyramid Contract.${reset}`);
    return earnings;
}

export const pGoldPerSovereign = async () => {
    const _pGoldPerSovereign = await PyramidContract().methods.pGoldPerSovereign().call();
    console.log(`${bright}${cyan}Each Sovereign Point can claim ${white}${ethers.utils.formatEther(_pGoldPerSovereign)}${cyan} PGold from the Pyramid Contract.${reset}`);
    return _pGoldPerSovereign;
}

export const claimManyFromTreasurer = async (wallet:string) => {
    const tokenIdsUnsorted = await pnpAction.tokensOfOwnerInCombined(wallet);
    const tokenIdsP:Array<any> = [];
    const tokenIdsN:Array<any> = [];
    let tokenIds:Array<any> = [];
    for (let i = 0; i < tokenIdsUnsorted.length; i++) {
        const traits = await getTokenTraits(tokenIdsUnsorted[i]);
        console.log(tokenIdsUnsorted[i], traits[0])
        if (traits[0]) {
            await tokenIdsP.push(tokenIdsUnsorted[i]);
        } else {
            await tokenIdsN.push(tokenIdsUnsorted[i]);
        }
    }
    tokenIds = await tokenIds.concat(tokenIdsP);
    tokenIds = await tokenIds.concat(tokenIdsN);

    console.log(`${bright}${magenta}Claiming process starting for Pyramid Contract...${reset}`);
    // prepare transaction data
    const txData = await PyramidContract().methods.claimManyFromTreasurer(tokenIds);
    // send transaction
    const result = await sendTx(txData, wallet, PyramidContract().options.address, 0);
    console.log('RESULT========',result)
    // @ts-ignore
    if (result) {
        console.log(`${bright}${magenta}Claiming process completed for Pyramid Contract.${reset}`);
    } else {
        console.log(`${red}Failed to claim, error: ${result}${reset}`);
    }
}

// the previous pGoldPerSovereign value of a noble
export const treasurer = async (tokenId:any) => {
    const nobleCensus = await PyramidContract().methods.treasurer(tokenId).call();  //contract.methods.treasurer(tokenId).call();
    console.log(`${bright}${cyan}Token ${tokenId} last claimed with value ${white}${nobleCensus}${cyan}.${reset}`);
    return nobleCensus;
}

// the pGoldPerSovereign value when a noble is added to the contract
export const pGoldLedger = async (tokenId:any) => {
    const pGoldLedgerIndex = await web3.utils.soliditySha3(tokenId, 3); // key + storage index
    const pGoldLedger = await web3.utils.hexToNumberString(await getStorage(pGoldLedgerIndex));
    console.log(`${bright}${cyan}Token ${tokenId} was added to the contract with the value ${white}${pGoldLedger}${cyan}.${reset}`);
    return pGoldLedger;
}
