import React, {useRef, useEffect} from 'react';
import styled from 'styled-components';
import {Text, TextCol, BoxSection} from '../Styles';
import {Breakpoint} from "../constants";

let isDown = false;
let offset = [0,0];
let mousePosition;

export default function Roadmap() {
    const refContainer = useRef<HTMLDivElement>(null);
    const refSlidingPanel = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!refContainer.current) {return;}
        if (!refSlidingPanel.current) {return;}
        const elContainer = refContainer.current;
        const elSlidingPanel = refSlidingPanel.current;

        /**
         * DESKTOP MOUSE EVENT
         */
        elSlidingPanel.addEventListener('mousedown', (e) => {
            e.stopPropagation();
            isDown = true
            elSlidingPanel.style.cursor = 'grabbing';
            offset = [
                elSlidingPanel.offsetLeft - e.clientX,
                elSlidingPanel.offsetTop - e.clientY
            ];
        })

        elSlidingPanel.addEventListener('mousemove', (e) => {
            e.stopPropagation();
            if (!isDown) {
                return false;
            }

            elSlidingPanel.style.cursor = 'grabbing';

            mousePosition = {x: e.clientX,y: e.clientY}
            let change = (mousePosition.x + offset[0]);
            const threshold = Math.abs(change) + elContainer.offsetWidth;
            if (change > 0) {
                return;
            }
            if (threshold > elSlidingPanel.offsetWidth) {
                return;
            }
            elSlidingPanel.style.left = change + 'px';
        })

        elSlidingPanel.addEventListener('mouseup', (e) => {
            e.stopPropagation();
            isDown = false;
            elSlidingPanel.style.cursor = 'grab';
        })

        elSlidingPanel.addEventListener('mouseleave', (e) => {
            e.stopPropagation();
            if (!isDown) {
                return false;
            }
            elSlidingPanel.style.cursor = 'grab';
            isDown = false;
        })
        elContainer.addEventListener('wheel', (e) => {
            e.preventDefault();
            elContainer.scrollLeft += e.deltaY;
        })


        /**
         * MOBILE MOUSE EVENT
         */
        elSlidingPanel.addEventListener('touchstart', (e) => {
            e.stopPropagation();
            isDown = true
            offset = [
                elSlidingPanel.offsetLeft - e.touches[0].clientX,
                elSlidingPanel.offsetTop - e.touches[0].clientY
            ];
        })

        elSlidingPanel.addEventListener('touchmove', (e) => {
            e.stopPropagation();
            if (!isDown) {
                return false;
            }
            mousePosition = {x: e.touches[0].clientX,y: e.touches[0].clientY}
            let change = (mousePosition.x + offset[0]);
            const threshold = Math.abs(change) + elContainer.offsetWidth;
            if (change > 0) {
                return;
            }
            if (threshold > elSlidingPanel.offsetWidth) {
                return;
            }
            elSlidingPanel.style.left = change + 'px';
        })

        elSlidingPanel.addEventListener('touchend', (e) => {
            e.stopPropagation();
            isDown = false;
        })

    }, []);


    return (
        <BoxSection marginTop={2} id="roadmap">
            <BackgroundWrap>
                <Wrapper>
                    <div style={{position:'relative',textAlign:'center', marginTop:'3rem', marginBottom:'4rem'}}>
                        <Text font='asulbold' weight={900} size={28}>Roadmap</Text>
                    </div>
                    <SubHeader>
                        <Text font='asulbold' weight={900} size={28} color={'#943112'}>Chapter 1</Text>
                    </SubHeader>
                    <SlidingContainer ref={refContainer}>
                        <SlidingPanel ref={refSlidingPanel}>
                            <div style={{position:'relative'}}>
                                <Line style={{marginLeft:'3rem', position:'absolute',marginTop:'2.2rem'}}/>
                                <div style={{display:'flex',flexDirection:'row'}}>
                                    <Panel marginLeft={3}>
                                        <Row>
                                            {/*<div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>*/}
                                            <ImageRound src={require('../assets/images/roadmap-circle-1.svg').default} borderColor={'#943112'} />
                                            {/*</div>*/}
                                        </Row>
                                        <Row>
                                            <div>
                                                <Box>
                                                    <Text font={'asulbold'} fontSize={1.2} color={'#943112'}>Phase 1.1</Text>
                                                    <Text font={'asulbold'} fontSize={1.2} lineHeight={1.1} color={'#943112'}>Rise of the Pyramids</Text>
                                                    <Text font={'asulregular'} fontSize={1} color={'#943112'} style={{marginTop:'0.3rem'}}>(July 2022)</Text>
                                                    <div>

                                                    </div>
                                                    <ul style={{listStyleType: 'square', marginLeft:'-7%'}}>
                                                        <li><Text font={'asulregular'} fontSize={0.92} lineHeight={1.2}>Peasants and Nobles mint</Text></li>
                                                        <li><Text font={'asulregular'} fontSize={0.92} lineHeight={1.2}>$PGOLD accumulation</Text></li>
                                                        <li><Text font={'asulregular'} fontSize={0.92} lineHeight={1.2}>Liquidity pool for $PGOLD</Text></li>
                                                    </ul>
                                                </Box>
                                            </div>
                                            <div>
                                                <Box>
                                                    <Text font={'asulbold'} fontSize={1.2} color={'#943112'}>Phase 1.2</Text>
                                                    <Text font={'asulbold'} fontSize={1.2} lineHeight={1.1} color={'#943112'}>House of Horus</Text>
                                                    <Text font={'asulregular'} fontSize={1} color={'#943112'} style={{marginTop:'0.3rem'}}>(Q4 2022)</Text>
                                                    <div>

                                                    </div>
                                                    <ul style={{listStyleType: 'square', marginLeft:'-7%'}}>
                                                        <li><Text font={'asulregular'} fontSize={0.92} lineHeight={1.2}>Staking and Bonding of $PGOLD</Text></li>
                                                    </ul>
                                                </Box>

                                            </div>
                                        </Row>
                                    </Panel>
                                    <Panel marginLeft={0.3}>
                                        <Row>
                                            <ImageRound src={require('../assets/images/roadmap-circle-2.svg').default} borderColor={'#414168'} />
                                        </Row>
                                        <Row>
                                            <div>
                                                <Box>
                                                    <Text font={'asulbold'} fontSize={1.2} color={'#414168'}>Phase 2.1</Text>
                                                    <Text font={'asulbold'} fontSize={1.2} lineHeight={1.1} color={'#414168'}>Monuments</Text>
                                                    <Text font={'asulregular'} fontSize={1} color={'#414168'} style={{marginTop:'0.3rem'}}>(Q1 2023)</Text>
                                                    <ul style={{listStyleType: 'square', marginLeft:'-7%'}}>
                                                        <li><Text font={'asulregular'} fontSize={0.92} lineHeight={1.2}>Introduction of monuments</Text></li>
                                                        <li><Text font={'asulregular'} fontSize={0.92} lineHeight={1.2}>Liquidity pool for $PWATER, $PMEAT, $PGRAIN</Text></li>
                                                        <li><Text font={'asulregular'} fontSize={0.92} lineHeight={1.2}>Lending and borrowing for $PWATER, $PMEAT, $PGRAIN</Text></li>
                                                    </ul>
                                                </Box>
                                            </div>
                                            <div>
                                                <Box>
                                                    <Text font={'asulbold'} fontSize={1.2} color={'#414168'}>Phase 2.2</Text>
                                                    <Text font={'asulbold'} fontSize={1.2} lineHeight={1.1} color={'#414168'}>Ascension Ritual</Text>
                                                    <Text font={'asulregular'} fontSize={1} color={'#414168'} style={{marginTop:'0.3rem'}}>(Late Q1 2023)</Text>
                                                    <ul style={{listStyleType: 'square', marginLeft:'-7%'}}>
                                                        <li><Text font={'asulregular'} fontSize={0.92} lineHeight={1.2}>Pharaohs mint (using monument tokens)</Text></li>
                                                        <li><Text font={'asulregular'} fontSize={0.92} lineHeight={1.2}>Revelation events</Text></li>
                                                    </ul>
                                                </Box>
                                            </div>
                                        </Row>
                                    </Panel>
                                </div>
                            </div>
                            <Panel marginLeft={2} marginRight={3}>
                                <Row>
                                    <ImageRound src={require('../assets/images/roadmap-circle-3.svg').default} borderColor={'#681F7B'}/>
                                </Row>
                                <Row>
                                    <div>
                                        <Box>
                                            <Text font={'asulbold'} fontSize={1.2} color={'#681F7B'}>Beyond Chapter 1</Text>
                                            <Text font={'asulbold'} fontSize={1.2} lineHeight={1.1} color={'#681F7B'}>Utility Expansion</Text>
                                            <Text font={'asulregular'} fontSize={1} color={'#681F7B'} style={{marginTop:'0.3rem'}}>(Q2 2023)</Text>
                                            <ul style={{listStyleType: 'square', marginLeft:'-7%'}}>
                                                <li><Text font={'asulregular'} fontSize={0.92} lineHeight={1.2}>Mobile & Web App</Text></li>
                                                <li><Text font={'asulregular'} fontSize={0.92} lineHeight={1.2}>Pharaoh's Exclusive Club</Text></li>
                                                <li><Text font={'asulregular'} fontSize={0.92} lineHeight={1.2}>PNP world expansion</Text></li>
                                                <ul style={{marginLeft:'-7%'}}>
                                                    <li><Text font={'asulregular'} fontSize={0.92} lineHeight={1.2}>New races, storylines, and assets</Text></li>
                                                    <li><Text font={'asulregular'} fontSize={0.92} lineHeight={1.2}>Integration with other projects</Text></li>
                                                </ul>
                                                <li><Text font={'asulregular'} fontSize={0.92} lineHeight={1.2}>Technical additions</Text></li>
                                                <ul style={{marginLeft:'-7%'}}>
                                                    <li><Text font={'asulregular'} fontSize={0.92} lineHeight={1.2}>PNP automated liquidity protocol</Text></li>
                                                    <li><Text font={'asulregular'} fontSize={0.92} lineHeight={1.2}>Cross-chain bridge</Text></li>
                                                </ul>
                                            </ul>
                                        </Box>
                                    </div>
                                </Row>
                            </Panel>
                        </SlidingPanel>
                    </SlidingContainer>

                    <ContainerForMobile>
                        <Panel style={{marginTop:'2rem'}}>
                            <Row>
                                <ImageRound src={require('../assets/images/roadmap-circle-1.svg').default} borderColor={'#943112'} />
                            </Row>
                            <Row responsive>
                                <Box>
                                    <Text font={'asulbold'} fontSize={1.2} color={'#943112'}>Phase 1.1</Text>
                                    <Text font={'asulbold'} fontSize={1.2} lineHeight={1.1} color={'#943112'}>Rise of the Pyramids</Text>
                                    <Text font={'asulregular'} fontSize={1} color={'#943112'} style={{marginTop:'0.3rem'}}>(May 2022)</Text>
                                    <ul style={{listStyleType: 'square', marginLeft:'-7%'}}>
                                        <li><Text font={'asulregular'} fontSize={0.92} lineHeight={1.2}>Peasants and Nobles mint</Text></li>
                                        <li><Text font={'asulregular'} fontSize={0.92} lineHeight={1.2}>$PGOLD accumulation</Text></li>
                                        <li><Text font={'asulregular'} fontSize={0.92} lineHeight={1.2}>Liquidity pool for $PGOLD</Text></li>
                                    </ul>
                                </Box>
                                <VerticalLineHolder h={1.2} marginLeft={2.5}>
                                    <VerticalLines tag={'vline-1'}/>
                                </VerticalLineHolder>
                                <Box>
                                    <Text font={'asulbold'} fontSize={1.2} color={'#943112'}>Phase 1.2</Text>
                                    <Text font={'asulbold'} fontSize={1.2} lineHeight={1.1} color={'#943112'}>House of Horus</Text>
                                    <Text font={'asulregular'} fontSize={1} color={'#943112'} style={{marginTop:'0.3rem'}}>(Q3 2022)</Text>
                                    <ul style={{listStyleType: 'square', marginLeft:'-7%'}}>
                                        <li><Text font={'asulregular'} fontSize={0.92} lineHeight={1.2}>Staking and Bonding of $PGOLD</Text></li>
                                    </ul>
                                </Box>
                            </Row>
                        </Panel>
                        <VerticalLineHolder h={2.2} marginLeft={3.8}>
                            <VerticalLines tag={'vline-2'}/>
                        </VerticalLineHolder>
                        <Panel>
                            <Row>
                                <ImageRound src={require('../assets/images/roadmap-circle-2.svg').default} borderColor={'#414168'} />
                            </Row>
                            <Row responsive>
                                <Box>
                                    <Text font={'asulbold'} fontSize={1.2} color={'#414168'}>Phase 2.1</Text>
                                    <Text font={'asulbold'} fontSize={1.2} lineHeight={1.1} color={'#414168'}>Monuments</Text>
                                    <Text font={'asulregular'} fontSize={1} color={'#414168'} style={{marginTop:'0.3rem'}}>(Q3 2022)</Text>
                                    <ul style={{listStyleType: 'square', marginLeft:'-7%'}}>
                                        <li><Text font={'asulregular'} fontSize={0.92} lineHeight={1.2}>Introduction of monuments</Text></li>
                                        <li><Text font={'asulregular'} fontSize={0.92} lineHeight={1.2}>Liquidity pool for $PWATER, $PMEAT, $PGRAIN</Text></li>
                                        <li><Text font={'asulregular'} fontSize={0.92} lineHeight={1.2}>Lending and borrowing for $PWATER, $PMEAT, $PGRAIN</Text></li>
                                    </ul>
                                </Box>
                                <VerticalLineHolder h={1.2} marginLeft={2.5}>
                                    <VerticalLines tag={'vline-3'}/>
                                </VerticalLineHolder>
                                <Box>
                                    <Text font={'asulbold'} fontSize={1.2} color={'#414168'}>Phase 2.2</Text>
                                    <Text font={'asulbold'} fontSize={1.2} lineHeight={1.1} color={'#414168'}>Ascension Ritual</Text>
                                    <Text font={'asulregular'} fontSize={1} color={'#414168'} style={{marginTop:'0.3rem'}}>(Late Q3 2022)</Text>
                                    <ul style={{listStyleType: 'square', marginLeft:'-7%'}}>
                                        <li><Text font={'asulregular'} fontSize={0.92} lineHeight={1.2}>Pharaohs mint (using monument tokens)</Text></li>
                                        <li><Text font={'asulregular'} fontSize={0.92} lineHeight={1.2}>Revelation events</Text></li>
                                    </ul>
                                </Box>
                            </Row>
                        </Panel>
                        <VerticalLineHolder h={5} marginLeft={3.8}>
                            <VerticalLines tag={'vline-4'}/>
                        </VerticalLineHolder>
                        <Panel>
                            <Row>
                                <ImageRound src={require('../assets/images/roadmap-circle-3.svg').default} borderColor={'#681F7B'}/>
                            </Row>
                            <Row responsive>
                                <Box>
                                    <Text font={'asulbold'} fontSize={1.2} color={'#681F7B'}>Beyond Chapter 1</Text>
                                    <Text font={'asulbold'} fontSize={1.2} lineHeight={1.1} color={'#681F7B'}>Utility Expansion</Text>
                                    <Text font={'asulregular'} fontSize={1} color={'#681F7B'} style={{marginTop:'0.3rem'}}>(Q4 2022)</Text>
                                    <ul style={{listStyleType: 'square', marginLeft:'-7%'}}>
                                        <li><Text font={'asulregular'} fontSize={0.92} lineHeight={1.2}>Mobile & Web App</Text></li>
                                        <li><Text font={'asulregular'} fontSize={0.92} lineHeight={1.2}>Pharaoh's Exclusive Club</Text></li>
                                        <li><Text font={'asulregular'} fontSize={0.92} lineHeight={1.2}>PNP world expansion</Text></li>
                                        <ul style={{marginLeft:'-7%'}}>
                                            <li><Text font={'asulregular'} fontSize={0.92} lineHeight={1.2}>New races, storylines, and assets</Text></li>
                                            <li><Text font={'asulregular'} fontSize={0.92} lineHeight={1.2}>Integration with other projects</Text></li>
                                        </ul>
                                        <li><Text font={'asulregular'} fontSize={0.92} lineHeight={1.2}>Technical additions</Text></li>
                                        <ul style={{marginLeft:'-7%'}}>
                                            <li><Text font={'asulregular'} fontSize={0.92} lineHeight={1.2}>PNP automated liquidity protocol</Text></li>
                                            <li><Text font={'asulregular'} fontSize={0.92} lineHeight={1.2}>Cross-chain bridge</Text></li>
                                        </ul>
                                    </ul>
                                </Box>
                            </Row>
                        </Panel>
                    </ContainerForMobile>
                </Wrapper>

                <BackgroundInner>
                    <div style={{position:'relative',display:'flex', flexDirection:'row',alignItems:'flex-end',marginTop:'-18rem',paddingLeft:'2rem'}}>
                        <img src={require('../assets/images/peasant.svg').default} style={{position:'absolute',width:'3.5rem',marginBottom:'1.7rem',marginLeft:'3vw'}}/>
                        <img src={require('../assets/images/temple.svg').default} style={{width:'46rem'}}/>
                        <img src={require('../assets/images/roadmap-pharaoh-silhouette.svg').default} style={{width:'17rem',marginLeft:'13vw'}} />
                    </div>
                    {/*<img src={require('../assets/images/temple.svg').default} style={{height:'22.5rem', marginTop:'-16.8rem', marginLeft:'2rem'}}/>*/}
                    {/*<img src={require('../assets/images/roadmap-pharaoh-silhouette.svg').default} style={{height:'21rem',marginTop:'-16rem',marginLeft:'15rem'}} />*/}
                    <img src={require('../assets/images/bar-bottom.png').default} style={{position:'relative',width:'100%', marginTop:'-1.5rem'}}  />
                </BackgroundInner>




            </BackgroundWrap>
        </BoxSection>
    );
}


const Wrapper = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
`
const SubHeader = styled.div`
    position: relative;
    padding-left: 3rem;
    width: 100%;
    display: flex;
    @media (max-width: ${props => Breakpoint.md}) {
        padding-right: 1.5rem;
        justify-content: flex-end;
    }
`
const SlidingContainer = styled.div`
    width: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    margin-top: 1.5rem;
    @media (max-width: ${props => Breakpoint.md}) {
        display: none;
    }
`
const SlidingPanel = styled.div`
    position: relative;
    flex-direction: row;
    display: flex;
    margin-bottom: 1rem;
    cursor: -webkit-grab;
    cursor: grab;
`

const Panel = styled('div')<{marginLeft?:number,marginRight?:number}>`
    display: flex;
    flex-direction: column;
    margin-left: ${props => props.marginLeft}rem;
    margin-right: ${props => props.marginRight}rem;
    @media (max-width: ${props => Breakpoint.md}) {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        // margin-top: 2rem; ----------------------------------------------------
    }
    z-index: 100;
`

const Row = styled('div')<{responsive?:boolean}>`
    display: flex;
    flex-direction: row;
    @media (max-width: ${props => Breakpoint.md}) {
        flex-direction: ${props => props.responsive ? 'column' : 'row'};
    }
`

const ImageRound = styled('div')<{src?:string,borderColor?:string}>`
    width: 4.8rem;
    height: 4.8rem;
    min-width: 4.8rem;
    min-height: 4.8rem;
    border-radius: 50%;
    border: 0.24rem solid ${props => props.borderColor};
    background-color: #fff;
    z-index: 102;
    margin-bottom: -0.7rem;
    &:after {
        content: '';
        width: 100%;
        height: 100%;
        display: inline-block;
        background-image: url(${props => props.src});
        background-repeat: no-repeat;
        background-size: 80%;
        background-position: center;
    }
    @media (max-width: ${props => Breakpoint.md}) {
        margin-bottom: -0.6rem;
    }
`

const Box = styled('div')<{}>`
    display: flex;
    flex-direction: column;
    width 17.5rem;
    background-color: #FFECBD;
    border: 0.15rem solid #3A2206;
    border-radius: 1rem;
    box-shadow: 0rem 0.8rem #7C4D1D;
    z-index: 101;
    padding: 1.5rem;
    margin-right: 0.4rem;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently */

    @media (max-width: ${props => Breakpoint.md}) {
        width: 100%;
        // margin-top: 1.1rem; --------------------------------------------------        
    }
`
const Line = styled.div`
    width: 100%;
    height: 0.16rem;
    border-top: 0.16rem dashed #B08048;
`

const ContainerForMobile = styled.div`
    width: 100%;
    margin-top: -5rem;
    display: none;
    flex-direction: column;
    @media (max-width: ${props => Breakpoint.md}) {
        display: flex;
        margin-bottom: 2rem;
        
    }
`

const BackgroundWrap = styled.div`
    width: 100%;
    position: relative;
`

const BackgroundInner = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    @media (max-width: ${props => Breakpoint.md}) {
        display: none;
    }
`

const VerticalLines = (props:any) => {
    return (
        <VerticalLineContainer>
            {
                [...new Array(10)].map((i, idx) => {
                    return <VLine key={props.tag + '-' + idx}/>
                })
            }
        </VerticalLineContainer>
    )
}
const VerticalLineContainer = styled.div`
    display: flex;
    flex-direction: column;
`
const VLine = styled.div`
    width: 0.13rem;
    height: 0.35rem;
    background-color: #B08048;
    margin-bottom: 0.2rem;
`

const VerticalLineHolder = styled('div')<{h?:number,marginLeft?:number}>`
    width: 0.14rem;
    height: ${props => props.h}rem;
    overflow: hidden;
    z-index: 99;
    margin-left: ${props => props.marginLeft}rem;
`