import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Button, ButtonInner, Text} from "../../Styles";
import {Breakpoint} from "../../constants";
import gsap from 'gsap';
import * as pnpAction from "../../redux/blockchain/actions/PNPAction";
import * as whitelistAction from "../../redux/blockchain/actions/WhitelistAction";
import {useDispatch, useSelector} from "react-redux";
import {setDappLocked} from '../../redux/blockchain/reducers/common';
import {addPeasant, addNoble, updateNoble, updatePeasant, setTotalMinted} from '../../redux/blockchain/reducers/pnpReducer';
import {red, reset} from "../../redux/ConsoleLogDisplay";

const maxCounterQty = 10;
let startGeneratingArtTimeout:any = null;

export default function Minting(props:any) {
    const dispatch = useDispatch();
    // @ts-ignore
    const { chainId, account, allowedNetworks, web3 } = useSelector((state) => state.profile);
    // @ts-ignore
    const { dappLocked } = useSelector((state) => state.common);
    // @ts-ignore
    const {peasants, nobles, totalMinted} = useSelector((state) => state.pnp);

    // const [totalSupply, setTotalSupply] = useState(50000);
    // const [price, setPrice] = useState(0.0069);
    const [qty, setQty] = useState(1);
    const [total, setTotal] = useState(0);
    const refMintingThermometer = useRef<HTMLDivElement>(null);
    const refThermoCell = useRef<(HTMLDivElement | null)[]>([]);
    const refThermoGauge = useRef<(HTMLDivElement | null)[]>([]);
    const refCounterInputScrolling = useRef<HTMLDivElement>(null);
    const refCounterInputScrollingMobile = useRef<HTMLDivElement>(null);
    const refCounterInputs = useRef<(HTMLSpanElement | null)[]>([]);
    const refCounterInputsMobile = useRef<(HTMLSpanElement | null)[]>([]);
    const refButtonMinus = useRef(null);
    const refButtonPlus = useRef(null);

    // const [totalMinted, setTotalMinted] = useState(0); //43940
    const [maxTokens, setMaxTokens] = useState(0);
    const [mintPrice, setMintPrice] = useState(0);
    const [paidTokens, setPaidTokens] = useState(0);
    const [loading, setLoading] = useState(false);
    const [numPerTrans, setNumPerTrans] = useState(10);

    async function startGeneratingArt() {
        if (account) {
            const tokenIds = [];

            const [peasantIds, nobleIds] = await pnpAction.getPNP(account);

            for (let i=0; i<peasantIds.length; i++) {
                const exists = await peasants.filter((o:any) => parseInt(o.tokenId) === parseInt(peasantIds[i]))[0];
                if (!exists) {
                    console.log('New Peasant TokenId', peasantIds[i])
                    tokenIds.push(peasantIds[i]);
                }
            }

            for (let i=0; i<nobleIds.length; i++) {
                const exists = await nobles.filter((o:any) => parseInt(o.tokenId) === parseInt(nobleIds[i]))[0];
                if (!exists) {
                    console.log('New Noble TokenId', nobleIds[i])
                    tokenIds.push(nobleIds[i]);
                }
            }

            const data:any = await pnpAction.generateArt(tokenIds);
            for (let i = 0; i < data.length; i++) {
                const nft = data[i];
                if (nft.isPeasant) {
                    const img = new Image();
                    img.src = nft.imageUrl;
                    dispatch(addPeasant({tokenId: nft.tokenId, image: img.src, imageUrl: nft.imageUrl}));
                } else {
                    const img = new Image();
                    img.src = nft.imageUrl;
                    dispatch(addNoble({tokenId: nft.tokenId, image: img.src, imageUrl: nft.imageUrl}));
                }
            }


        }
    }

    function handleAdjustQtyClick(mode:number) {
        try {
            const elInputScrolling = refCounterInputScrolling.current || refCounterInputScrollingMobile.current;
            const elCounterInputs = refCounterInputs.current || refCounterInputsMobile.current;
            if (!elInputScrolling) {return;}
            if (!elCounterInputs) {return;}

            if (mode == 0) {
                if (qty > 1) {
                    // @ts-ignore
                    gsap.to(elInputScrolling, {top:`${-elCounterInputs[qty - 1].offsetTop}px`})
                    setTotal((qty - 1) * mintPrice);
                    setQty(qty - 1);

                    if ((qty - 1) <= 1) {
                        // @ts-ignore
                        refButtonMinus.current.style.filter = 'grayscale(1)'; refButtonMinus.current.style.cursor = 'not-allowed';
                    } else {
                        // @ts-ignore
                        refButtonMinus.current.style.filter = 'grayscale(0)'; refButtonMinus.current.style.cursor = 'pointer';
                    }
                } else {
                    // @ts-ignore
                    refButtonMinus.current.style.filter = 'grayscale(1)'; refButtonMinus.current.style.cursor = 'not-allowed';
                }

                // @ts-ignore
                refButtonPlus.current.style.filter = 'grayscale(0)'; refButtonPlus.current.style.cursor = 'pointer';
            }

            if (mode == 1) {
                if (qty < numPerTrans) {
                    // @ts-ignore
                    gsap.to(elInputScrolling, {top:`${-elCounterInputs[qty + 1].offsetTop}px`})
                    setTotal((qty + 1) * mintPrice);
                    setQty(qty + 1);

                    if ((qty + 1) >= numPerTrans) {
                        // @ts-ignore
                        refButtonPlus.current.style.filter = 'grayscale(1)'; refButtonPlus.current.style.cursor = 'not-allowed';
                    } else {
                        // @ts-ignore
                        refButtonPlus.current.style.filter = 'grayscale(0)';  refButtonPlus.current.style.cursor = 'pointer';
                    }
                } else {
                    // @ts-ignore
                    refButtonPlus.current.style.filter = 'grayscale(1)';  refButtonPlus.current.style.cursor = 'not-allowed';
                }

                // @ts-ignore
                refButtonMinus.current.style.filter = 'grayscale(0)'; refButtonMinus.current.style.cursor = 'pointer';
            }
        } catch (e) {

        }
    }

    function handleAdjustQtyClickMobile(mode:number) {
        try {
            const elInputScrolling = refCounterInputScrollingMobile.current;
            const elCounterInputs = refCounterInputsMobile.current;
            if (!elInputScrolling) {return;}
            if (!elCounterInputs) {return;}

            if (mode == 0) {
                if (qty > 1) {
                    // @ts-ignore
                    gsap.to(elInputScrolling, {top:`${-elCounterInputs[qty - 1].offsetTop}px`})
                    setTotal((qty - 1) * mintPrice);
                    setQty(qty - 1);
                }
            }

            if (mode == 1) {
                if (qty < numPerTrans) {
                    // @ts-ignore
                    gsap.to(elInputScrolling, {top:`${-elCounterInputs[qty + 1].offsetTop}px`})
                    setTotal((qty + 1) * mintPrice);
                    setQty(qty + 1);
                }
            }
        } catch (e) {

        }
    }

    async function updateValues() {
        const _totalSupply = await pnpAction.totalSupply();
        dispatch(setTotalMinted(_totalSupply));
    }

    async function handleMintClick() {
        try {
            if (chainId > 0 && allowedNetworks.includes(chainId)) {
                if (account) {
                    dispatch(setDappLocked(true));
                    setLoading(true);

                    const currentPhase = await pnpAction.currentPhase();
                    dispatch(setTotalMinted(currentPhase.totalSupply));
                    setNumPerTrans(currentPhase.numberPerTransaction);
                    switch (currentPhase.phase) {
                        case "whitelist":
                            const wlEntitlement = await whitelistAction.entitlement(account);
                            if (wlEntitlement === 0) {
                                dispatch(setDappLocked(false));
                                setLoading(false);
                                if (props.mintingStatus) {
                                    props.mintingStatus(3);
                                }
                            } else if (wlEntitlement === 1) {
                                await whitelistAction.claimWhitelist(account);
                                dispatch(setDappLocked(false));
                                setLoading(false);
                                if (props.mintingStatus) {
                                    props.mintingStatus(1);
                                }
                                await startGeneratingArt();
                                await updateValues();
                            } else {
                                dispatch(setDappLocked(false));
                                setLoading(false);
                                if (props.mintingStatus) {
                                    props.mintingStatus(4);
                                }
                            }
                            return;

                        case "public_paid":
                            await pnpAction.mint(account, qty, mintPrice * qty);
                            dispatch(setDappLocked(false));
                            setLoading(false);
                            if (props.mintingStatus) {
                                props.mintingStatus(1);
                            }
                            await startGeneratingArt();
                            await updateValues();
                            return;

                        case "public_free":
                            const isClaimed = await pnpAction.freeMintClaimed(account);
                            if (isClaimed) {
                                if (props.mintingStatus) {
                                    props.mintingStatus(4);
                                }
                            } else {
                                await pnpAction.mint(account, qty, mintPrice * qty);
                                dispatch(setDappLocked(false));
                                setLoading(false);
                                if (props.mintingStatus) {
                                    props.mintingStatus(1);
                                }
                                await startGeneratingArt();
                                await updateValues();
                            }
                            return;

                        default:
                            await pnpAction.mint(account, qty, mintPrice * qty);
                            dispatch(setDappLocked(false));
                            setLoading(false);
                            if (props.mintingStatus) {
                                props.mintingStatus(1);
                            }
                            await startGeneratingArt();
                            await updateValues();
                            return;
                    }
                }
            }
        } catch (e) {
            // @ts-ignore
            console.log(e.message)
            // @ts-ignore
            if (e.message) {
                // @ts-ignore
                if (e.message.toLowerCase().includes('transaction was not mined')) {
                    if (props.mintingStatus) {
                        props.mintingStatus(2);
                    }
                }
            }

            dispatch(setDappLocked(false));
            setLoading(false);
            if (props.mintingStatus) {
                props.mintingStatus(0);
            }
        }
    }

    function updateThermo(_mintedTokens:number) {
        const thermoTokens = (totalMinted || _mintedTokens);

        if (refThermoGauge.current) {
            // @ts-ignore
            if (thermoTokens >= 10000) {
                // @ts-ignore
                if (!refThermoGauge.current[0]){return;}
                refThermoGauge.current[0].style.width = '100%';
                refThermoGauge.current[0].style.borderTopLeftRadius= '1rem';
                refThermoGauge.current[0].style.borderBottomLeftRadius= '1rem';
                if (thermoTokens >= 20000) {
                    // @ts-ignore
                    refThermoGauge.current[1].style.width = '100%';
                    if (thermoTokens >= 40000) {
                        // @ts-ignore
                        refThermoGauge.current[2].style.width = '100%';
                        if (thermoTokens >= 50000) {
                            // @ts-ignore
                            if (!refThermoGauge.current[3]){return;}
                            refThermoGauge.current[3].style.width = '100%';
                            refThermoGauge.current[3].style.borderTopRightRadius= '1rem';
                            refThermoGauge.current[3].style.borderBottomRightRadius= '1rem';
                        } else {

                            const pct = ((thermoTokens - 40000) / 10000) * 100;
                            // @ts-ignore
                            const progress = refThermoCell.current[3]?.offsetWidth * (pct / 100);
                            // @ts-ignore
                            refThermoGauge.current[3].style.width = progress + 'px';

                        }
                    } else {

                        const pct = ((thermoTokens - 20000) / 20000) * 100;
                        // @ts-ignore
                        const progress = refThermoCell.current[2]?.offsetWidth * (pct / 100);
                        // @ts-ignore
                        if (!refThermoGauge.current[2]){return;}
                        refThermoGauge.current[2].style.width = progress + 'px';

                    }
                } else {

                    const pct = ((thermoTokens - 10000) / 10000) * 100;
                    // @ts-ignore
                    const progress = refThermoCell.current[1]?.offsetWidth * (pct / 100);
                    // @ts-ignore
                    if (!refThermoGauge.current[1]){return;}
                    refThermoGauge.current[1].style.width = progress + 'px';

                }
            } else {

                const pct = (  ((thermoTokens > 0 && thermoTokens < 100) ? (thermoTokens + 100) : thermoTokens) / 10000) * 100;
                // @ts-ignore
                const progress = refThermoCell.current[0]?.offsetWidth * (pct / 100);
                if (!refThermoGauge.current[0]){return;}
                refThermoGauge.current[0].style.width = progress + 'px';
                // refThermoGauge.current[0].style.borderTopLeftRadius= '1rem';
                // refThermoGauge.current[0].style.borderBottomLeftRadius= '1rem';

            }
        }

    }

    function disablePlusMinusButtons() {
        if (refButtonMinus.current && refButtonPlus.current) {
            // @ts-ignore
            refButtonMinus.current.style.filter = 'grayscale(1)';refButtonMinus.current.style.pointerEvents = 'none';
            // @ts-ignore
            refButtonPlus.current.style.filter = 'grayscale(1)';refButtonPlus.current.style.pointerEvents = 'none';
        }
    }

    useEffect(() => {
        (async () => {

            try {
                if (chainId > 0 && allowedNetworks.includes(chainId)) {
                    if (account) {
                        const _maxTokens = await pnpAction.maxTokens();
                        setMaxTokens(_maxTokens)

                        const _totalSupply = await pnpAction.totalSupply();
                        dispatch(setTotalMinted(_totalSupply));

                        updateThermo(_totalSupply);

                        // const _paidTokens = await pnpAction.paidTokens();
                        // setPaidTokens(_paidTokens);

                        /**
                         * update the number of mints per transaction
                         * based on the following phases.
                         * */

                        const currentPhase = await pnpAction.currentPhase();
                        dispatch(setTotalMinted(currentPhase.totalSupply));
                        setNumPerTrans(currentPhase.numberPerTransaction);
                        if (currentPhase.phase === "whitelist") {
                            setMintPrice(0);
                            setTotal(0);
                            setNumPerTrans(1);
                            disablePlusMinusButtons();
                        } else if (currentPhase.phase === "public_paid") {
                            const _mintPrice = await pnpAction.mintPrice();
                            setMintPrice(_mintPrice);
                            setTotal(qty * _mintPrice);
                            setNumPerTrans(3);
                        } else if (currentPhase.phase === "public_free") {
                            setMintPrice(0);
                            setTotal(0);
                            setNumPerTrans(1);
                            disablePlusMinusButtons();
                        } else {
                            const _mintPrice = await pnpAction.mintPrice();
                            setMintPrice(_mintPrice);
                            setTotal(qty * _mintPrice);
                            setNumPerTrans(10);
                        }
                    }
                }

            } catch (e) {
                console.log(e)
            }
        })();
    }, [chainId,account])

    useEffect(() => {
        return () => {
            if (startGeneratingArtTimeout) {
                clearTimeout(startGeneratingArtTimeout);
            }
        };
    }, [])

    return (
        <Container>
            <MintingBox>
                <MintingHeader>
                    <MintingHeaderTextWrap>
                        <Text font={'asulregular'}>Minting</Text>
                    </MintingHeaderTextWrap>
                    <MintingHeaderTextWrap>
                        <div style={{display:'flex',flexDirection:'row'}}>
                            <Text>{totalMinted}</Text>
                            <Text>&nbsp;/&nbsp;</Text>
                            <Text>{maxTokens}</Text>
                            <Text>&nbsp;minted</Text>
                        </div>
                    </MintingHeaderTextWrap>
                </MintingHeader>
                <MintingThermoWrap>
                    <MintingThermoNumbers>
                        <ThermoCell rightBorder ref={el => refThermoCell.current[0] = el}>
                            <ThermoGauge ref={el => refThermoGauge.current[0] = el}/>
                            <ThermoCellTexts>
                                <Gen>
                                    <Text fontSize={1} lineHeight={1.4}>1 to 10,000&nbsp;</Text>
                                    <Text fontSize={1} lineHeight={1.4}>(Gen 0)</Text>
                                </Gen>
                                <Text font={'asulbold'} fontSize={1.3} lineHeight={1.4}>ETH</Text>
                            </ThermoCellTexts>
                        </ThermoCell>
                        <ThermoCell rightBorder ref={el => refThermoCell.current[1] = el}>
                            <ThermoGauge ref={el => refThermoGauge.current[1] = el}/>
                            <ThermoCellTexts>
                                <Gen>
                                    <Text fontSize={1} lineHeight={1.4}>10,001 to 20,000&nbsp;</Text>
                                    <Text fontSize={1} lineHeight={1.4}>(Gen 1)</Text>
                                </Gen>
                                <Text font={'asulbold'} fontSize={1.3} lineHeight={1.4}>20,000 $PGOLD</Text>
                            </ThermoCellTexts>
                        </ThermoCell>
                        <ThermoCell rightBorder ref={el => refThermoCell.current[2] = el}>
                            <ThermoGauge ref={el => refThermoGauge.current[2] = el}/>
                            <ThermoCellTexts>
                                <Gen>
                                    <Text fontSize={1} lineHeight={1.4}>20,001 to 40,000&nbsp;</Text>
                                    <Text fontSize={1} lineHeight={1.4}>(Gen 2)</Text>
                                </Gen>
                                <Text font={'asulbold'} fontSize={1.3} lineHeight={1.4}>40,000 $PGOLD</Text>
                            </ThermoCellTexts>
                        </ThermoCell>
                        <ThermoCell ref={el => refThermoCell.current[3] = el}>
                            <ThermoGauge ref={el => refThermoGauge.current[3] = el}/>
                            <ThermoCellTexts>
                                <Gen>
                                    <Text fontSize={1} lineHeight={1.4}>40,001 to 50,000&nbsp;</Text>
                                    <Text fontSize={1} lineHeight={1.4}>(Gen 3)</Text>
                                </Gen>
                                <Text font={'asulbold'} fontSize={1.3} lineHeight={1.4}>80,000 $PGOLD</Text>
                            </ThermoCellTexts>
                        </ThermoCell>
                    </MintingThermoNumbers>

                    <MintingThermoTextsMobile>
                        <GenMobile>
                            <MintingTextRange>1 to 10,000&nbsp;</MintingTextRange>
                            <MintingTextGen>(Gen 0)</MintingTextGen>
                            <MintingTextPGold>ETH</MintingTextPGold>
                        </GenMobile>
                        <GenMobile>
                            <MintingTextRange>10,001 to 20,000&nbsp;</MintingTextRange>
                            <MintingTextGen>(Gen 1)</MintingTextGen>
                            <MintingTextPGold>20,000</MintingTextPGold>
                            <MintingTextPGold>$PGOLD</MintingTextPGold>
                        </GenMobile>
                        <GenMobile>
                            <MintingTextRange>20,001 to 40,000&nbsp;</MintingTextRange>
                            <MintingTextGen>(Gen 2)</MintingTextGen>
                            <MintingTextPGold>40,000</MintingTextPGold>
                            <MintingTextPGold>$PGOLD</MintingTextPGold>
                        </GenMobile>
                        <GenMobile>
                            <MintingTextRange>40,001 to 50,000&nbsp;</MintingTextRange>
                            <MintingTextGen>(Gen 3)</MintingTextGen>
                            <MintingTextPGold>80,000</MintingTextPGold>
                            <MintingTextPGold>$PGOLD</MintingTextPGold>
                        </GenMobile>
                    </MintingThermoTextsMobile>

                </MintingThermoWrap>

                <MintingEventWrap>
                    <MintingEventCell wPct={18} paddingLeft={1}>
                        <Text font={'asulregular'} fontSize={1.6}>Price:</Text>
                        <Text font={'asulbold'} fontSize={1.7} style={{marginTop:'1.5rem'}}>{mintPrice} ETH</Text>
                    </MintingEventCell>
                    <MintingEventCell wPct={29} paddingLeft={2}  paddingRight={2} sideBorders>
                        <Text font={'asulregular'} fontSize={1.6}>Quantity:</Text>
                        <QuantityCounter>
                            <Button
                                width={3.2}
                                height={3.2}
                                color={'#fff'}
                                borderRadius={0.9}
                                unit={'rem'}
                                ref={refButtonMinus}
                                onClick={() => handleAdjustQtyClick(0)}
                            >
                                <ButtonInner
                                    width={3.2 * 0.79}
                                    height={3.2 * 0.79}
                                    bg={'linear-gradient(180deg, #1A6988 0%, #0B325D 100%)'}
                                    hoverBgColor={'#0F496F'}
                                    font={'arial'}
                                    fontSize={3}
                                    borderRadius={0.6}
                                    unit={'rem'}
                                    style={{paddingBottom:'20%'}}
                                >
                                    -
                                </ButtonInner>
                            </Button>
                            <CounterInputWrap>
                                <CounterInputScrolling ref={refCounterInputScrolling}>
                                    {
                                        [...new Array(numPerTrans)].map((i, idx) => {
                                            return (
                                                <CounterInput key={`counter-${idx}`} ref={el => refCounterInputs.current[idx + 1] = el}>
                                                    {idx + 1}
                                                </CounterInput>
                                            )
                                        })
                                    }
                                </CounterInputScrolling>
                            </CounterInputWrap>
                            <Button
                                width={3.2}
                                height={3.2}
                                color={'#fff'}
                                borderRadius={0.9}
                                unit={'rem'}
                                ref={refButtonPlus}
                                onClick={() => handleAdjustQtyClick(1)}
                            >
                                <ButtonInner
                                    width={3.2 * 0.79}
                                    height={3.2 * 0.79}
                                    bg={'linear-gradient(180deg, #1A6988 0%, #0B325D 100%)'}
                                    hoverBgColor={'#0F496F'}
                                    font={'arial'}
                                    fontSize={2.5}
                                    borderRadius={0.6}
                                    unit={'rem'}
                                >
                                    +
                                </ButtonInner>
                            </Button>
                        </QuantityCounter>
                    </MintingEventCell>

                    <MintingEventCell wPct={24} paddingLeft={1}  paddingRight={2}>
                            <Text font={'asulregular'} fontSize={1.6}>Total:</Text>
                            <Text font={'asulbold'} fontSize={2.3} color={'#973212'} style={{marginTop:'1.5rem', whiteSpace:'nowrap'}}>{total.toFixed(2)} ETH</Text>
                    </MintingEventCell>

                    <MintingEventCell wPct={29}>
                        <MintingButtonWrap>
                            <Button
                                width={20}
                                height={5}
                                color={'#fff'}
                                borderRadius={1.3}
                                unit={'rem'}
                                // style={{pointerEvents:`${dappLocked ? 'none' : 'auto'}`}}
                                style={{cursor: `${dappLocked ? 'not-allowed' : 'pointer'}`}}
                                onClick={() => dappLocked ? null : handleMintClick()}
                            >
                                <ButtonInner
                                    width={20 * 0.96}
                                    height={5 * 0.82}
                                    bg={'linear-gradient(180deg, #BA3C14 0%, #832C11 100%)'}
                                    hoverBgColor={dappLocked ? 'linear-gradient(180deg, #BA3C14 0%, #832C11 100%)' : '#842C11'}
                                    font={'asulbold'}
                                    fontSize={1.8}
                                    borderRadius={0.9}
                                    unit={'rem'}
                                >
                                    {
                                        loading ? (
                                            <div className="sk-wave" style={{position:'absolute'}}>
                                                <div className="sk-wave-rect"></div>
                                                <div className="sk-wave-rect"></div>
                                                <div className="sk-wave-rect"></div>
                                                <div className="sk-wave-rect"></div>
                                                <div className="sk-wave-rect"></div>
                                            </div>
                                        ) : 'MINT'
                                    }
                                </ButtonInner>
                            </Button>
                        </MintingButtonWrap>
                    </MintingEventCell>
                </MintingEventWrap>

                {/*MOBILE*/}
                <MintingEventWrapMobile>
                    <MintingEventWrapCol>
                        <MintingEventWrapRow>
                            <MintingEventCell wPct={50}>
                                <MintingPriceText font={'asulregular'} size={1.6}>Price:</MintingPriceText>
                                <MintingPriceText font={'asulbold'} size={1.7} style={{marginTop:'1.5rem'}}>{mintPrice} ETH</MintingPriceText>
                            </MintingEventCell>
                            <VerticalBorder/>
                            <MintingEventCell wPct={50}>
                                <MintingPriceText font={'asulregular'} size={1.6}>Quantity:</MintingPriceText>
                                <QuantityCounter>
                                    <Button
                                        width={3.2}
                                        height={3.2}
                                        color={'#fff'}
                                        borderRadius={0.9}
                                        unit={'rem'}
                                        onClick={() => handleAdjustQtyClickMobile(0)}
                                    >
                                        <ButtonInner
                                            width={3.2 * 0.79}
                                            height={3.2 * 0.79}
                                            bg={'linear-gradient(180deg, #1A6988 0%, #0B325D 100%)'}
                                            hoverBgColor={'#0F496F'}
                                            font={'arial'}
                                            fontSize={3}
                                            borderRadius={0.6}
                                            unit={'rem'}
                                            style={{paddingBottom:'20%'}}
                                        >
                                            -
                                        </ButtonInner>
                                    </Button>
                                    <CounterInputWrap>
                                        <CounterInputScrolling className="counter-scrolling-mobile" ref={refCounterInputScrollingMobile}>
                                            {
                                                [...new Array(numPerTrans)].map((i, idx) => {
                                                    return (
                                                        <CounterInput key={`counter-${idx}-mobile`} className="counter-inputs-mobile" ref={el => refCounterInputsMobile.current[idx + 1] = el}>
                                                            {idx + 1}
                                                        </CounterInput>
                                                    )
                                                })
                                            }
                                        </CounterInputScrolling>
                                    </CounterInputWrap>
                                    <Button
                                        width={3.2}
                                        height={3.2}
                                        color={'#fff'}
                                        borderRadius={0.9}
                                        unit={'rem'}
                                        onClick={() => handleAdjustQtyClickMobile(1)}
                                    >
                                        <ButtonInner
                                            width={3.2 * 0.79}
                                            height={3.2 * 0.79}
                                            bg={'linear-gradient(180deg, #1A6988 0%, #0B325D 100%)'}
                                            hoverBgColor={'#0F496F'}
                                            font={'arial'}
                                            fontSize={2.5}
                                            borderRadius={0.6}
                                            unit={'rem'}
                                        >
                                            +
                                        </ButtonInner>
                                    </Button>
                                </QuantityCounter>
                            </MintingEventCell>
                        </MintingEventWrapRow>

                        <HorizontalBorder/>

                        <MintingTotalTexts>
                            <MintingPriceText font={'asulregular'} size={1.6}>Total:</MintingPriceText>
                            <MintingPriceText font={'asulbold'} size={2.3} color={'#973212'}>{total.toFixed(2)} ETH</MintingPriceText>
                        </MintingTotalTexts>

                            <MintingButtonWrap>
                                <Button
                                    width={20}
                                    height={5}
                                    color={'#fff'}
                                    borderRadius={1.3}
                                    unit={'rem'}
                                    // style={{pointerEvents:`${dappLocked ? 'none' : 'auto'}`}}
                                    style={{cursor: `${dappLocked ? 'not-allowed' : 'pointer'}`}}
                                    onClick={() => dappLocked ? null : handleMintClick()}
                                >
                                    <ButtonInner
                                        width={20 * 0.96}
                                        height={5 * 0.82}
                                        bg={'linear-gradient(180deg, #BA3C14 0%, #832C11 100%)'}
                                        hoverBgColor={dappLocked ? 'linear-gradient(180deg, #BA3C14 0%, #832C11 100%)' : '#842C11'}
                                        font={'asulbold'}
                                        fontSize={1.8}
                                        borderRadius={0.9}
                                        unit={'rem'}
                                    >
                                        {
                                            loading ? (
                                                <div className="sk-wave" style={{position:'absolute'}}>
                                                    <div className="sk-wave-rect"></div>
                                                    <div className="sk-wave-rect"></div>
                                                    <div className="sk-wave-rect"></div>
                                                    <div className="sk-wave-rect"></div>
                                                    <div className="sk-wave-rect"></div>
                                                </div>
                                            ) : 'MINT'
                                        }
                                    </ButtonInner>
                                </Button>
                            </MintingButtonWrap>

                    </MintingEventWrapCol>
                </MintingEventWrapMobile>
            </MintingBox>
        </Container>
    );
}

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction:column;
`
const MintingBox = styled.div`
    position: relative;
    width: 100%;
    border-radius: 1rem;
    border: 0.24rem solid #3A2206;
    background-color: #EDD79B;
    box-sizing: border-box;
    box-shadow: 0.5rem 0.5rem #3A2206;
    padding: 2rem 1.5rem;
    @media (max-width: ${props => Breakpoint.sm}) {
        padding: 1.5rem 1rem;
    }
`

const MintingHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    @media (max-width: ${props => Breakpoint.md}) {
        // flex-direction: column;
    }
 `
const MintingHeaderTextWrap = styled.div`
    font-family: asulregular;
    font-size: 1.6rem;
    @media (max-width: ${props => Breakpoint.md}) {
    }
    @media (max-width: ${props => Breakpoint.sm}) {
        font-size: ${props => 1.6 * 0.7}rem;
        margin-bottom: -0.5rem;
    }
`
const MintingThermoWrap = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
`
const MintingThermoWrapInner = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    border: 0.24rem solid #3A2206;
    border-radius: 1.3rem;    
    z-index: 10;
    background: transparent;
`
const MintingThermoNumbers = styled.div`
    position: relative;
    width: 100%;
    border: 0.24rem solid #3A2206;
    border-radius: 1.3rem;    
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    font-family: asulregular;
    overflow: hidden;
`
const MintingThermometer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #FEDC84 0%, #D69A0B 100%);
`
const ThermoCell = styled('div')<{rightBorder?:boolean}>`
    position: relative;
    width: 100%;
    ${props => props.rightBorder ? 'border-right: 0.24rem solid #3A2206' : ''};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem 0;
    @media (max-width: ${props => Breakpoint.lg}) {
        padding: 0;
        height: 1rem;
    }
`
const ThermoCellTexts = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: ${props => Breakpoint.lg}) {
        display: none;
    }
`
const ThermoGauge = styled('div')<{leftRadius?:boolean,rightRadius?:boolean}>`
    position: absolute;
    width: 0;
    height: 100%;
    ${props => props.leftRadius ? 'border-top-left-radius:1rem;border-bottom-left-radius:1rem' : ''};
    ${props => props.rightRadius ? 'border-top-right-radius:1rem;border-bottom-right-radius:1rem' : ''};
    background: linear-gradient(180deg, #FEDC84 0%, #D69A0B 100%);
`
const Gen = styled.div`
    display: flex;
    flex-direction: row;
`

const MintingThermoTextsMobile = styled.div`
    width: 100%;
    display: none;
    justify-content: space-between;
    margin-top: 0.5rem;
    @media (max-width: ${props => Breakpoint.lg}) {
        display: flex;
    }
`
const GenMobile = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`
const MintingTextRange = styled.span`
    font-family: asulregular;
    font-size: 1.2rem;
    @media (max-width: ${props => Breakpoint.md}) {
        font-size: ${props =>1.2 * 0.7}rem;        
    }
    @media (max-width: ${props => Breakpoint.sm}) {
        font-size: ${props =>1.2 * 0.5}rem;        
    }
`
const MintingTextGen = styled.span`
    font-family: asulregular;
    @media (max-width: ${props => Breakpoint.md}) {
        font-size: ${props =>1.2 * 0.7}rem;        
    }
    @media (max-width: ${props => Breakpoint.sm}) {
        font-size: ${props =>1.2 * 0.5}rem;        
    }
`
const MintingTextPGold = styled.span`
    font-family: asulbold;
    font-size: 1.5rem;
    @media (max-width: ${props => Breakpoint.md}) {
        font-size: ${props =>1.5 * 0.7}rem;        
    }
    @media (max-width: ${props => Breakpoint.sm}) {
        font-size: ${props =>1.5 * 0.5}rem;        
    }
`

const MintingEventWrap = styled.div`
    width: 100%;
    border-radius: 0.8rem;
    border: 0.24rem solid #3A2206;
    background-color: #FDF4DD;
    display: flex;
    justify-content: space-between;
    padding: 3vw;
    margin-top: 1.5rem;
    @media (max-width: ${props => Breakpoint.lg}) {
        display: none;
    }
`
const MintingEventCell = styled('div')<{wPct?:number, paddingLeft?:number,paddingRight?:number,sideBorders?:boolean,leftBorder?:boolean}>`
    width: ${props => props.wPct || 100}%;
    display: flex;
    flex-direction: column;
    ${props => props.sideBorders ? 'border-left: 0.15rem dashed #A2907B;border-right: 0.15rem dashed #A2907B;' : ''};    
    ${props => props.leftBorder ? 'border-left: 0.15rem dashed #A2907B' : ''};
    padding-left: ${props => props.paddingLeft}vw;
    padding-right: ${props => props.paddingRight}vw;    
    @media (max-width: ${props => Breakpoint.xl}) {
        transform: scale(0.8);
    }
`
const QuantityCounter = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    background-color: #fff;
    @media (max-width: ${props => Breakpoint.sm}) {
        transform: scale(0.9);
    }
`
const CounterInputWrap = styled.div`
    position: relative;
    width: 42%;
    height: 3.2rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`
const CounterInputScrolling = styled.div`
    position: absolute;
    width: 100%;
    height: auto;
`
const CounterInput = styled.span`
    width: 100%;
    height: 3.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: asulbold;
    font-size: 1.8rem;
`
const MintingTotalTexts = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
`
const MintingButtonWrap = styled.div`
    width: 100%;
    display: flex;
    @media (max-width: ${props => Breakpoint.lg}) {
        justify-content: center;
        padding: 1rem;
    }
    @media (max-width: ${props => Breakpoint.md}) {
        transform: scale(0.8);
        padding: 0rem;
    }
    @media (max-width: ${props => Breakpoint.sm}) {
        transform: scale(0.6);
        padding: 0rem;
    }
`
const MintingEventWrapMobile = styled.div`
    width: 100%;
    border-radius: 0.8rem;
    border: 0.24rem solid #3A2206;
    background-color: #FDF4DD;
    display: none;
    flex-direction: column;
    margin-top: 1.5rem;
    @media (max-width: ${props => Breakpoint.lg}) {
        display: flex;
    }
`
const MintingEventWrapRow = styled.div`
    width: 100%;    
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
`
const MintingEventWrapCol = styled.div`
    width: 100%;    
    display: flex;
    flex-direction: column;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
`
const MintingPriceText = styled('div')<{font?:string,size?:number,color?:string}>`
    font-family: ${props => props.font};
    font-size: ${props => props.size}rem;
    color: ${props => props.color || '#000'};
    @media (max-width: ${props => Breakpoint.sm}) {
        font-size: ${props => props.size ? props.size * 0.7 : 0}rem;
    }
    line-height: 0.9;
    white-space: nowrap;
`
const VerticalBorder = styled.div`
    display: flex;
    align-items: center;
    width: 0.15rem;
    min-height: 100%;
    &:after {
        content: '';
        width: 100%;
        height: 80%;
        border-left: 0.15rem dashed #A2907B;
    }
`
const HorizontalBorder = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 100%;
    height: 0.15rem;
    border-top: 0.15rem dashed #A2907B;
`