import {reset, bright, white, red, blue, green} from '../ConsoleLogDisplay';
import {ethers} from 'ethers';
import * as pgold from './abi/PGold';
import * as pnp from './abi/PNP';
import * as pyramid from './abi/Pyramid';
import * as traits from './abi/Traits';
import * as whitelist from './abi/Whitelist';
import {Web3Provider} from "@ethersproject/providers";
import Web3 from "web3";


let provider:any =  null;
let signer:any = null;
let web3:any;

export const setContractProvider = (_provider:any) => {
    provider = _provider;
    signer = provider.getSigner();
}
export const setWeb3ForUtils = (_web3:any) => {
    web3 = _web3;
}

export const getWeb3 = () => {
    return web3;
}

export const PGoldContract = () => {
    // return new ethers.Contract(pgold.address, pgold.ABI, signer);
    // @ts-ignore
    return new web3.eth.Contract(pgold.ABI, pgold.address);
}

export const PNPContract = () => {
    // return new ethers.Contract(pnp.address, pnp.ABI, signer);
    // @ts-ignore
    return new web3.eth.Contract(pnp.ABI, pnp.address);
}

export const PyramidContract = () => {
    // return new ethers.Contract(pyramid.address, pyramid.ABI, signer);
    // @ts-ignore
    return new web3.eth.Contract(pyramid.ABI, pyramid.address);
}

export const TraitsContract = () => {
    return new ethers.Contract(traits.address, traits.ABI, signer);
}

export const WhitelistContract = () => {
    // return new ethers.Contract(whitelist.address, whitelist.ABI, signer);
    return new web3.eth.Contract(whitelist.ABI, whitelist.address);
}

export const getProvider = () => {
    return provider;
}

export const getSigner = () => {
    return signer;
}


export const sendTx_06292022 = async (txData:any, wallet:string, contractAddr:string, amount:number) => {
    return new Promise(async (resolve, reject) => {
        try {
            // prepare transaction options
            let options = {
                from: wallet,
                data: txData.encodeABI()
            };
            if (contractAddr) {
                // add contract address to transaction options
                options = {
                    ...options,
                    // @ts-ignore
                    to: contractAddr
                }
            }
            if (amount) {
                // add amount of ETH to send to transaction options
                options = {
                    ...options,
                    // @ts-ignore
                    value: Web3.utils.toWei(amount.toString(), 'ether')
                    // value: ethers.utils.parseEther(amount.toString()).toHexString()
                }
            }
            try {
                console.log(`${bright}${blue}Submitting transaction...${reset}`);
                // @ts-ignore
                //var txHash = await window.ethereum.request({ method: 'eth_sendTransaction', params: [options] });
                const txHash = await web3.eth.sendTransaction(options);
                // const txHash = await signer.sendTransaction(options);
                console.log(`${green}Successfully submitted transaction, TX Hash: ${bright}${white}${txHash}${reset}${green}.${reset}`);
                resolve({ txHash, success: true });
            } catch (error) {
                console.log(`${red}Failed to submit transaction, ${error}${reset}`);
                // @ts-ignore
                reject({ ...error, success: false });
            }
        } catch (error) {
            console.log(`${red}Failed to construct transaction, ${error}${reset}`);
            // @ts-ignore
            resolve({ ...error, success: false });
        }
    });
}

// function to sign and send transaction for whitelist
export const sendTx = async (txData:any, wallet:string, contractAddr:string, amount:number) => {
    return new Promise(async function (resolve, reject) {
        try {
            let options;
            // prepare transaction options
            if (amount) {
                // add amount of ETH to send to transaction options
                options = {
                    from: wallet,
                    nonce: await web3.eth.getTransactionCount(wallet, 'pending'),
                    data: txData.encodeABI(),
                    value: Web3.utils.toWei(amount.toString(), 'ether'),
                    // gas: Math.floor(await txData.estimateGas({ from: walletArray[walletIndex], value: amount }) * 1.5)
                    gas: web3.utils.toHex(3000000)
                }
            } else {
                options = {
                    from: wallet,
                    nonce: await web3.eth.getTransactionCount(wallet, 'pending'),
                    data: txData.encodeABI(),
                    gas: Math.floor(await txData.estimateGas({ from: wallet }) * 2)
                }
            }
            if (contractAddr) {
                // add contract address to transaction options
                options = {
                    ...options,
                    to: contractAddr,
                }
            }

            try {
                console.log(`${bright}${blue}Submitting transaction...${reset}`);
                // @ts-ignore
                //var txHash = await window.ethereum.request({ method: 'eth_sendTransaction', params: [options] });
                const txHash = await web3.eth.sendTransaction(options);
                console.log(`${green}Successfully submitted transaction, TX Hash: ${bright}${white}${txHash}${reset}${green}.${reset}`);
                resolve({ txHash, success: true });
            } catch (error) {
                console.log(`${red}Failed to submit transaction, ${error}${reset}`);
                // @ts-ignore
                reject({ ...error, success: false });
            }

        } catch (error) {
            console.log(`${red}Failed to submit transaction, error: ${error}${reset}`);
            reject(error);
        }
    });
}


// function to read data from the contract storage slots
export const getStorage = async (contractAddr:any, slot:any) => {
    const data = await web3.eth.getStorageAt(contractAddr, slot);
    // console.log(`${green}Storage for ${contractAddr} at Slot ${slot}: ${bright}${data}${reset}`);
    return data;
}
