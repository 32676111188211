import { configureStore } from "@reduxjs/toolkit";
import profileReducer from "../redux/profile/reducer";
import commonReducer from '../redux/blockchain/reducers/common';
import pnpReducer from '../redux/blockchain/reducers/pnpReducer';

export default configureStore({
    reducer: {
        profile: profileReducer,
        common: commonReducer,
        pnp: pnpReducer
    },
    middleware:(getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })
});