import {PNPContract, getStorage,sendTx} from "../Web3Utils";
import {reset, bright, white, red, cyan, magenta} from '../../ConsoleLogDisplay';
import {ethers} from "ethers";
import * as pnp from "../abi/PNP";
import Web3 from "web3";
import axios from "axios";
import {BACKEND_SERVER} from "../../../constants";
import {setTotalMinted} from "../reducers/pnpReducer";

export const tokensOfOwnerInCombined = async (wallet:string) => {
    const totalSupply = await PNPContract().methods.totalSupply().call();
    const tokensOfOwner = [];

    if (totalSupply > 0 && totalSupply <=50000) {
        // console.log(`${bright}0 - ${totalSupply <= 9999 ? (totalSupply - 1) : 9999}${reset}`)
        const result = await PNPContract().methods.tokensOfOwnerIn(wallet, 0, (totalSupply <= 9999 ? (totalSupply) : 9999)).call();
        tokensOfOwner.push(result);
        // tokensOfOwner.push(['12','13','14']);
    }
    if (totalSupply > 10000 && totalSupply <= 50000) {
        // console.log(`${bright}10000 - ${totalSupply <= 19999 ? (totalSupply - 1) : 19999}${reset}`)
        const result = await PNPContract().methods.tokensOfOwnerIn(wallet, 10000, totalSupply <= 19999 ? (totalSupply) : 19999).call();
        tokensOfOwner.push(result);
        // tokensOfOwner.push(['14232','14233','14234','14235','14236']);
    }
    if (totalSupply > 20000 && totalSupply <= 50000) {
        // console.log(`${bright}20000 - ${totalSupply <= 29999 ? (totalSupply - 1) : 29999}${reset}`)
        const result = await PNPContract().methods.tokensOfOwnerIn(wallet, 20000, totalSupply <= 29999 ? (totalSupply) : 29999).call();
        tokensOfOwner.push(result);
        // tokensOfOwner.push(['28900','28901','28902','28903']);
    }
    if (totalSupply > 30000 && totalSupply <= 50000) {
        // console.log(`${bright}30000 - ${totalSupply <= 39999 ? (totalSupply - 1) : 39999}${reset}`)
        const result = await PNPContract().methods.tokensOfOwnerIn(wallet, 30000, totalSupply <= 39999 ? (totalSupply) : 39999).call();
        tokensOfOwner.push(result);
        // tokensOfOwner.push([]);
    }
    if (totalSupply > 40000 && totalSupply <= 50000) {
        // console.log(`${bright}40000 - ${totalSupply <= 49999 ? (totalSupply - 1) : 49999}${reset}`)
        const result = await PNPContract().methods.tokensOfOwnerIn(wallet, 40000, totalSupply <= 49999 ? (totalSupply) : 49999).call();
        tokensOfOwner.push(result);
        // tokensOfOwner.push([]);
    }

    let resultCombined: Array<any> = [];
    for (let i=0; i< tokensOfOwner.length; i++) {
        resultCombined = await resultCombined.concat(tokensOfOwner[i]);
    }
    return resultCombined;
}

export const tokensOfOwner = async (wallet:string) => {
    const tokensOfOwner = await PNPContract().methods.tokensOfOwner(wallet).call();
    console.log(`${bright}${cyan}${wallet} owns Token Ids ${white}${tokensOfOwner}${cyan}.${reset}`);
    return tokensOfOwner;
}

export const tokensOfOwnerIn = async (wallet:string, start:number, stop:number) => {
    const result = await PNPContract().methods.tokensOfOwnerIn(wallet, start, stop).call();
    return result;
}

export const getTokenTraits = async (tokenId:number) => {
    const traitsIndex = await Web3.utils.soliditySha3(tokenId, 14); // key + storage index
    let traitsData = await getStorage(pnp.address, traitsIndex);
    traitsData = traitsData.substring(42).match(/.{1,2}/g); // only need last 12 bytes for traits data
    const traits = [];
    for (let i = traitsData.length - 1; i > -1; i--) {
        // last byte is boolean
        if (i == 11) {
            if (traitsData[i].toString() == '00') {
                traits.push(false);
            } else {
                traits.push(true);
            }
        } else {
            // remaining bytes are integers
            traits.push(await Web3.utils.hexToNumberString(`0x${traitsData[i]}`));
        }
    }
    console.log(`${bright}${cyan}The token traits for token ${tokenId} is ${white}${traits}${cyan}.${reset}`);
    return traits;
}

export const getPeasantsAndNobles = async (tokenIds:any) => {
    const peasants = [];
    const nobles = [];
    for (let i = 0; i < tokenIds.length; i++) {
        const traits = await getTokenTraits(tokenIds[i]);
        if (traits[0]) {
            peasants.push(tokenIds[i]);
        } else {
            nobles.push(tokenIds[i]);
        }
    }
    return [peasants, nobles];

}

export const getPeasantsAndNoblesOLD = async (tokenIds:any) => {
    const peasants = [];
    const nobles = [];
    for (let i = 0; i < tokenIds.length; i++) {
        const traits = await getTokenTraits(tokenIds[i]);
        // @ts-ignore
        if (traits.isPeasant) {
            peasants.push(tokenIds[i]);
        } else {
            nobles.push(tokenIds[i]);
        }
    }
    return [peasants, nobles];
}

export const totalSupply = async () => {
    const result = await PNPContract().methods.totalSupply().call();
    console.log(`${bright}${result}${cyan} tokens have been minted.${reset}`);
    return Number(result);
}

export const maxTokens = async () => {
    const result = await PNPContract().methods.MAX_TOKENS().call();
    console.log(`${bright}${cyan}The maximum number of tokens that can be minted is ${white}${result.toString()}${cyan}.${reset}`);
    return result.toString();
}

export const mintPrice = async () => {
    const raw = await PNPContract().methods.MINT_PRICE().call();
    const result = Web3.utils.fromWei(raw.toString()); //ethers.utils.formatEther(raw);
    console.log(`${bright}${cyan}The minting price for the first 20% of the tokens is ${white}${result}${cyan} ETH.${reset}`);
    return Number(result);
}

export const getMintTime = async (tokenId:number) => {
    const mintTime = await PNPContract().methods.getMintTime(tokenId).call();
    console.log(`${bright}${cyan}The token ${tokenId} was minted at ${white}${new Date(mintTime * 1000)}${cyan}.${reset}`);
    return mintTime;
}

// number of tokens that can be bought with native token
export const paidTokens = async () => {
    const paidTokens = await PNPContract().methods.PAID_TOKENS().call();
    console.log(`${bright}${paidTokens}${cyan} tokens can be bought with ETH.${reset}`);
    return paidTokens;
}

export const setPaidTokens = async (wallet:string) => {
    console.log(`${bright}${magenta}Setting Number of Paid Tokens process starting for PNP Contract...${reset}`);
    const noOfPaidTokens = await paidTokens();

    /*
        // prepare transaction data
        const txData = await contract.methods.setPaidTokens(noOfPaidTokens);
        // send transaction
        const result = await Web3Util.sendTx(txData, walletIndex, contract.options.address);
        if (result.txResult === true) {
            console.log(`${bright}${magenta}Setting Number of Paid Tokens process ended for PNP Contract.${reset}`);
        } else {
            console.log(`${red}Failed to set number of paid tokens, error: ${result}${reset}`);
        }
    */
}

export const getPNP = async (wallet:string) => {
    const tokenIds = await tokensOfOwnerInCombined(wallet);
    const [peasantIds, nobleIds] = await getPeasantsAndNobles(tokenIds);
    return [peasantIds, nobleIds];
}


/**
 * TOKEN MODULE - WRITE
 */
export const mint = async (wallet:string, noOfNFT:number, ethAmount:number) => {
    console.log(`${bright}${magenta}Minting process starting for PNP Contract...${reset}`);
    // prepare transaction data

    // THIS IS ALSO WORKING
    // const txData = await PNPContract().methods.mint(noOfNFT).send({from:wallet, value: Web3.utils.toWei(ethAmount.toString(),'ether')});
    // console.log(`${bright}${magenta}Minting process ended for PNP Contract ${txData}.${reset}`);

    const txData = await PNPContract().methods.mint(noOfNFT);
    const result:any = await sendTx(txData, wallet, pnp.address, ethAmount);
    if (result.success) {
        console.log(`${bright}${magenta}Minting process ended for PNP Contract.${reset}`);
    } else {
        console.log(`${red}Failed to mint, error: ${result}${reset}`);
    }
}

export const endWhitelist = async (wallet:string) => {
    const isEnded = await PNPContract().methods.whitelistEnded().send({from:wallet});
    console.log(isEnded)

    console.log(`${bright}${magenta}Ending Whitelist process starting for PNP Contract...${reset}`);
    // prepare transaction data
    const txData =  await PNPContract().methods.endWhitelist().send({from:wallet});
    // send transaction
    const result = await sendTx(txData, wallet, pnp.address, 0);
    // @ts-ignore
    if (result.txResult === true) {
        console.log(`${bright}${magenta}Ending Whitelist process ended for PNP Contract.${reset}`);
    } else {
        console.log(`${red}Failed to end whitelist, error: ${result}${reset}`);
    }
}

export const generateArt = async (tokenIds:any[]) => {
    return new Promise(async (resolve, reject) => {
        const nftsTraits: any = [];

        for (let i=0; i< tokenIds.length; i++) {
            const tId = tokenIds[i];
            const trait = await getTokenTraits(tId);

            if (trait[0]) {
                await nftsTraits.push({
                    tokenId: tId,
                    isPeasant: true,
                    // @ts-ignore
                    background: parseInt(trait[1] || 0) + 1,
                    // @ts-ignore
                    base: parseInt(trait[2] || 0) + 1,
                    // @ts-ignore
                    eyes: parseInt(trait[3] || 0) + 1,
                    // @ts-ignore
                    mouth: parseInt(trait[4] || 0) + 1,
                    // @ts-ignore
                    clothes: parseInt(trait[5] || 0) + 1,
                    // @ts-ignore
                    head: parseInt(trait[6] || 0) + 1,
                    // @ts-ignore
                    tool: parseInt(trait[7] || 0) + 1,
                    // @ts-ignore
                    necklace: parseInt(trait[8] || 0) + 1,
                    // @ts-ignore
                    stains: parseInt(trait[9] || 0) + 1,
                });
            } else {
                await nftsTraits.push({
                    tokenId: tId,
                    isPeasant: false,
                    // @ts-ignore
                    background: parseInt(trait[1] || 0) + 1,
                    // @ts-ignore
                    base: parseInt(trait[2] || 0) + 1,
                    // @ts-ignore
                    eyes: parseInt(trait[3] || 0) + 1,
                    // @ts-ignore
                    mouth: parseInt(trait[4] || 0) + 1,
                    // @ts-ignore
                    clothes: parseInt(trait[5] || 0) + 1,
                    // @ts-ignore
                    head: parseInt(trait[6] || 0) + 1,
                    // @ts-ignore
                    tool: parseInt(trait[7] || 0) + 1,
                    // @ts-ignore
                    pet: parseInt(trait['10'] || 0) + 1
                })
            }
        }

        console.log('REQUEST',nftsTraits)

        axios({
            method: 'POST',
            url: `${BACKEND_SERVER}/art/generate_art`,
            data: {nftsTraits: nftsTraits},
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(result => {
                console.log('RESPONSE: success');

                if (result && result.data && result.data.data && Array.isArray(result.data.data)) {
                    return resolve(result.data.data);
                } else {
                    return resolve([]);
                }

            })
            .catch(err => {
                console.log('RESPONSE:', err);
                return resolve([]);
            })
    })
}

// check if whitelist has ended
export const whitelistEnded = async () => {
    const whitelistEnded = await PNPContract().methods.whitelistEnded().call();
    if (whitelistEnded) console.log(`${bright}${cyan}Whitelist has ended.${reset}`);
    else console.log(`${bright}${cyan}Whitelist has not ended.${reset}`);
    return whitelistEnded;
}

// token id to start public free mint for initial sale
export const startPublicFreeMint = async () => {
    const startPublicFreeMint = await PNPContract().methods.startPublicFreeMint().call();
    console.log(`${bright}${cyan}Public Free Mint will start from ${white}Token ${startPublicFreeMint}${cyan}.${reset}`);
    return startPublicFreeMint;
}

export const freeMintClaimed = async (wallet: string) => {
    const mappingSlot = "000000000000000000000000000000000000000000000000000000000000000C"; // storage index 12
    const paddedAddr = (await Web3.utils.padLeft(wallet, 64)).substring(2); // convert address to bytes32
    const freeMintClaimedIndex = await Web3.utils.soliditySha3({t: 'bytes', v: paddedAddr + mappingSlot}); // concatenate key and storage index
    let freeMintClaimed = (await getStorage(pnp.address, freeMintClaimedIndex)).slice(-1); // only need last character
    if (freeMintClaimed == 1) {
        freeMintClaimed = true;
        console.log(`${bright}${cyan}Wallet ${wallet} has already claimed free mint.${reset}`);
    } else {
        freeMintClaimed = false;
        console.log(`${bright}${cyan}Wallet ${wallet} has not claimed free mint.${reset}`);
    }
    return freeMintClaimed;
}

// number of tokens that can be bought with native token
export const getInitialSaleTokens = async () => {
    const initialSaleTokens = await PNPContract().methods.getInitialSaleTokens().call();
    console.log(`${bright}${initialSaleTokens}${cyan} tokens are available for the Initial Sale.${reset}`);
    return initialSaleTokens;
}

/**
 * update the number of mints per transaction
 * based on the following phases.
 * */
export const currentPhase = async () => {
    let res:string;
    let numPerTrans:number;

    const _totalSupply = await totalSupply();
    const whitelistHasEnded = await whitelistEnded();
    const publicFreeMint = await startPublicFreeMint();
    const initialSaleTokens = await getInitialSaleTokens();
    // If whitelistEnded is true and the totalSupply has not reach startPublicFreeMint, it will be public paid.
    // If whitelistEnded is true and totalSupply has reached startPublicFreeMint, it is public free.

    if (!whitelistHasEnded) {
        numPerTrans = 1;
        res = "whitelist";
    } else if (whitelistHasEnded && (_totalSupply + 1) <= publicFreeMint) {
        numPerTrans = 3;
        res = "public_paid";
    } else if (whitelistHasEnded && ((_totalSupply + 1) > publicFreeMint && (_totalSupply+1) <= initialSaleTokens)) {
        numPerTrans = 1;
        res = "public_free";
    } else {
        numPerTrans = 10;
        res = "default";
    }


    // if (_totalSupply < 500) {
    //     if (!whitelistHasEnded) {
    //         setNumPerTrans(1);
    //         res = "whitelist";
    //     } else {
    //         setNumPerTrans(3);
    //         res = "public_paid";
    //     }
    // } else if (_totalSupply >= 500 && _totalSupply < 5500) {
    //     if (!whitelistHasEnded) {
    //         setNumPerTrans(1);
    //         res = "whitelist";
    //     } else {
    //         setNumPerTrans(3);
    //         res = "public_paid";
    //     }
    // } else if (_totalSupply >= 5500 && _totalSupply < 10000) {
    //     setNumPerTrans(1);
    //     res = "public_free";
    // } else {
    //     setNumPerTrans(10);
    //     res = "default";
    // }

    return {
        phase: res,
        totalSupply: _totalSupply,
        numberPerTransaction: numPerTrans
    };
}

/*
export const startGeneratingArt = async (wallet:string) => {
    const tokenIds = [];

    const [peasantIds, nobleIds] = await getPNP(wallet);

    for (let i = 0; i < peasantIds.length; i++) {
        const exists = await peasantIds.filter((o: any) => parseInt(o) === parseInt(peasantIds[i]))[0];
        if (!exists) {
            console.log('GOT', peasantIds[i])
            tokenIds.push(peasantIds[i]);
        }
    }

    for (let i = 0; i < nobleIds.length; i++) {
        const exists = await nobleIds.filter((o: any) => parseInt(o) === parseInt(nobleIds[i]))[0];
        if (!exists) {
            console.log('GOT', nobleIds[i])
            tokenIds.push(nobleIds[i]);
        }
    }

    const data: any = await pnpAction.generateArt(tokenIds);
    for (let i = 0; i < data.length; i++) {
        const nft = data[i];
        if (nft.isPeasant) {
            dispatch(updatePeasant(nft));
            dispatch(addPeasant({tokenId: nft.tokenId, image: nft.imageUrl}));

        } else {
            dispatch(addNoble({tokenId: nft.tokenId, image: nft.imageUrl}));
        }
    }
}
*/
