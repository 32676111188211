import {createSlice} from "@reduxjs/toolkit";
import Web3 from "web3";

const initialState = {
    // allowedNetworks: [1337,5777],
    // allowedNetworks: [4],
    allowedNetworks: [80001],
    isNetworkValid: false,
    account: "",
    chainId: 0,
    provider: null,
    web3: Web3
}

export const profileSlice = createSlice({
    name: "blockchain",
    initialState: initialState,
    reducers: {
        setAccount: (state, action) => {
            state.account = action.payload;
        },
        setChainId: (state, action) => {
            state.chainId = action.payload;
        },
        setProvider: (state, action) => {
            state.provider = action.payload;
        },
        setNetworkValid: (state, action) => {
            state.isNetworkValid = action.payload;
        },
        setWeb3: (state, action) => {
            state.web3 = action.payload;
        }
    }
});

export const {
    setAccount,
    setChainId,
    setProvider,
    setNetworkValid,
    setWeb3,
} = profileSlice.actions;

export default profileSlice.reducer;

