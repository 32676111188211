import React from 'react';
import styled from 'styled-components';

export function LineWithArrow() {
    return (
        <Container>
            <VerticalLinesWithArrow>
                <VerticalLine key={'vertical-line-1'}/>
                <VerticalLine key={'vertical-line-2'}/>
                <VerticalLine key={'vertical-line-3'}/>
            </VerticalLinesWithArrow>
            <HorizontalLine/>
            <ArrowWrap>
                <Arrow src={require('../assets/images/arrow-right-short-orange.svg').default} />
            </ArrowWrap>
        </Container>
    );
}

const Container = styled.div`
    width: 7.3rem;
    display: flex;
    flex-direction: column;
    position: relative;
`

const VerticalLinesWithArrow = styled.div`
    width: 100%;
    height: 0.8rem;
    display: flex;
    justify-content: space-between;    
`

const VerticalLine = styled.div`
    width: 0.4rem;
    height: 100%;
    background-color: #D15622;
`
const HorizontalLine = styled.div`
    width: 100%;
    height: 0.4rem;
    background-color: #D15622;
`

const ArrowWrap = styled.div`
    width: 100%;
    text-align: center;
`
const Arrow = styled.img`
    transform: rotate(90deg);
    width: 1.5rem;
`
