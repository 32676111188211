import React from 'react';
import {Button,ButtonInner} from '../Styles';
import styled from "styled-components";
import { Breakpoint } from '../constants';

export default function ConfirmedModal(props:any) {
    let messages = [];
    if (props.message) {
        messages = props.message.split('\n');
    }

    return (
        <Container>
            <BoxSection>
                <BoxWrapper>
                    {
                        (messages && messages.length > 0) && (
                            messages.map((m: any, i: number) => {
                                return <Text font={'asulbold'} fontSize={2} style={{whiteSpace: 'nowrap'}} key={i}>{m}</Text>
                            })
                        )
                    }
                    {
                        props.opensea && (
                            <Button
                                unit={'rem'}
                                width={14}
                                height={3.5}
                                color={'#fff'}
                                borderRadius={0.9}
                                style={{marginTop:'2rem'}}
                            >
                                <ButtonInner
                                    unit={'rem'}
                                    width={14 * 0.95}
                                    height={3.5 * 0.8}
                                    bg={'linear-gradient(180deg, #BA3C14 0%, #832C11 100%)'}
                                    font={'asulbold'}
                                    fontSize={1.2}
                                    borderRadius={0.6}
                                    onClick={props.viewOnOpensea}
                                >
                                    View on Opensea
                                </ButtonInner>
                            </Button>
                        )
                    }

                    <Button
                        unit={'rem'}
                        width={10}
                        height={3.5}
                        color={'#fff'}
                        borderRadius={0.9}
                        style={{marginTop: `${props.opensea ? '0.8rem' : '2rem'}`}}
                    >
                        <ButtonInner
                            unit={'rem'}
                            width={10 * 0.94}
                            height={3.5 * 0.8}
                            bg={'linear-gradient(180deg, #1A6988 0%, #0B325D 100%)'}
                            font={'asulbold'}
                            fontSize={1.2}
                            borderRadius={0.6}
                            onClick={props.close}
                        >
                            Back
                        </ButtonInner>
                    </Button>
                </BoxWrapper>
            </BoxSection>

        </Container>
    );
}

const Container = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);    
`

const BoxWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const BoxSection = styled('div')<{marginTop?:number}>`
    border-radius: 1rem;
    border: 0.2rem solid #3A2206;
    background-color: #EDD79B;
    box-sizing: border-box;
    box-shadow: 0.5rem 0.5rem #3A2206;
    margin-top: ${props => props.marginTop || 0}rem;
    padding: 3rem 5rem;
`
export const Text = styled('div')<{
    font?:string,
    size?:number,
    weight?:number,
    color?:string,
    blend?:boolean,
    lineHeight?:number,
    fontSize?:number,
    spacing?:number}>`
    font-family: ${props => props.font};
    font-size: ${props => props.fontSize}rem;
    font-weight: ${props => props.weight || 600};
    letter-spacing: ${props => props.spacing || 0.01}rem;
    color: ${props => props.color || ''};   
    ${props => props.blend ? 'mix-blend-mode: difference;' : ''};
    line-height: ${props => props.lineHeight || 1};
    @media (max-width: ${props => Breakpoint.md}) {
        font-size: ${props => (props.fontSize || 1) * 0.7}rem;
    }
    @media (max-width: ${props => Breakpoint.md2}) {
        font-size: ${props => (props.fontSize || 1) * 0.4}rem;
    }
    
`

