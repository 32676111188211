import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    dappLocked: true,
    mintCounter: 0,
}

export const slice = createSlice({
    name: 'common',
    initialState: initialState,
    reducers: {
        setDappLocked: (state, action) => {
            state.dappLocked = action.payload
        },
        incrementMintCounter: (state) => {
            state.mintCounter++;
        }
    }
})

export const {
    setDappLocked,
    incrementMintCounter,
} = slice.actions;

export default slice.reducer;