export const enum Breakpoint {
    xs = '0rem',
    sm = '37.5rem',
    md = '56.25rem',
    md2 = '43.75rem',
    lg = '75rem',
    xl = '96rem',
    xxl = '1200rem'
}

export const ERROR_NETWORK_MESSAGE = "Your wallet is not connected to the Polygon Testnet. To mint, please switch to the Polygon Testnet.";
// export const BACKEND_SERVER = "http://ec2-3-1-106-85.ap-southeast-1.compute.amazonaws.com:4000";
export const BACKEND_SERVER = "https://pnpempire.info:4000";
