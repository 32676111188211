import React from 'react';
import {Text,Button,ButtonInner} from '../Styles';
import styled from "styled-components";

export default function ComingSoonModal(props:any) {

    return (
        <Container>
            <BoxSection>
                <BoxWrapper>
                    <Text font={'asulbold'} fontSize={2} style={{whiteSpace:'nowrap'}}>Coming Soon!</Text>
                    <Button
                        unit={'rem'}
                        width={10}
                        height={3.5}
                        color={'#fff'}
                        borderRadius={0.9}
                        style={{marginTop:'2rem'}}
                    >
                        <ButtonInner
                            unit={'rem'}
                            width={10 * 0.94}
                            height={3.5 * 0.8}
                            bg={'linear-gradient(180deg, #1A6988 0%, #0B325D 100%)'}
                            font={'asulbold'}
                            fontSize={1.2}
                            borderRadius={0.6}
                            onClick={props.close}
                        >
                            Back
                        </ButtonInner>
                    </Button>
                </BoxWrapper>
            </BoxSection>

        </Container>
    );
}

const Container = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);    
`

const BoxWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const BoxSection = styled('div')<{marginTop?:number}>`
    border-radius: 1rem;
    border: 0.2rem solid #3A2206;
    background-color: #EDD79B;
    box-sizing: border-box;
    box-shadow: 0.5rem 0.5rem #3A2206;
    margin-top: ${props => props.marginTop || 0}rem;
    padding: 3rem 5rem;
`

