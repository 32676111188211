import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { ethers } from "ethers";
import { Web3Provider } from '@ethersproject/providers';
import Web3 from "web3";

const modalProvider = {
    cacheProvider: false,
    providerOptions: {
        walletconnect: {
            package: WalletConnectProvider,
            options: {
                rpc: {
                    1: `https://mainnet.infura.io/v3/${process.env.REACT_APP_PROJECT_ID}`,
                    80001: 'https://rpc-mumbai.matic.today'
                },
            },
        },
    },
};

const useMetamaskSignIn = () => {

    async function signIn(
        setMetaMaskAccount: ((arg0: string) => void) | undefined,
        setMetaMaskChainId: ((arg0: number) => void) | undefined,
        setMetaMaskProvider: ((arg0: Web3Provider) => void) | undefined,
        setMetamaskWeb3: ((args: Web3) => void) | undefined,
    ) {

        try {
            const web3Modal = new Web3Modal(modalProvider);

            const connection = await web3Modal.connect();
            const provider = new ethers.providers.Web3Provider(connection);

            if (provider) {

                if (setMetaMaskChainId) {
                    const network = await provider.getNetwork();
                    // @ts-ignore
                    setMetaMaskChainId(network.chainId);
                    console.log("Network Initial", network.chainId);
                }

                if (setMetaMaskProvider) {
                    setMetaMaskProvider(provider);
                }
                //-------------------------------------RELLY
                const web3 = new Web3(connection);
                if (setMetamaskWeb3) {
                    setMetamaskWeb3(web3);
                }
            }

            const accs = await provider.listAccounts();
            if (setMetaMaskAccount) {
                setMetaMaskAccount(accs[0]);
            }
            console.log("Account Initial:", accs[0]);

            connection.on("accountsChanged", async (accounts: string[]) => {
                if (setMetaMaskAccount) {
                    setMetaMaskAccount(accounts[0]);
                }
                console.log("Account:", accounts[0]);
            });

            connection.on("networkChanged", async (netId: number) => {
                if (setMetaMaskChainId) {
                    setMetaMaskChainId(netId);
                }
                console.log("Network", netId);
            });
        } catch (e) {}
    }

    return {signIn};
}

export { useMetamaskSignIn };
