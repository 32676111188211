import React from 'react';
import styled from 'styled-components';
import {Text, BoxSection, TextCol} from '../Styles';
import {Breakpoint} from "../constants";

export default function BoxPnpDesc() {
    return (
        <BoxSection marginTop={2}>
            <SpaceBetween>
                <CellText>
                    <TextCol marginBottom={2.5}>
                        <Text font='asulbold' weight={900} size={28}>What is PNP?</Text>
                    </TextCol>
                    <TextCol marginBottom={1.8}>
                        <Text font='asulregular' size={20} lineHeight={1.2}>PNP is an ambitious gamified world set in ancient Egypt with infinite utility.</Text>
                    </TextCol>
                    <TextCol marginBottom={1.8}>
                        <Text font='asulregular' size={20} lineHeight={1.2}>In Chapter 1, players choose their path to ascend to the ranks of a Pharaoh with a host of different startegies.</Text>
                    </TextCol>
                    <TextCol>
                        <Text font='asulregular' size={20} lineHeight={1.2}>The goal is to accumulate tokens to mint your rare 1/1 Pharaoh NFT.</Text>
                    </TextCol>
                </CellText>
                <CellImages>
                    <FlexWrap>
                        <Col>
                            <OuterImage>
                                <BoxImage src={require('../assets/images/peasant-bg.svg').default}>
                                    <InnerImage src={require('../assets/images/peasant.svg').default} heightPct={86} marginTopPct={15} />
                                </BoxImage>
                            </OuterImage>
                            <Text font='asulregular' size={20} lineHeight={3}>Peasant</Text>
                        </Col>
                        <Col>
                            <OuterImage>
                                <BoxImage src={require('../assets/images/noble-bg.svg').default}>
                                    <InnerImage src={require('../assets/images/noble.svg').default} heightPct={86} marginTopPct={15} />
                                </BoxImage>
                            </OuterImage>
                            <Text font='asulregular' size={20} lineHeight={3}>Noble</Text>
                        </Col>
                        <Col>
                            <OuterImage>
                                <BoxImage src={require('../assets/images/pharaoh-anon-bg.svg').default}>
                                    <InnerImage src={require('../assets/images/pharaoh-anon.svg').default} heightPct={86} marginTopPct={15} />
                                    <InnerImage
                                        src={require('../assets/images/pharaoh-anon-questionmark.svg').default}
                                        heightPct={20}
                                        marginTopPct={48}
                                        marginLeftPct={5}
                                        absolute
                                    />
                                </BoxImage>
                            </OuterImage>
                            <Text font='asulregular' size={20} lineHeight={3}>Pharaoh</Text>
                        </Col>
                    </FlexWrap>
                </CellImages>
            </SpaceBetween>
        </BoxSection>
    );
}

const SpaceBetween = styled('div')<{marginTop?:number}>`
    display: flex;
    justify-content: space-between;
    margin-top: ${props => props.marginTop || 0}rem;

    padding: 3rem;
    @media (max-width: ${props => Breakpoint.md}) {
        flex-wrap: wrap;
        padding: 3rem 1.5rem 1rem 1.5rem;
    }
`

const CellText = styled('div')<{marginTop?:number,w?:number}>`
    width: ${props => props.w || 100}%;
    height: 100%;
    padding-right: 1rem;
    margin-top: ${props => props.marginTop || 0}rem;
    
    @media (max-width: ${props => Breakpoint.md}) {
        margin-bottom: 2rem;       
        padding-right: 0rem;
    }
`
const CellImages = styled.div`
    width: 100%;
    height: 100%;
    margin-top: 3rem;
    margin-bottom: 1rem;
    @media (max-width: ${props => Breakpoint.md}) {
        margin-top: 0;       
        margin-bottom: 0;
    }
`

const FlexWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (max-width: ${props => Breakpoint.lg}) {
    }

    @media (max-width: ${props => Breakpoint.sm}) {
        flex-direction: column;
    }
`

const Col = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const OuterImage = styled('div')<{src?:string}>`
    width: 10.51rem;
    height: 10.5rem;
    overflow: hidden;
    border: 0.2rem solid #3A2206;
    border-radius: 1rem;
    box-shadow: 0.6rem 0.6rem #3A2206;
    display: flex;
    justify-content: center;
    align-items: center;
`

const BoxImage = styled('div')<{src?:string,w?:number,h?:number}>`
    position: relative;
    background-image: url(${props => props.src});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 105%;
    width: inherit;
    height: inherit;
    border-radius: 1rem;
    // box-sizing: border-box;
    // box-shadow: 0.6rem 0.6rem #3A2206;
    display: flex;
    justify-content: center;
`

const InnerImage = styled('img')<{heightPct?:number,marginTopPct?:number,marginLeftPct?:number,absolute?:boolean}>`
    height: ${props => props.heightPct}%;
    margin-top: ${props => props.marginTopPct}%;
    margin-left: ${props => props.marginLeftPct}%;
    position: ${props => props.absolute ? 'absolute' : 'relative'};
`