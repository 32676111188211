import React from 'react';
import styled from "styled-components";

function Config() {
    return (
        <div>
            <Button>LOCK</Button>
        </div>
    );
}

export default Config;



const Button = styled.div`
    width: 8rem;
    height: 3rem;
    border-radius: 0.3rem;
    background-color: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`